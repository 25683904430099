/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-05-09 20:10:21
 */

import Vue from 'ssr-common/vue';

import App from './app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter(context);
    const store = createStore(context);

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
