var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "header",
      class: { "nav-bar": true, "nav-bar-transparent": _vm.hideNav },
    },
    [
      _c("router-link", { attrs: { to: { name: "Index" } } }, [
        _c("h2", { staticClass: "bold" }, [
          _vm._v("\n            Instacash\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideNav,
              expression: "!hideNav",
            },
          ],
          staticClass: "routes",
        },
        [
          _c("router-link", { attrs: { to: { name: "Index" } } }, [
            _vm._v("\n            Home\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown" }, [
            _c(
              "a",
              { staticClass: "roate-icon" },
              [
                _vm._v("\n                ファンド一覧\n                "),
                _c("arrow-down"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-content" },
              [
                _c(
                  "router-link",
                  { staticClass: "b3", attrs: { to: { name: "ProductList" } } },
                  [
                    _vm._v(
                      "\n                    ファンド一覧\n                    "
                    ),
                    _c("vector", { staticClass: "vector-svg" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.profileEntryText
                  ? [
                      _c(
                        "router-link",
                        {
                          staticClass: "b3",
                          attrs: { to: { name: "ProductHold" } },
                        },
                        [
                          _vm._v(
                            "\n                        保有ファンド\n                        "
                          ),
                          _c("vector", { staticClass: "vector-svg" }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "b3",
                          attrs: { to: { name: "TradeRecord" } },
                        },
                        [
                          _vm._v(
                            "\n                        申込履歴\n                        "
                          ),
                          _c("vector", { staticClass: "vector-svg" }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "AboutUs" } } }, [
            _vm._v("\n            会社概要\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user" },
        [
          _vm.profileEntryText
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "b3 user-email",
                    attrs: { to: { name: "Profile" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.profileEntryText) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "b3 bold", on: { click: _vm.onLogout } },
                  [_vm._v("\n                サインアウト\n            ")]
                ),
              ]
            : [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$route.name.startsWith("Register"),
                        expression: "!$route.name.startsWith('Register')",
                      },
                    ],
                    staticClass: "b3 bold mr10",
                    on: { click: _vm.onRegister },
                  },
                  [_vm._v("\n                新規登録\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$route.name !== "Login",
                        expression: "$route.name !== 'Login'",
                      },
                    ],
                    staticClass: "b3 bold",
                    on: { click: _vm.onLogin },
                  },
                  [_vm._v("\n                ログイン\n            ")]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }