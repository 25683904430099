<!-- @Author: ruiwang -->
<!-- @Date: 2024-02-25 12:12:44 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 14:16:39 -->

<template>
    <div class="profile-page">
        <section class="table-row">
            <div class="flex-between">
                <h1 class="bold">
                    会員情報
                </h1>
                <div
                    v-if="!showSimpleInfo"
                    class="modif-entry"
                    @click="onOpenModifyPage('ProfileModify')"
                >
                    <img
                        class="modif-entry-icon"
                        src="./image/edit.svg"
                    >
                    <span class="b2_plus modif-entry-text">更改会員情報</span>
                </div>
            </div>
            <div class="b3 c-02 mt28 mb28">
                住所の変更をご希望される場合、お問い合わせよりご連絡ください。
            </div>
        </section>
        <template v-if="!showSimpleInfo">
            <section class="table-row">
                <div class="flex">
                    <div>
                        <div class="info-card des-group base-info-left mr28">
                            <item-info
                                v-for="(item, idx) in userBaseInfo"
                                :key="idx"
                                :item="item"
                                :val-obj="userInfo"
                            >
                                <template #email.value>
                                    <span>{{ userInfo.email }}</span>
                                    <div
                                        class="modif-entry"
                                        @click="onOpenModifyPage('EmailModify')"
                                    >
                                        <img
                                            class="modif-entry-icon"
                                            src="./image/edit.svg"
                                        >
                                        <span class="b2_plus modif-entry-text">メールアドレス変更</span>
                                    </div>
                                </template>
                                <template #pwdAnswer.label>
                                    {{ `秘密質問：${pwdQuestionEnum[userInfo.pwdQuestion] || ''}` }}
                                </template>
                                <template #address.value>
                                    {{ userInfo.area }} {{ userInfo.street }} {{ userInfo.address }}
                                </template>
                            </item-info>
                        </div>
                    </div>
                    <div>
                        <div class="info-card des-group base-info-right">
                            <item-info
                                v-for="(item, idx) in investmentInfo"
                                :key="idx"
                                :item="item"
                                :val-obj="userInfo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section class="section2 mt100">
                <h1 class="bold">
                    入金先口座情報
                </h1>
                <div>
                    <p class="b3 mt28">
                        投資申込後は、下記の口座へご本人名義でご入金をお願いいたします。
                    </p>
                    <p class="b3">
                        ご入金後、当社での着金確認およびマイページへの反映にお時間をいただくことがあります。また、振込手数料はお客さま負担となりますので、ご注意ください。
                    </p>
                    <p class="b3">
                        会員登録されているご本人の名義（下記の振込依頼人名）でのお振込みをお願いいたします。
                    </p>
                </div>
                <div class="info-card mt28">
                    <div class="des-group">
                        <item-info
                            v-for="(item, idx) in depositAccountInfo"
                            :key="idx"
                            :item="item"
                            :val-obj="userInfo"
                        />
                    </div>
                    <div class="divider-line mt14" />
                    <h5 class="c-02 mt28">
                        ご入金時の注意事項
                    </h5>
                    <div class="c-03 mt28">
                        <p class="b3">
                            ①会員登録されているご本人の名義と異なる名義からお振込みされた場合、お客さまからのご入金として取り扱うことができず、組戻しの手続きが必要となります。組戻し手数料等はお客さまのご負担となりますのでご了承ください。
                        </p>
                        <p class="b3">
                            ②会員登録されているご本人の名義と同じ名義であれば、送金用口座としてご登録の銀行口座以外からでもご入金いただけます。
                        </p>
                        <p class="b3">
                            ③口座名義は正しく入力してください。受取人の口座名義【インスタキャッシュ（カ】が異なる場合、振込先金融機関にて正しい入金処理が行われないことがございます。
                        </p>
                        <p class="b3">
                            ④当社サービスは出資金のデポジット（事前入金）には対応しておりません。出資金は必ず、ファンドへの投資申込みが完了してから、申込額を過不足なくご入金ください。
                        </p>
                        <p class="b3">
                            ⑤お客さまの投資家用口座へのご入金は、通常、ご入金の翌営業日を目処に、投資家用口座の入金額および投資申込ファンドへ充当されマイページ上に反映されますが、16時以降や土日・祝日、年末年始等のお振込みはご入金および投資申込ファンドへの充当の反映にお時間をいただくことがございます。あらかじめご了承ください。
                        </p>
                    </div>
                </div>
            </section>
            <section class="mt100">
                <h1 class="bold">
                    出金先口座情報
                </h1>
                <div class="info-card des-group mt28">
                    <item-info
                        v-for="(item, idx) in withDrawalAccountInfo"
                        :key="idx"
                        :item="item"
                        :val-obj="userInfo"
                    />
                </div>
            </section>
            <section
                v-if="userInfo.reports && userInfo.reports.length"
                class="section2 mt100"
            >
                <h1 class="bold">
                    顧客交付書面
                </h1>
                <div class="info-card report-card mt28">
                    <div
                        v-for="(name, idx) in userInfo.reports"
                        :key="idx"
                        :class="{
                            'report-item': true,
                            'b3': true,
                            'report-item-non-border': idx === userInfo.reports.length - 1
                        }"
                    >
                        <div class="report-item-name">
                            {{ name | sliceReportName }}
                        </div>
                        <div class="report-item-btns">
                            <div
                                class="btn mr40"
                                @click="onOpenReport(name)"
                            >
                                開く
                            </div>
                            <div
                                class="btn"
                                @click="onDownloadReport(name)"
                            >
                                ダウンロード
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <div
            v-else
            class="info-card des-group mt28"
        >
            <item-info
                v-for="(item, idx) in userBaseInfo.slice(0, 1)"
                :key="idx"
                :item="item"
                :val-obj="userInfo"
            />
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {axios as http} from '@yqg/resource';

import {pwdQuestionEnum} from 'src/common/constant/enum';
import Common from 'src/common/resource/common';

import ItemInfo from './component/item-info';
import FormMixin from './mixins/form';
import {userBaseInfo, investmentInfo, depositAccountInfo, withDrawalAccountInfo} from './options';
export default {
    name: 'ProfilePage',

    components: {
        ItemInfo
    },

    mixins: [FormMixin],

    data() {
        return {
            userBaseInfo,
            investmentInfo,
            depositAccountInfo,
            withDrawalAccountInfo,
            pwdQuestionEnum,
        };
    },

    computed: {
        ...mapGetters('finance', ['userInfo', 'status']),

        showSimpleInfo() {
            const {status} = this;

            // 无状态
            return !status || ['TEMP_CREATE', 'KYC_WAIT_SUBMIT'].includes(status);
        },

    },

    mounted() {
        this.$store.dispatch('finance/fetchUserInfo');
    },

    methods: {
        async onOpenReport(fileKey) {
            const {data: {body}} = await Common.file({params: {fileKey}});
            window.open(body, '_blank');
        },

        async onDownloadReport(fileKey) {
            const {data: {body}} = await Common.file({params: {fileKey}});
            try {
                const res = await http.get(body, {responseType: 'blob', hideloading: true, noToken: true});
                const a = document.createElement('a');
                a.href = URL.createObjectURL(res.data);
                a.download = this.$options.filters.sliceReportName(fileKey);
                a.style.display = 'none';
                document.body.appendChild(a);
                a.innerHTML = 'download';
                a.click();
                URL.revokeObjectURL(a.href);
            } catch (e) {
                window.open(body, '_blank');
            }
        },

        onOpenModifyPage(name) {
            this.$router.push({name});
        }
    }

};
</script>

<style lang="scss" scoped>
.mt14 {
    margin-top: 0.14rem;
}

.mt28 {
    margin-top: 0.28rem;
}

.mb28 {
    margin-bottom: 0.28rem;
}

.mr28 {
    margin-right: 0.28rem;
}

.mt100 {
    margin-top: 1rem;
}

.mr40 {
    margin-right: 0.4rem;
}

.c-02 {
    color: $c-02;
}

.c-03 {
    color: $c-03;
}

.profile-page {
    padding: 1rem 1rem 0.64rem;

    h1 {
        color: $c-01;
    }

    .info-card {
        padding: 0.28rem 0.3rem 0.14rem;
        border-radius: 0.16rem;
        border: 1px solid $c-06;
        background-color: $c-09;

        ::v-deep .des-group-row-label {
            white-space: nowrap;
        }
    }

    .table-row {
        display: table-row;
    }

    .flex-between {
        display: flex;
        justify-content: space-between;
    }

    .flex {
        display: flex;
    }

    .section2 {
        max-width: 7.26rem;
    }

    .divider-line {
        height: 0.01rem;
        background-color: $c-06;
    }

    .base-info-left {
        flex: 1;
    }

    .base-info-right {
        box-sizing: border-box;
        width: 4.56rem;
    }

    .report-card {
        padding: 0 0.24rem;

        .report-item {
            display: flex;
            padding: 0.2rem;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.01rem solid $c-10;

            &-name {
                flex: 1;
                flex-wrap: wrap;
                padding-right: 0.2rem;
            }

            &-btns {
                display: flex;

                .btn {
                    cursor: pointer;
                }
            }
        }

        .report-item-non-border {
            border: 0;
        }
    }

    .modif-entry {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 0.47rem;

        &-icon {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.08rem;
        }

        &-text {
            color: $yellow-02;
        }
    }

    ::v-deep .des-group-row-value {
        &.email-item {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
