<!-- @Author: huafengli -->
<!-- @Date: 2024-7-10 11:11:02 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-10 11:11:27 -->

<template>
    <div class="email-container">
        <div class="left">
            <h2 class="mb24">
                ご登録いただいたメールアドレス宛にアカウント情報登録のURLを送付いたしましたので、登録を完了してください。
            </h2>
            <p class="b2">
                情報登録用URLは２４時間以内有効です。期限が過ぎると新たに仮登録を行ってください。
            </p>
        </div>
        <img
            src="../login/img/group.jpg"
            alt="group"
            class="right"
        >
    </div>
</template>
<script>
export default {
    name: 'EmailModifyRecord',
};
</script>
<style lang="scss" scoped>
    .email-container {
        flex: 1;
        display: flex;

        .left {
            flex: 0 1 50%;
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: $c-02;
            padding: 1.6rem 1.2rem 2.1rem;

            .re-send {
                color: $c-01;
                font-weight: 700;
                cursor: pointer;
            }
        }

        .right {
            width: 7.2rem;
        }
    }

    .mb24 {
        margin-bottom: 0.24rem;
    }

    .mt60 {
        margin-top: 0.6rem;
    }
</style>
