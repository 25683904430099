var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-bar" }, [
    _c("div", { staticClass: "front" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pdf b3" },
        _vm._l(_vm.pdfs, function (url, idx) {
          return _c(
            "a",
            {
              key: idx,
              staticClass: "pdf-item",
              attrs: { href: "/static/pdf/" + url + ".pdf", target: "_blank" },
            },
            [
              _c(
                "div",
                { staticClass: "pdf-item-wrapper" },
                [
                  _c("pdf-svg"),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.pdfDisplayNameMap[url]) +
                      ".pdf\n                "
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("p", { staticClass: "b3" }, [
        _vm._v("\n                インスタキャッシュ株式会社"),
        _c("br"),
        _vm._v("\n                東京都渋谷区渋谷1-3-9 624室"),
        _c("br"),
        _vm._v("\n                お客様相談室：03-6778-8580"),
        _c("br"),
        _vm._v("\n                受付時間：平日9:30～17:30\n            "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                第二種金融商品取引業：関東財務局長（金商）第○○号"
        ),
        _c("br"),
        _vm._v(
          "\n                一般社団法人 第二種金融商品取引業協会 加入\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-part" }, [
      _c("h2", { staticClass: "bold" }, [
        _vm._v("\n            Instacash\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "back b3" }, [
        _vm._v(
          "\n            当社が加入する（社）第二種金融商品取引業協会を通じて契約する金融商品取引業務にかかる指定紛争解決機関"
        ),
        _c("br"),
        _vm._v(
          "\n            特定非営利活動法人 証券・金融商品あっせん相談センター"
        ),
        _c("br"),
        _vm._v("\n            東京都中央区日本橋茅場町２－１－１第二証券会館"),
        _c("br"),
        _vm._v("\n            電話番号：0120-64-5005\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }