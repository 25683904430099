<!-- @Author: ruiwang -->
<!-- @Date: 2024-02-24 17:38:21 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 14:57:14 -->

<template>
    <register-layout :step="3">
        <div class="rs2">
            <h2 class="bold">
                本人確認書類の提出
            </h2>
            <section>
                <p class="b3 mt24">
                    会員本登録には、本人確認書類として下記をご提出ください。
                    各書類はアップロードにて提出いただけます。
                </p>
                <p class="b3">
                    （ご不明な点がございましたら、お問合せよりご連絡ください。）
                </p>
                <p class="b3 mt24">
                    ファイルサイズ：１ファイルあたり４Mb以内
                </p>
            </section>
            <section
                v-if="!isServer && form"
                class="mt60 fm-1"
            >
                <a-form
                    layout="vertical"
                    :form="form"
                    @submit.prevent="handleSubmit"
                >
                    <div class="step-tit">
                        <h4 class="bold">
                            Step 01.
                        </h4>
                        <div class="b2">
                            アップロードするファイルの種類を選択します。
                        </div>
                    </div>
                    <div class="tab-bar mb20 mt24">
                        <div
                            v-for="(label, key) in $options.nationalityEnum"
                            :key="key"
                            class="tab-bar-item b1"
                            :class="getActiveTabClass(key)"
                            @click="onTabChange(key)"
                        >
                            {{ label }}
                        </div>
                    </div>
                    <section>
                        <a-form-item
                            :key="identityTypeDef.field"
                            class="custom-btn-radio-group-wrapper"
                        >
                            <a-radio-group
                                v-decorator="['identityType', { rules: [{ required: true }], initialValue: formData.identityType }]"
                                class="custom-card-radio-group"
                                @change="onRadioChange"
                            >
                                <a-radio-button
                                    v-for="opt in identityTypeDef.options"
                                    :key="opt.value"
                                    :style="{width: opt.isJpIdCard || opt.isStarCard ? '100%' : undefined}"
                                    :value="opt.value"
                                >
                                    <div
                                        v-if="opt.isJpIdCard"
                                        class="flex-between"
                                    >
                                        <div>
                                            <div class="b2">
                                                <icon-star class="star-icon icon-small" />
                                                {{ opt.label }}
                                            </div>
                                            <div class="b4 tab-desc">
                                                1書類の提出のみで可
                                            </div>
                                        </div>
                                        <div class="tab-item-right flex">
                                            <img
                                                class="id-card"
                                                src="./img/id-card-front.png"
                                            >
                                            <img
                                                class="id-card ml12"
                                                src="./img/id-card-back.png"
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="opt.isStarCard"
                                        class="flex-between"
                                    >
                                        <div>
                                            <div
                                                v-for="label in opt.labels"
                                                :key="label"
                                                class="dot b2"
                                            >
                                                {{ label }}
                                            </div>
                                        </div>
                                        <div class="tab-item-right flex pr10">
                                            <icon-star class="star-icon icon-big" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            v-for="label in opt.labels"
                                            :key="label"
                                            class="dot b2"
                                        >
                                            {{ label }}
                                        </div>
                                    </div>
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item>
                    </section>
                    <div class="step-tit">
                        <h4 class="bold">
                            Step 02.
                        </h4>
                        <div class="b2">
                            「ファイル選択」をクリックし、提出書類の画像を選択
                        </div>
                    </div>
                    <section
                        v-for="(group, idx) in fileDefGroup"
                        :key="group.key"
                        class="controler-box mt20"
                    >
                        <a-form-item
                            :key="`${tab}_${group.row[0].value}`"
                            :label="`${idx + 1}種類目の本人確認書類`"
                        >
                            <a-upload-dragger
                                v-decorator="genFileDecorator(group.row[0].value)"
                                :accept="$options.accept"
                                :custom-request="customRequest"
                                :before-upload="getBeforeUpload(group.row[0].value)"
                                :file-list="fileListMap[group.row[0].value]"
                                class="dragger-upload front"
                                @change="onFileChange($event, group.row[0].value)"
                            >
                                <h5>
                                    {{ group.row[0].cardLabel }}
                                </h5>
                                <div class="b3">
                                    ファイルをアップロード
                                </div>
                            </a-upload-dragger>
                        </a-form-item>
                        <a-form-item
                            v-if="group.row[1]"
                            :key="`${tab}_${group.row[1].value}`"
                            style="padding-top: 28px;"
                        >
                            <a-upload-dragger
                                v-decorator="genFileDecorator(group.row[1].value)"
                                :accept="$options.accept"
                                :custom-request="customRequest"
                                :before-upload="getBeforeUpload(group.row[1].value)"
                                :file-list="fileListMap[group.row[1].value]"
                                class="dragger-upload back"
                                @change="onFileChange($event, group.row[1].value)"
                            >
                                <h5>
                                    {{ group.row[1].cardLabel }}
                                </h5>
                                <div class="b3">
                                    ファイルをアップロード
                                </div>
                            </a-upload-dragger>
                        </a-form-item>
                    </section>
                    <a-form-item>
                        <button
                            :disabled="!canSubmit"
                            type="primary"
                        >
                            送信する
                        </button>
                    </a-form-item>
                </a-form>
            </section>
        </div>
        <template #tip>
            <div class="b1 c-09">
                <p>※確認書類は発行後3ヶ月以内・有効期限内のものを用意してください。</p>
                <p class="mid-tip">
                    ※確認書類は複数枚に渡る場合、すべてのページが必要となります。
                </p>
                <p>※官公署が発行する書類は公印のあるページが必要となります。</p>
            </div>
        </template>
    </register-layout>
</template>

<script>
import {mapGetters} from 'vuex';

import {isServer} from 'ssr-common/constant/config';

import {nationalityEnum} from 'src/common/constant/enum';
import Common from 'src/common/resource/common';
import User from 'src/common/resource/user';

import RegisterLayout from '../component/register-layout';
import {fieldsMap, options1, options2, genOptions, cardType, identityTypeMap} from './fields';
import IconStar from './icon/star';

const accept = '.jpg,.jpeg,.png';
export default {
    name: 'RegisterStepUpload',

    accept,

    nationalityEnum,

    components: {
        RegisterLayout,
        IconStar
    },

    data() {
        return {
            tab: 'JP_DOMESTIC',
            fieldsMap,
            options1,
            options2,
            form: null,
            isServer: isServer(),
            formData: {identityType: 'ID_CARD'},
            uploadErrMsgMap: {},
            canSubmit: false,
            fileListMap: {}
        };
    },

    computed: {
        ...mapGetters('finance', ['step2FormData']),

        showSecondUpload() {
            const {tab, formData} = this;

            return tab !== 'JP_DOMESTIC' || formData?.jpUploadType !== 'JP0';
        },

        fileDefGroup() {
            const {identityType} = this.formData || {};

            if (!identityType) return [];

            const files = this.identityTypeDef.options.find(item => item.value === identityType)?.files;

            return files;
        },

        identityTypeDef() {
            const {tab} = this;
            const map = identityTypeMap[tab];

            const opts = [];
            Object.keys(map).forEach(key => {
                const def = {
                    labels: [],
                    value: key
                };

                const files = [];
                const cards = map[key];
                cards.forEach(item => {
                    const {type, double} = item;
                    const row = [];
                    const label = cardType[type];
                    const opt = {
                        label,
                        cardLabel: `${label}証明書（表）`,
                        value: `${type}.front`
                    };

                    row.push(opt);
                    if (double) {
                        row.push({
                            label: ' ',
                            cardLabel: `${label}証明書（裏）`,
                            value: `${type}.back`
                        });
                    }

                    files.push({
                        key: type,
                        row
                    });
                });

                const isJpIdCard = tab === 'JP_DOMESTIC' && key === 'ID_CARD';
                const isStarCard = tab === 'FOREIGNER' && ['ID_CARD_RESIDENCE', 'ID_CARD_PERMANENT_RESIDENCE'].includes(key);
                const len = Object.keys(cards).length;
                const labels = cards.map((card, idx) => {
                    let lab = cardType[card.type];
                    if (card.double) {
                        lab = `${lab}（両面）`;
                    }

                    if (idx === len - 1 && isStarCard) {
                        lab = `${lab}【推奨】`;
                    }

                    return lab;
                });
                Object.assign(def, {
                    files,
                    labels,
                    label: labels.join('\n'),
                    isJpIdCard,
                    isStarCard
                });
                opts.push(def);
            });

            return {
                field: 'identityType',
                label: nationalityEnum[tab],
                options: opts
            };
        }
    },

    mounted() {
        const {onValuesChange} = this;
        this.form = this.$form.createForm(this, {
            name: 'step2Upload',
            onValuesChange
        });
    },

    methods: {
        genOptions,

        onFileChange({file}, key) {
            const {status, response} = file;
            if (['removed', 'error'].includes(status) || (status === 'done' && !response)) {
                this.$set(this.fileListMap, key, []);

                return;
            }

            this.$set(this.fileListMap, key, [file]);
        },

        async customRequest(options) {
            const {file, onError, onProgress, onSuccess} = options;
            try {
                const {data: {body}} = await Common.upload({file}, {onUploadProgress: onProgress});
                onSuccess(body);
            } catch (err) {
                onError(err);
            }
        },

        validateFile(file) {
            const {name, size} = file;

            const minSize = 0;
            const maxSize = 4 * 1024 * 1024;
            const accepts = accept?.split(',') || [];

            if (accepts.length && !accepts.some(ext => new RegExp(`${ext}$`, 'i').test(name))) {
                return {isValid: false, message: '利用可能なファイル形式：JPG、JPEG、PNG（大文字・小文字どちらもか）'};
            }

            if (size < minSize || size > maxSize) {
                return {
                    isValid: false,
                    // eslint-disable-next-line max-len
                    message: 'ファイルサイズ：１ファイルあたり４Mb以内'
                };
            }

            return {isValid: true, message: null};
        },

        getBeforeUpload(field) {
            // eslint-disable-next-line
            return file => {
                return new Promise((resolve, reject) => {
                    const {isValid, message} = this.validateFile(file);

                    const [cardType, type] = field.split('.');
                    const cardMsg = this.uploadErrMsgMap[cardType] || {front: '', back: ''};
                    if (!isValid) {
                        cardMsg[type] = message;
                        this.$set(this.uploadErrMsgMap, cardType, cardMsg);
                        this.$error(message);
                        reject();

                        return;
                    }

                    cardMsg[type] = '';
                    this.$set(this.uploadErrMsgMap, cardType, cardMsg);
                    // this.message = null;
                    resolve();
                });
            };
        },

        normFile(e) {
            if (Array.isArray(e)) {
                return e[0];
            }

            return e?.fileList?.[0];
        },

        genFileDecorator(field) {
            return [field, {
                rules: [{required: true, message: ' '}],
                valuePropName: 'file',
                getValueFromEvent: e => {
                    if (Array.isArray(e)) {
                        return e[0];
                    }

                    return e?.fileList?.[0];
                }
            }];
        },

        onValuesChange({form}, value, values) {
            this.formData = values;
            this.$nextTick(() => {
                form.validateFields(err => {
                    this.canSubmit = !err;
                });
            });
        },

        onRadioChange() {
            this.fileListMap = {};
        },

        onTabChange(tab) {
            this.tab = tab;
            this.form.resetFields();
            this.fileListMap = {};
            this.$nextTick(() => {
                this.form.setFieldsValue({identityType: tab === 'JP_DOMESTIC' ? 'ID_CARD' : 'ID_CARD_RESIDENCE'});
            });
        },

        getActiveTabClass(type) {
            const isActive = this.tab === type;

            return {
                'bold': isActive,
                'tab-bar-item-active': isActive
            };
        },

        async handleSubmit() {
            const {canSubmit, step2FormData, tab: nationality} = this;
            if (!canSubmit) return;

            const values = this.form.getFieldsValue();
            const {identityType, ...cards} = values;
            const identityImages = Object.keys(cards).map(key => {
                const item = cards[key];

                return {
                    fileType: key,
                    fileKeys: Object.values(item).map(val => val.response)
                };
            });
            const cond = {
                ...step2FormData,
                nationality,
                identityType,
                identityImages
            };

            await User.kyc(cond);
            await this.$store.dispatch('finance/fetchMainInfo');
            this.$router.push({name: 'RegisterStep3'});
        }
    }
};
</script>

<style lang="scss" scoped>
.rs2 {
    .propel-03 {
        color: $propel-03;
    }

    .ml12 {
        margin-left: 0.12rem;
    }

    .mt20 {
        margin-top: 0.2rem;
    }

    .mb20 {
        margin-bottom: 0.2rem;
    }

    .mt24 {
        margin-top: 0.24rem;
    }

    .mt28 {
        margin-top: 0.28rem;
    }

    .mb28 {
        margin-bottom: 0.28rem;
    }

    .mt60 {
        margin-top: 0.6rem;
    }

    .pr10 {
        padding-right: 0.1rem;
    }

    .pt24 {
        padding-top: 0.24rem;
    }

    .pt28 {
        padding-top: 0.28rem;
    }

    .email {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0.1rem 0.2rem;
        background-color: $grey-01;
        border-radius: 0.12rem;
    }

    .fm {
        border: 1px solid blue;
    }

    &-bg {
        position: sticky;
        top: 1.2rem;
        border: 1px solid red;
        width: 5.56rem;
        height: 7rem;
    }

    .controler-box {
        display: flex;
        flex-wrap: wrap;

        .ant-form-item {
            width: calc(50% - 0.15rem);
            margin-bottom: 0.24rem;

            &:nth-child(odd) {
                margin-right: 0.3rem;
            }
        }
    }

    .step-tit {
        display: flex;
    }

    .tab-bar {
        display: flex;
        padding: 0.06rem;
        border-radius: 0.12rem;
        background-color: $grey-01;

        &-item {
            padding: 0.06rem;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.08rem;
            cursor: pointer;

            &:first-child {
                margin-right: 0.06rem;
            }
        }

        &-item-active {
            background-color: $c-09;
        }
    }

    .dragger-upload {
        ::v-deep {
            .ant-upload.ant-upload-drag {
                border: none;
                height: 1.68rem;
                border-radius: 0.16rem;
                box-sizing: border-box;
                padding: 0.15rem;
            }
        }

        &.front {
            ::v-deep {
                .ant-upload.ant-upload-drag {
                    background-color: $gold-01;
                }
            }
        }

        &.back {
            ::v-deep {
                .ant-upload.ant-upload-drag {
                    background-color: $grey-01;
                }
            }
        }
    }
}

.mid-tip {
    margin: 0.2rem 0;
}

.dot {
    display: flex;
    align-items: center;

    &::before {
        content: "";
        margin-right: 0.08rem;
        background-color: $green-03;
        display: block;
        width: 0.08rem;
        height: 0.08rem;
        border-radius: 50%;
    }
}

.icon-small {
    width: 0.12rem;
    height: 0.12rem;
}

.icon-big {
    width: 0.2rem;
    height: 0.2rem;
}

.star-icon {
    fill: $green-03;
}

.tab-desc {
    color: $propel-03;
}

.ant-radio-button-wrapper-checked {
    .star-icon {
        fill: $c-09;
    }

    .tab-desc {
        color: $c-09;
    }

    .dot {
        &::before {
            background-color: $c-09;
        }
    }
}

.c-09 {
    color: $c-09;
}

.flex {
    display: flex;
    align-items: center;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.id-card {
    width: 1rem;
}

</style>
