<!-- @Author: ruiwang -->
<!-- @Date: 2024-02-25 15:04:47 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2024-02-25 15:04:47 -->

<template>
    <div class="container notification-page">
        <section>
            <h1 class="bold">
                通知一覧
            </h1>
        </section>
        <section class="mt28">
            <div class="notice-card">
                <div>
                    <div class="b3">
                        2014-02-24
                    </div>
                    <div class="status-row">
                        <div class="tag-item bold">
                            未读
                        </div>
                        <div class="b2">
                            [重要] これはメッセージです。
                        </div>
                    </div>
                </div>
                <img src="./img/right.svg">
            </div>
        </section>
    </div>
</template>

<script>
import {genOptions} from 'instacash/app/finance/profile/options';

export default {
    name: 'ProfilePage'
};
</script>

<style lang="scss" scoped>
.mt28 {
    margin-top: 0.28rem;
}

.notification-page {
    h1 {
        color: $c-01;
    }

    .notice-card {
        padding: 0.16rem 0.28rem;
        border-radius: 0.16rem;
        border: 1px solid $c-06;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .status-row {
        margin-top: 0.055rem;
        display: flex;
        align-items: center;

        .tag-item {
            padding: 0.02rem 0.08rem;
            border-radius: 0.12rem;
            color: $c-09;
            background-color: $green-03;
            margin-right: 0.12rem;
        }
    }
}
</style>

