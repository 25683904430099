<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:10:33 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:24 -->

<template>
    <div>
        <div class="b2 mb28">
            本人名義の銀行口座情報を入力してください。
        </div>
        <section class="controler-box">
            <template
                v-for="option in options"
            >
                <a-form-item
                    :key="option.field"
                    :label="option.label"
                >
                    <a-radio-group
                        v-if="option.type === 'radio'"
                        v-decorator="genDecorator(option)"
                        class="custom-btn-radio-group"
                        :disabled="option.useDisabled && isDisabled"
                    >
                        <a-radio-button
                            v-for="item in option.options"
                            :key="item.value"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-radio-button>
                    </a-radio-group>

                    <a-date-picker
                        v-if="option.type === 'date'"
                        v-decorator="genDecorator(option)"
                        :placeholder="option.placeholder"
                        style="width: 100%;"
                        :disabled="option.useDisabled && isDisabled"
                    />
                    <a-input
                        v-if="!option.type || option.type === 'input'"
                        v-decorator="genDecorator(option)"
                        :placeholder="option.placeholder"
                        :disabled="option.useDisabled && isDisabled"
                    />
                    <div v-if="option.field === 'accountHolder'">
                        <div class="b4">
                            小文字は入力できません。
                        </div>
                        <div class="b4">
                            長音記号は「-」（半角マイナスハイフン）にて入力ください。
                        </div>
                    </div>
                </a-form-item>
            </template>
        </section>
    </div>
</template>
<script>
import formMixin from '../mixins/form';
export default {
    name: 'BankInfo',

    mixins: [formMixin],

    props: {
        options: {
            type: Array,
            default: () => []
        }
    }

};
</script>
<style lang="scss" scoped>
@import "~instacash/common/style/finance/index.scss";

.mb28 {
    margin-bottom: 0.28rem;
}

.controler-box {
    display: flex;
    flex-wrap: wrap;

    .ant-form-item {
        width: calc(50% - 0.15rem);
        margin-bottom: 0.24rem;

        &:nth-child(odd) {
            margin-right: 0.3rem;
        }
    }
}
</style>
