<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:26:53 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:26 -->

<template>
    <div class="container">
        <div class="left">
            <h2 class="title">
                会員情報更改
            </h2>
            <div>
                <p class="b3 mt24">
                    仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。
                </p>
                <p class="b3">
                    ※ 自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。
                </p>
            </div>
            <div
                v-if="!isServer && form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <login-item-info :form="form" />
                    <base-item-info :options="baseOptions" />
                    <bank-item-info :options="bankOptions" />
                    <investment-item-info :options="investmentOptions" />
                    <kyc-file-item-info
                        ref="kycFileItemInfo"
                        :form="form"
                        :form-data="formData"
                        @setFieldsValue="setFieldsValue"
                    />
                    <a-form-item>
                        <button
                            type="primary"
                            @click="handleSubmit"
                        >
                            修正申込
                        </button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="right">
            <div class="image">
                <img src="./image/tip-bg.raw.png">
                <div class="user-name">
                    <h1 class="white">
                        {{ userInfo.name }}
                    </h1>
                    <h5 class="white">
                        {{ userInfo.email }}
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import {mapGetters} from 'vuex';

import {isServer} from 'ssr-common/constant/config';

import statusFlow from 'src/common/mixin/statusFlow';
import Common from 'src/common/resource/common';
import User from 'src/common/resource/user';

import {fieldsMap, options1, options2, options3, genOptions} from '../register/step2/fields';
import BankItemInfo from './component/bank-info';
import BaseItemInfo from './component/base-info';
import InvestmentItemInfo from './component/investment-info';
import KycFileItemInfo from './component/kyc-file-info';
import LoginItemInfo from './component/login-info';
import FormMixin from './mixins/form';

const imageFieldKeys = ['front', 'back'];
export default {
    name: 'ProfileEdit',

    components: {
        LoginItemInfo,
        BaseItemInfo,
        BankItemInfo,
        InvestmentItemInfo,
        KycFileItemInfo
    },

    mixins: [FormMixin, statusFlow],

    data() {
        return {
            form: null,
            isServer: isServer(),
            baseOptions: [],
            bankOptions: [],
            investmentOptions: [],
            formData: {},
        };
    },

    computed: {
        ...mapGetters('finance', ['userInfo']),
    },

    async mounted() {
        const [baseOptions, bankOptions, investmentOptions] = await Promise.all([
            genOptions(options1, fieldsMap),
            genOptions(options2, fieldsMap),
            genOptions(options3, fieldsMap),
            this.$store.dispatch('finance/fetchUserInfo')
        ]);

        Object.assign(this, {baseOptions, bankOptions, investmentOptions});

        this.formData = await this.initFormValue();

        this.form = this.$form.createForm(this, {
            name: 'profile_modify_form',
            onValuesChange: this.onValuesChange,
        });
        this.$nextTick(() => {
            this.setFieldsValue(this.formData);
        });
    },

    methods: {
        async initFormValue() {
            const {pwdQuestion, pwdAnswer, identityType} = this.userInfo;
            let formData = {pwdQuestion, pwdAnswer, identityType};

            const identityImagesMap = await this.transformIdentityImagesToMap();
            formData = Object.assign(formData, identityImagesMap);

            [options1, options2, options3].forEach(options => {
                options.forEach(option => {
                    const field = option[0];
                    const {type} = option[1] || {};
                    formData[field] = type === 'date' ? moment(this.userInfo[field]) : this.userInfo[field];
                });
            });

            return formData;
        },

        async transformIdentityImagesToMap() {
            const {identityImages} = this.userInfo;
            const identityImagesMap = {};

            await Promise.all(identityImages.map(async item => {
                const {fileType, fileKeys} = item;
                const fileUrls = await Promise.all(fileKeys.map(async fileKey => {
                    return await this.getFileUrl(fileKey);
                }));

                identityImagesMap[fileType] = fileUrls.reduce((map, url, index) => {
                    map[imageFieldKeys[index]] = {
                        uid: index,
                        name: this.$options.filters.sliceReportName(fileKeys[index]),
                        status: 'done',
                        url,
                        response: fileKeys[index]
                    };

                    return map;
                }, {});
            }));

            return identityImagesMap;
        },

        async getFileUrl(fileKey) {
            const {data: {body}} = await Common.file({params: {fileKey}});

            return body;
        },

        onValuesChange(form, value, values) {
            this.formData = values;
        },

        setFieldsValue(values) {
            this.form.setFieldsValue(values);
        },

        handleSubmit() {
            this.form.validateFieldsAndScroll({scroll: {offsetTop: 200}}, async (err, values) => {
                if (err) {
                    return;
                }

                const kycFileItemInfoRef = this.$refs.kycFileItemInfo;
                const {birthday, ...restData} = values || {};

                const cond = {
                    id: this.userInfo.id,
                    ...restData,
                    birthday: new Date(birthday).getTime(),
                    nationality: kycFileItemInfoRef.tab,
                    identityImages: kycFileItemInfoRef.fileDefGroup.map(item => {
                        return {
                            fileType: item.key,
                            fileKeys: Object.values(values[item.key]).map(val => val.response)
                        };
                    })
                };

                await User.kycUpdate(cond);

                if (this.isReRegister) {
                    await this.$store.dispatch('finance/fetchInfo');
                    this.goPage();
                } else {
                    this.$success('お客様の修正内容が更新されました。');
                    this.$router.push({name: 'Profile'});
                }
            });
        }
    }

};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    .title {
        font-weight: 500;
    }

    .left {
        flex: 1;
        width: 6.3rem;
        margin-right: 0.54rem;
        margin-top: 0.6rem;

        .form-wrap {
            .form {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .right {
        width: 5.56rem;

        .image {
            position: sticky;
            top: 0.82rem;

            img {
                width: 100%;
            }
        }

        .user-name {
            position: absolute;
            bottom: 0.8rem;
            left: 0.4rem;
        }
    }
}

.white {
    color: $c-09;
}

.mt60 {
    margin-top: 0.6rem;
}

.mt24 {
    margin-top: 0.24rem;
}
</style>
