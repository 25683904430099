/*
 * @Author: ruiwang
 * @Date: 2024-02-24 15:50:47
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-07-12 17:43:02
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Enum from '@yqg/enum';
import { investmentExperienceEnum, professionEnum, investmentPlanEnum, investmentPurposeEnum } from 'src/common/constant/enum';
// const inputRe = /^[\u4E00-\u9FBF\u3040-\u309F\u30A0-\u30FF]*$/; // 汉字+全角假名
// const katakanaRe = /^[\u30A0-\u30FF]*$/; // 全角片假名
const numberRule = { pattern: /^[\u0030-\u0039]+$/, message: '半角数字' };
const num7Re = /^[\u0030-\u0039]{7}$/;
const num12Re = /^[\u0030-\u0039]{12}$/;
export const fieldsMap = {
    idNo: ' マイナンバー',
    peps: '外国PEPs',
    name: '氏名（姓名）',
    alias: 'フリガナ',
    gender: '性別',
    birthday: '生年月日',
    postalNumber: '郵便番号（ハイフン除く７桁の半角数字）',
    area: '住所（都道府県）',
    street: '住所（市区町村・丁目・番地号）',
    address: '住所（建物名・部屋番号）',
    mobileNumber: '電話番号（固定電話/携帯電話）',
    bankName: '銀行名',
    branchBank: '支店名',
    bankType: '口座種類',
    bankNumber: '口座番号',
    bankUser: '口座名義人',
    investmentExperience: '投資経験(複数可)',
    investmentYears: '投資経験（年数）',
    financialAssets: '金融資産（万円）',
    profession: '職業等',
    annualIncome: '年収（万円）',
    investmentPurpose: '投資目的（複数可）',
    investmentPlan: '投資方針※（複数可）',
    investmentAccount: '証券口座',
    investmentRisk: '元本毀損で生活に支障'
};
const double = true;
// identityType
export const identityTypeMap = {
    JP_DOMESTIC: {
        ID_CARD: [{ type: 'ID_CARD', double }],
        RESIDENT_DRIVER: [{ type: 'RESIDENT' }, { type: 'DRIVER', double }],
        RESIDENT_INSURANCE: [{ type: 'RESIDENT' }, { type: 'INSURANCE', double }],
        NOTIFICATION_DRIVER: [{ type: 'NOTIFICATION' }, { type: 'DRIVER', double }],
        NOTIFICATION_INSURANCE_RESIDENCE: [{ type: 'NOTIFICATION' }, { type: 'INSURANCE', double }, { type: 'RESIDENT' }]
    },
    FOREIGNER: {
        ID_CARD_RESIDENCE: [{ type: 'ID_CARD', double }, { type: 'RESIDENCE', double }],
        ID_CARD_PERMANENT_RESIDENCE: [{ type: 'ID_CARD' }, { type: 'PERMANENT_RESIDENCE' }],
        RESIDENT_RESIDENCE: [{ type: 'RESIDENT' }, { type: 'RESIDENCE', double }],
        RESIDENT_PERMANENT_RESIDENCE: [{ type: 'RESIDENT' }, { type: 'PERMANENT_RESIDENCE' }],
        NOTIFICATION_RESIDENCE: [{ type: 'NOTIFICATION' }, { type: 'RESIDENCE', double }],
        NOTIFICATION_PERMANENT_RESIDENCE: [{ type: 'NOTIFICATION' }, { type: 'PERMANENT_RESIDENCE' }]
    }
};
export const cardType = {
    ID_CARD: '個人番号カード',
    RESIDENT: 'マイナンバー記載の住民票の写し',
    DRIVER: '運転免許証',
    INSURANCE: '各種健康保険証',
    NOTIFICATION: '現住所・氏名記載の通知カード',
    RESIDENCE: '在留カード',
    PERMANENT_RESIDENCE: '特別永住者証明書'
};
// 用户性别：gender
const sexOptions = [
    {
        label: '男性',
        value: 'MALE',
    },
    {
        label: '女性',
        value: 'FEMALE',
    }
];
// 银行类型：bankType
const accountTypeOptions = [
    {
        label: '普通',
        value: 'ORDINARY_DEPOSIT',
    },
    {
        label: '当座',
        value: 'CURRENT',
    }
];
// 投资年限：investmentYears
const investmentExperienceYearsOptions = [
    {
        label: '１～３',
        value: 'YEARS_1_3',
    },
    {
        label: '３～５',
        value: 'YEARS_3_5',
    },
    {
        label: '５以上',
        value: 'YEARS_5_MORE',
    }
];
// 投资账户：investmentAccount
const securitiesAccountOptions = [
    {
        label: '有',
        value: 'TRUE',
    },
    {
        label: '無',
        value: 'FALSE',
    }
];
// 投资风险：investmentRisk
const booleanOptions = [
    {
        label: 'はい',
        value: 'TRUE',
    },
    {
        label: 'いいえ',
        value: 'FALSE',
    }
];
export const options1 = [
    ['name', { useDisabled: true }],
    ['alias', { useDisabled: true }],
    ['gender', {
            type: 'radio', options: sexOptions
        }],
    ['birthday', { type: 'date', useDisabled: true }],
    ['idNo', {
            rules: [{ pattern: num12Re, message: '１２桁のマイナンバーをご入力ください。' }],
            useDisabled: true
        }],
    ['postalNumber', { rules: [{
                    pattern: num7Re, message: '(ハイフン除く７桁の半角数字)'
                }] }],
    ['area', { useDisabled: true }],
    ['street', { useDisabled: true }],
    ['address', { required: false, useDisabled: true }],
    ['mobileNumber', { extra: '※固定電話、もしくは携帯電話いずれか一つは必要，ハイフン除く。', rules: [numberRule] }]
];
export const options2 = [
    ['bankName', { useDisabled: true }],
    ['branchBank', { useDisabled: true }],
    ['bankType', {
            type: 'radio', options: accountTypeOptions,
            useDisabled: true
        }],
    ['bankNumber', { rules: [numberRule], useDisabled: true }],
    ['bankUser', { useDisabled: true }]
];
export const options3 = [
    ['investmentExperience', { type: 'checkbox', options: investmentExperienceEnum }],
    ['investmentYears', { type: 'radio', options: investmentExperienceYearsOptions }],
    ['financialAssets', { suffix: '万円', rules: [numberRule] }],
    ['profession', { type: 'radio', options: professionEnum }],
    ['annualIncome', { suffix: '万円', rules: [numberRule] }],
    ['investmentPurpose', { type: 'checkbox', options: investmentPurposeEnum }],
    ['investmentPlan', { type: 'checkbox', options: investmentPlanEnum }],
    ['investmentAccount', { type: 'radio', options: securitiesAccountOptions }],
    ['investmentRisk', {
            type: 'radio', options: booleanOptions,
            rules: [{
                    validator: (rule, value, callback) => {
                        let msg;
                        if (value === 'TRUE') {
                            msg = '「はい」を選択したお客様にはご登録できません。';
                        }
                        callback(msg);
                    }
                }]
        }],
    ['peps', {
            type: 'radio', options: booleanOptions,
            required: true,
            rules: [{
                    validator: (rule, value, callback) => {
                        let msg;
                        if (value === 'TRUE') {
                            msg = '「はい」を選択したお客さんには登録できません。';
                        }
                        callback(msg);
                    }
                }]
        }]
];
export const genOptions = (opts, map) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const res = [];
    for (const tar of opts) {
        const required = true;
        if (!Array.isArray(tar)) {
            const field = tar;
            const label = (map === null || map === void 0 ? void 0 : map[field]) || field;
            const cur = {
                required,
                label,
                field,
            };
            res.push(cur);
            continue;
        }
        const [field, def = {}] = tar;
        const label = (map === null || map === void 0 ? void 0 : map[field]) || field;
        const { options: originOptions } = def, restDef = __rest(def, ["options"]);
        let options = originOptions;
        if (originOptions instanceof Enum) {
            try {
                yield originOptions.query();
            }
            catch (e) {
                /* handle error */
            }
            options = ((_a = originOptions === null || originOptions === void 0 ? void 0 : originOptions.LIST) === null || _a === void 0 ? void 0 : _a.map((item) => (Object.assign(Object.assign({}, item), { label: item.text })))) || [];
        }
        const cur = Object.assign({ required,
            label,
            field,
            options }, restDef);
        res.push(cur);
    }
    return res;
});
