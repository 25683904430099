var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticClass: "login-left" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            ログイン\n        "),
      ]),
      _vm._v(" "),
      _vm.form
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "a-form",
                {
                  staticClass: "form",
                  attrs: { layout: "vertical", form: _vm.form },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "メールアドレス" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "email",
                              {
                                initialValue: _vm.initialEmail,
                                rules: _vm.getRules("email"),
                              },
                            ],
                            expression:
                              "['email', {initialValue: initialEmail, rules: getRules('email')}]",
                          },
                        ],
                        attrs: {
                          placeholder: "メールアドレスを入力してください",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-form-item",
                    { attrs: { label: "パスワード" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "password",
                              { rules: _vm.getRules("password") },
                            ],
                            expression:
                              "['password', {rules: getRules('password')}]",
                          },
                        ],
                        attrs: {
                          type: "password",
                          placeholder: "パスワードを入力してください",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "remember-container",
                      on: { click: _vm.onRemember },
                    },
                    [
                      _c("img", {
                        staticClass: "check-icon",
                        attrs: {
                          src: _vm.isRememberEmail ? _vm.Select : _vm.NoSelect,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "check-text" }, [
                        _vm._v("次回からメールアドレスを省略します。"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      class: { active: _vm.canSubmit },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("\n                    ログイン\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }