"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports._JL = void 0;
var isInBrowser = typeof EventTarget !== 'undefined';
var thisGlobal = (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; })() ||
    Function('return this')();
function _JL(loggerName) {
    if (!loggerName) {
        return _JL.__;
    }
    if (!Array.prototype.reduce) {
        Array.prototype.reduce = function (callback, initialValue) {
            var previousValue = initialValue;
            for (var i = 0; i < this.length; i++) {
                previousValue = callback(previousValue, this[i], i, this);
            }
            return previousValue;
        };
    }
    var accumulatedLoggerName = '';
    var logger = ('.' + loggerName).split('.').reduce(function (prev, curr, idx, arr) {
        if (accumulatedLoggerName) {
            accumulatedLoggerName += '.' + curr;
        }
        else {
            accumulatedLoggerName = curr;
        }
        var currentLogger = prev['__' + accumulatedLoggerName];
        if (currentLogger === undefined) {
            _JL.Logger.prototype = prev;
            currentLogger = new _JL.Logger(accumulatedLoggerName);
            prev['__' + accumulatedLoggerName] = currentLogger;
        }
        return currentLogger;
    }, _JL.__);
    return logger;
}
exports._JL = _JL;
(function (_JL) {
    _JL.requestId = '';
    _JL.entryId = 0;
    _JL._createXMLHttpRequest = function () { return new XMLHttpRequest(); };
    _JL._getTime = function () { return (new Date).getTime(); };
    _JL._console = console;
    _JL._appenderNames = [];
    function copyProperty(propertyName, from, to) {
        if (from[propertyName] === undefined) {
            return;
        }
        if (from[propertyName] === null) {
            delete to[propertyName];
            return;
        }
        to[propertyName] = from[propertyName];
    }
    function allow(filters) {
        if (!(_JL.enabled == null)) {
            if (!_JL.enabled) {
                return false;
            }
        }
        try {
            if (filters.userAgentRegex) {
                if (!new RegExp(filters.userAgentRegex).test(navigator.userAgent)) {
                    return false;
                }
            }
        }
        catch (e) { }
        try {
            if (filters.ipRegex && _JL.clientIP) {
                if (!new RegExp(filters.ipRegex).test(_JL.clientIP)) {
                    return false;
                }
            }
        }
        catch (e) { }
        return true;
    }
    function allowMessage(filters, message) {
        try {
            if (filters.disallow) {
                if (new RegExp(filters.disallow).test(message)) {
                    return false;
                }
            }
        }
        catch (e) { }
        return true;
    }
    function stringifyLogObjectFunction(logObject) {
        if (typeof logObject == 'function') {
            if (logObject instanceof RegExp) {
                return logObject.toString();
            }
            else {
                return logObject();
            }
        }
        return logObject;
    }
    var StringifiedLogObject = (function () {
        function StringifiedLogObject(msg, meta, finalString) {
            this.msg = msg;
            this.meta = meta;
            this.finalString = finalString;
        }
        return StringifiedLogObject;
    }());
    function stringifyLogObject(logObject) {
        var actualLogObject = stringifyLogObjectFunction(logObject);
        var finalString;
        switch (typeof actualLogObject) {
            case 'string':
                return new StringifiedLogObject(actualLogObject, null, actualLogObject);
            case 'number':
                finalString = actualLogObject.toString();
                return new StringifiedLogObject(finalString, null, finalString);
            case 'boolean':
                finalString = actualLogObject.toString();
                return new StringifiedLogObject(finalString, null, finalString);
            case 'undefined':
                return new StringifiedLogObject('undefined', null, 'undefined');
            case 'object':
                if ((actualLogObject instanceof RegExp) ||
                    (actualLogObject instanceof String) ||
                    (actualLogObject instanceof Number) ||
                    (actualLogObject instanceof Boolean)) {
                    finalString = actualLogObject.toString();
                    return new StringifiedLogObject(finalString, null, finalString);
                }
                else {
                    if (typeof _JL.serialize === 'function') {
                        finalString = _JL.serialize.call(this, actualLogObject);
                    }
                    else {
                        finalString = JSON.stringify(actualLogObject);
                    }
                    return new StringifiedLogObject('', actualLogObject, finalString);
                }
            default:
                return new StringifiedLogObject('unknown', null, 'unknown');
        }
    }
    function setOptions(options) {
        copyProperty('enabled', options, this);
        copyProperty('maxMessages', options, this);
        copyProperty('defaultAjaxUrl', options, this);
        copyProperty('clientIP', options, this);
        copyProperty('requestId', options, this);
        copyProperty('defaultBeforeSend', options, this);
        copyProperty('serialize', options, this);
        return this;
    }
    _JL.setOptions = setOptions;
    function getAllLevel() { return -2147483648; }
    _JL.getAllLevel = getAllLevel;
    function getTraceLevel() { return 1000; }
    _JL.getTraceLevel = getTraceLevel;
    function getDebugLevel() { return 2000; }
    _JL.getDebugLevel = getDebugLevel;
    function getInfoLevel() { return 3000; }
    _JL.getInfoLevel = getInfoLevel;
    function getWarnLevel() { return 4000; }
    _JL.getWarnLevel = getWarnLevel;
    function getErrorLevel() { return 5000; }
    _JL.getErrorLevel = getErrorLevel;
    function getFatalLevel() { return 6000; }
    _JL.getFatalLevel = getFatalLevel;
    function getOffLevel() { return 2147483647; }
    _JL.getOffLevel = getOffLevel;
    function levelToString(level) {
        if (level <= 1000) {
            return 'trace';
        }
        if (level <= 2000) {
            return 'debug';
        }
        if (level <= 3000) {
            return 'info';
        }
        if (level <= 4000) {
            return 'warn';
        }
        if (level <= 5000) {
            return 'error';
        }
        return 'fatal';
    }
    var Exception = (function () {
        function Exception(data, inner) {
            this.inner = inner;
            this.name = 'JL.Exception';
            this.message = stringifyLogObject(data).finalString;
        }
        return Exception;
    }());
    _JL.Exception = Exception;
    Exception.prototype = new Error();
    var LogItem = (function () {
        function LogItem(l, m, n, t, u) {
            this.l = l;
            this.m = m;
            this.n = n;
            this.t = t;
            this.u = u;
        }
        return LogItem;
    }());
    _JL.LogItem = LogItem;
    function newLogItem(levelNbr, message, loggerName) {
        _JL.entryId++;
        return new LogItem(levelNbr, message, loggerName, _JL._getTime(), _JL.entryId);
    }
    function clearTimer(timer) {
        if (timer.id) {
            clearTimeout(timer.id);
            timer.id = null;
        }
    }
    function setTimer(timer, timeoutMs, callback) {
        var that = this;
        if (!timer.id) {
            timer.id = setTimeout(function () {
                callback.call(that);
            }, timeoutMs);
        }
    }
    var Appender = (function () {
        function Appender(appenderName, sendLogItems) {
            this.appenderName = appenderName;
            this.sendLogItems = sendLogItems;
            this.level = _JL.getTraceLevel();
            this.sendWithBufferLevel = 2147483647;
            this.storeInBufferLevel = -2147483648;
            this.bufferSize = 0;
            this.batchSize = 1;
            this.maxBatchSize = 20;
            this.batchTimeout = 2147483647;
            this.sendTimeout = 5000;
            this.buffer = [];
            this.batchBuffer = [];
            this.batchTimeoutTimer = { id: null };
            this.sendTimeoutTimer = { id: null };
            this.nbrLogItemsSkipped = 0;
            this.nbrLogItemsBeingSent = 0;
            var emptyNameErrorMessage = 'Trying to create an appender without a name or with an empty name';
            if (appenderName == undefined) {
                throw emptyNameErrorMessage;
            }
            if (_JL._appenderNames.indexOf(appenderName) != -1) {
                if (!appenderName) {
                    throw emptyNameErrorMessage;
                }
                throw 'Multiple appenders use the same name ' + appenderName;
            }
            _JL._appenderNames.push(appenderName);
        }
        Appender.prototype.addLogItemsToBuffer = function (logItems) {
            if (this.batchBuffer.length >= this.maxBatchSize) {
                this.nbrLogItemsSkipped += logItems.length;
                return;
            }
            if (!(_JL.maxMessages == null)) {
                if (_JL.maxMessages < 1) {
                    return;
                }
                _JL.maxMessages -= logItems.length;
            }
            this.batchBuffer = this.batchBuffer.concat(logItems);
            var that = this;
            setTimer(this.batchTimeoutTimer, this.batchTimeout, function () {
                that.sendBatch.call(that);
            });
        };
        Appender.prototype.batchBufferHasOverdueMessages = function () {
            for (var i = 0; i < this.batchBuffer.length; i++) {
                var messageAgeMs = _JL._getTime() - this.batchBuffer[i].t;
                if (messageAgeMs > this.batchTimeout) {
                    return true;
                }
            }
            return false;
        };
        Appender.prototype.batchBufferHasStrandedMessage = function () {
            return (!(_JL.maxMessages == null)) && (_JL.maxMessages < 1) && (this.batchBuffer.length > 0);
        };
        Appender.prototype.sendBatchIfComplete = function () {
            if ((this.batchBuffer.length >= this.batchSize) ||
                this.batchBufferHasOverdueMessages() ||
                this.batchBufferHasStrandedMessage()) {
                this.sendBatch();
            }
        };
        Appender.prototype.onSendingEnded = function () {
            clearTimer(this.sendTimeoutTimer);
            this.nbrLogItemsBeingSent = 0;
            this.sendBatchIfComplete();
        };
        Appender.prototype.setOptions = function (options) {
            copyProperty('level', options, this);
            copyProperty('ipRegex', options, this);
            copyProperty('userAgentRegex', options, this);
            copyProperty('disallow', options, this);
            copyProperty('sendWithBufferLevel', options, this);
            copyProperty('storeInBufferLevel', options, this);
            copyProperty('bufferSize', options, this);
            copyProperty('batchSize', options, this);
            copyProperty('maxBatchSize', options, this);
            copyProperty('batchTimeout', options, this);
            copyProperty('sendTimeout', options, this);
            if (this.bufferSize < this.buffer.length) {
                this.buffer.length = this.bufferSize;
            }
            if (this.maxBatchSize < this.batchSize) {
                throw new _JL.Exception({
                    'message': 'maxBatchSize cannot be smaller than batchSize',
                    'maxBatchSize': this.maxBatchSize,
                    'batchSize': this.batchSize
                });
            }
            return this;
        };
        Appender.prototype.log = function (level, msg, meta, callback, levelNbr, message, loggerName) {
            var logItem;
            if (!allow(this)) {
                return;
            }
            if (!allowMessage(this, message)) {
                return;
            }
            if (levelNbr < this.storeInBufferLevel) {
                return;
            }
            logItem = newLogItem(levelNbr, message, loggerName);
            if (levelNbr < this.level) {
                if (this.bufferSize > 0) {
                    this.buffer.push(logItem);
                    if (this.buffer.length > this.bufferSize) {
                        this.buffer.shift();
                    }
                }
                return;
            }
            this.addLogItemsToBuffer([logItem]);
            if (levelNbr >= this.sendWithBufferLevel) {
                if (this.buffer.length) {
                    this.addLogItemsToBuffer(this.buffer);
                    this.buffer.length = 0;
                }
            }
            this.sendBatchIfComplete();
        };
        Appender.prototype.sendBatch = function () {
            if (this.nbrLogItemsBeingSent > 0) {
                return;
            }
            clearTimer(this.batchTimeoutTimer);
            if (this.batchBuffer.length == 0) {
                return;
            }
            this.nbrLogItemsBeingSent = this.batchBuffer.length;
            var that = this;
            setTimer(this.sendTimeoutTimer, this.sendTimeout, function () {
                that.onSendingEnded.call(that);
            });
            this.sendLogItems(this.batchBuffer, function () {
                that.batchBuffer.splice(0, that.nbrLogItemsBeingSent);
                if (that.nbrLogItemsSkipped > 0) {
                    that.batchBuffer.push(newLogItem(getWarnLevel(), 'Lost ' + that.nbrLogItemsSkipped + ' messages. Either connection with the server was down or logging was disabled via the enabled option. Reduce lost messages by increasing the ajaxAppender option maxBatchSize.', that.appenderName));
                    that.nbrLogItemsSkipped = 0;
                }
                that.onSendingEnded.call(that);
            });
        };
        return Appender;
    }());
    _JL.Appender = Appender;
    var AjaxAppender = (function (_super) {
        __extends(AjaxAppender, _super);
        function AjaxAppender(appenderName) {
            return _super.call(this, appenderName, AjaxAppender.prototype.sendLogItemsAjax) || this;
        }
        AjaxAppender.prototype.setOptions = function (options) {
            copyProperty('url', options, this);
            copyProperty('beforeSend', options, this);
            _super.prototype.setOptions.call(this, options);
            return this;
        };
        AjaxAppender.prototype.sendLogItemsAjax = function (logItems, successCallback) {
            try {
                if (!allow(this)) {
                    return;
                }
                if (this.xhr && (this.xhr.readyState != 0) && (this.xhr.readyState != 4)) {
                    this.xhr.abort();
                }
                this.xhr = _JL._createXMLHttpRequest();
                var ajaxUrl = '/jsnlog.logger';
                if (!(_JL.defaultAjaxUrl == null)) {
                    ajaxUrl = _JL.defaultAjaxUrl;
                }
                if (this.url) {
                    ajaxUrl = this.url;
                }
                this.xhr.open('POST', ajaxUrl);
                this.xhr.setRequestHeader('Content-Type', 'application/json');
                this.xhr.setRequestHeader('JSNLog-RequestId', _JL.requestId);
                var that = this;
                this.xhr.onreadystatechange = function () {
                    if ((that.xhr.readyState == 4) && (that.xhr.status >= 200 && that.xhr.status < 300)) {
                        successCallback();
                    }
                };
                var json = {
                    r: _JL.requestId,
                    lg: logItems
                };
                if (typeof this.beforeSend === 'function') {
                    this.beforeSend.call(this, this.xhr, json);
                }
                else if (typeof _JL.defaultBeforeSend === 'function') {
                    _JL.defaultBeforeSend.call(this, this.xhr, json);
                }
                var finalmsg = JSON.stringify(json);
                this.xhr.send(finalmsg);
            }
            catch (e) { }
        };
        return AjaxAppender;
    }(Appender));
    _JL.AjaxAppender = AjaxAppender;
    var ConsoleAppender = (function (_super) {
        __extends(ConsoleAppender, _super);
        function ConsoleAppender(appenderName) {
            return _super.call(this, appenderName, ConsoleAppender.prototype.sendLogItemsConsole) || this;
        }
        ConsoleAppender.prototype.clog = function (logEntry) {
            _JL._console.log(logEntry);
        };
        ConsoleAppender.prototype.cerror = function (logEntry) {
            if (_JL._console.error) {
                _JL._console.error(logEntry);
            }
            else {
                this.clog(logEntry);
            }
        };
        ConsoleAppender.prototype.cwarn = function (logEntry) {
            if (_JL._console.warn) {
                _JL._console.warn(logEntry);
            }
            else {
                this.clog(logEntry);
            }
        };
        ConsoleAppender.prototype.cinfo = function (logEntry) {
            if (_JL._console.info) {
                _JL._console.info(logEntry);
            }
            else {
                this.clog(logEntry);
            }
        };
        ConsoleAppender.prototype.cdebug = function (logEntry) {
            if (_JL._console.debug) {
                _JL._console.debug(logEntry);
            }
            else {
                this.cinfo(logEntry);
            }
        };
        ConsoleAppender.prototype.sendLogItemsConsole = function (logItems, successCallback) {
            try {
                if (!allow(this)) {
                    return;
                }
                if (!_JL._console) {
                    return;
                }
                var i;
                for (i = 0; i < logItems.length; ++i) {
                    var li = logItems[i];
                    var msg = li.n + ': ' + li.m;
                    if (!isInBrowser) {
                        msg = new Date(li.t) + ' | ' + msg;
                    }
                    if (li.l <= _JL.getDebugLevel()) {
                        this.cdebug(msg);
                    }
                    else if (li.l <= _JL.getInfoLevel()) {
                        this.cinfo(msg);
                    }
                    else if (li.l <= _JL.getWarnLevel()) {
                        this.cwarn(msg);
                    }
                    else {
                        this.cerror(msg);
                    }
                }
            }
            catch (e) {
            }
            successCallback();
        };
        return ConsoleAppender;
    }(Appender));
    _JL.ConsoleAppender = ConsoleAppender;
    var Logger = (function () {
        function Logger(loggerName) {
            this.loggerName = loggerName;
            this.seenRegexes = [];
        }
        Logger.prototype.setOptions = function (options) {
            copyProperty('level', options, this);
            copyProperty('userAgentRegex', options, this);
            copyProperty('disallow', options, this);
            copyProperty('ipRegex', options, this);
            copyProperty('appenders', options, this);
            copyProperty('onceOnly', options, this);
            this.seenRegexes = [];
            return this;
        };
        Logger.prototype.buildExceptionObject = function (e) {
            var excObject = {};
            if (e.stack) {
                excObject.stack = e.stack;
            }
            else {
                excObject.e = e;
            }
            if (e.message) {
                excObject.message = e.message;
            }
            if (e.name) {
                excObject.name = e.name;
            }
            if (e.data) {
                excObject.data = e.data;
            }
            if (e.inner) {
                excObject.inner = this.buildExceptionObject(e.inner);
            }
            return excObject;
        };
        Logger.prototype.log = function (level, logObject, e) {
            var i = 0;
            var compositeMessage;
            var excObject;
            if (!this.appenders) {
                return this;
            }
            if (((level >= this.level)) && allow(this)) {
                if (e) {
                    excObject = this.buildExceptionObject(e);
                    excObject.logData = stringifyLogObjectFunction(logObject);
                }
                else {
                    excObject = logObject;
                }
                compositeMessage = stringifyLogObject(excObject);
                if (allowMessage(this, compositeMessage.finalString)) {
                    if (this.onceOnly) {
                        i = this.onceOnly.length - 1;
                        while (i >= 0) {
                            if (new RegExp(this.onceOnly[i]).test(compositeMessage.finalString)) {
                                if (this.seenRegexes[i]) {
                                    return this;
                                }
                                this.seenRegexes[i] = true;
                            }
                            i--;
                        }
                    }
                    compositeMessage.meta = compositeMessage.meta || {};
                    i = this.appenders.length - 1;
                    while (i >= 0) {
                        this.appenders[i].log(levelToString(level), compositeMessage.msg, compositeMessage.meta, function () { }, level, compositeMessage.finalString, this.loggerName);
                        i--;
                    }
                }
            }
            return this;
        };
        Logger.prototype.trace = function (logObject) { return this.log(getTraceLevel(), logObject); };
        Logger.prototype.debug = function (logObject) { return this.log(getDebugLevel(), logObject); };
        Logger.prototype.info = function (logObject) { return this.log(getInfoLevel(), logObject); };
        Logger.prototype.warn = function (logObject) { return this.log(getWarnLevel(), logObject); };
        Logger.prototype.error = function (logObject) { return this.log(getErrorLevel(), logObject); };
        Logger.prototype.fatal = function (logObject) { return this.log(getFatalLevel(), logObject); };
        Logger.prototype.fatalException = function (logObject, e) { return this.log(getFatalLevel(), logObject, e); };
        return Logger;
    }());
    _JL.Logger = Logger;
    function createAjaxAppender(appenderName) {
        return new AjaxAppender(appenderName);
    }
    _JL.createAjaxAppender = createAjaxAppender;
    function createConsoleAppender(appenderName) {
        return new ConsoleAppender(appenderName);
    }
    _JL.createConsoleAppender = createConsoleAppender;
    var defaultAppender;
    if (isInBrowser) {
        defaultAppender = new AjaxAppender('');
    }
    else {
        defaultAppender = new ConsoleAppender('');
    }
    _JL.__ = new _JL.Logger('');
    _JL.__.setOptions({
        level: _JL.getDebugLevel(),
        appenders: [defaultAppender]
    });
})(_JL || (_JL = {}));
exports._JL = _JL;
if (typeof __jsnlog_configure == 'function') {
    __jsnlog_configure(_JL);
}
if (isInBrowser && !thisGlobal.onerror) {
    thisGlobal.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        _JL('onerrorLogger').fatalException({
            'msg': 'Uncaught Exception',
            'errorMsg': errorMsg ? (errorMsg.message || errorMsg) : '',
            'url': url,
            'line number': lineNumber, 'column': column
        }, errorObj);
        return false;
    };
}
if (isInBrowser && !thisGlobal.onunhandledrejection) {
    thisGlobal.onunhandledrejection = function (event) {
        _JL('onerrorLogger').fatalException({
            'msg': 'unhandledrejection',
            'errorMsg': event.reason ? event.reason.message : event.message || null
        }, event.reason);
    };
}
