<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-24 12:34:15 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 14:56:56 -->

<template>
    <register-layout :step="3">
        <template #default>
            <div class="register-step3">
                <h2>会員登録申し込み完了</h2>
                <template v-if="status === 'KYC_REFUSE'">
                    <p class="b3">
                        この度はお申込みをいただき誠に有難うございました。審査の結果について大変申し訳ございませんが、登録の承認をご遠慮させて頂く事となりました。
                    </p>
                    <p class="b3">
                        審査内容の詳細につきまして開示しておらず、お問合せを頂きまして
                    </p>
                    <p class="b3">
                        もお答えすることは出来ません。
                    </p>
                </template>

                <template v-else-if="status === 'KYC_WAIT_UPDATE'">
                    <p class="b3">
                        「お客様の本人情報が本人確認資料記載内容と一致しません。
                    </p>
                    <p class="b3">
                        ご確認のうえ<span
                            class="bold c-03"
                            @click="onOpenProfileModify()"
                        >登録情報修正</span>ボタンをクリックし再入力してください。」
                    </p>
                </template>
                <template v-else>
                    <h5 class="bold">
                        お申込み内容を確認後、<span class="green-03">認証コ</span>ードをご登録住所宛て郵送いたしますので、しばらくお待ちください。
                    </h5>
                    <p class="b2">
                        🙏 引き続き、インスタキャッシュをよろしくお願いします。
                    </p>
                    <p class="b2">
                        <span class="emoji-warn">⚠️ </span>審査の結果、ご登録をいたしかねる場合がございますので、ご了承ください。
                    </p>
                </template>
                <button
                    class="b2 bold"
                    @click="goHome"
                >
                    トップに戻る
                </button>
            </div>
        </template>
    </register-layout>
</template>

<script>
import {mapGetters} from 'vuex';

import RegisterLayout from '../component/register-layout';

export default {
    name: 'RegsiterStep3',

    components: {
        RegisterLayout
    },

    computed: {
        ...mapGetters('finance', ['status'])
    },

    mounted() {
        this.$store.commit('finance/updateFinance', {
            currentStepRouteName: 'RegisterStep3'
        });
    },

    methods: {
        goHome() {
            this.$router.push({name: 'Index'});
        },

        onOpenProfileModify() {
            this.$router.push({name: 'ProfileModify'});
        }
    }
};
</script>

<style lang="scss" scoped>
.register-step3 {
    width: 6.5rem;

    .mt24 {
        margin-top: 0.24rem;
    }

    h2 {
        color: $c-01;
    }

    h5 {
        margin: 0.24rem 0;
        color: $c-01;
    }

    p.b2 {
        margin-top: 0.04rem;
        color: $c-02;

        .emoji-warn {
            color: $red-02;
        }
    }

    p.b3 {
        margin-top: 0.24rem;
    }

    .c-03 {
        color: $c-03;
    }

    .green-03 {
        color: $green-03;
    }

    button {
        display: block;
        margin-top: 0.6rem;
    }
}
</style>
