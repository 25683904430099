<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-26 16:03:31 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-03-07 19:20:03 -->

<template>
    <div class="footer-bar">
        <div class="front">
            <div class="left">
                <p class="b3">
                    インスタキャッシュ株式会社<br>
                    東京都渋谷区渋谷1-3-9&nbsp;624室<br>
                    お客様相談室：03-6778-8580<br>
                    受付時間：平日9:30～17:30
                </p>
                <p>
                    第二種金融商品取引業：関東財務局長（金商）第○○号<br>
                    一般社団法人&nbsp;第二種金融商品取引業協会&nbsp;加入
                </p>
            </div>
            <div class="pdf b3">
                <a
                    v-for="(url, idx) in pdfs"
                    :key="idx"
                    class="pdf-item"
                    :href="`/static/pdf/${url}.pdf`"
                    target="_blank"
                >
                    <div class="pdf-item-wrapper">
                        <pdf-svg />
                        {{ pdfDisplayNameMap[url] }}.pdf
                    </div>
                </a>
            </div>
        </div>

        <hr>

        <div class="bottom-part">
            <h2 class="bold">
                Instacash
            </h2>
            <div class="back b3">
                当社が加入する（社）第二種金融商品取引業協会を通じて契約する金融商品取引業務にかかる指定紛争解決機関<br>
                特定非営利活動法人&nbsp;証券・金融商品あっせん相談センター<br>
                東京都中央区日本橋茅場町２－１－１第二証券会館<br>
                電話番号：0120-64-5005
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {pdfDisplayNameMap} from 'src/common/constant';

import PdfSvg from './pdf.vc.svg';

export default {
    name: 'FooterBar',

    components: {
        PdfSvg
    },

    data() {
        return {
            pdfDisplayNameMap,
            pdfs: [
                'anti-social',
                'electromagnetic',
                'anti-money',
                'privacy-policy',
                'information-security',
                'personal-data',
                'website',
                'sales-act',
                'transaction'
            ]
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.footer-bar {
    // margin-top: 1rem;
    padding: 0.4rem 1rem;
    background-color: $c-01;
    color: $c-07;

    h2 {
        color: $c-09;
    }

    .front {
        margin-top: 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left > p:first-child {
            margin-bottom: 0.2rem;
        }

        .pdf {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 0.06rem 0.6rem;

            &-item {
                color: $c-09;
                transition: color 0.3s;

                &:hover {
                    color: $yellow-02;
                }

                &-wrapper {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 0.03rem;
                    }
                }
            }
        }
    }

    hr {
        margin: 1.4rem 0 0.4rem;
        color: $c-06;
    }

    .bottom-part {
        display: flex;
        justify-content: space-between;
    }
}
</style>
