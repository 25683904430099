var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "email-container" }, [
      _c("div", { staticClass: "left" }, [
        _c("h2", { staticClass: "mb24" }, [
          _vm._v(
            "\n            ご登録いただいたメールアドレス宛にアカウント情報登録のURLを送付いたしましたので、登録を完了してください。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "b2" }, [
          _vm._v(
            "\n            情報登録用URLは２４時間以内有効です。期限が過ぎると新たに仮登録を行ってください。\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "right",
        attrs: { src: require("../login/img/group.jpg"), alt: "group" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }