/*
 * @Author: huafengli
 * @Date: 2024-04-29 17:14:43
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-07-10 14:47:21
 */
import Resource from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    mainInfo: {
        url: '/api/user/main/info',
        method: 'get'
    },
    userInfo: {
        url: '/api/user/info',
        method: 'get'
    },
    login: {
        url: '/api/user/login',
        method: 'post'
    },
    logout: {
        url: '/api/user/logout',
        method: 'get'
    },
    register: {
        url: '/api/user/register',
        method: 'post'
    },
    confirm: {
        url: '/api/user/register/email/confirm',
        method: 'get'
    },
    send: {
        url: '/api/user/register/email/send',
        method: 'get'
    },
    kyc: {
        url: '/api/user/kyc',
        method: 'post'
    },
    kycConfirm: {
        url: '/api/user/kyc/confirm',
        method: 'post',
        formData: true
    },
    updateEmail: {
        url: '/api/user/update/email/send',
        method: 'get'
    },
    updateEmailConfirm: {
        url: '/api/user/update/email/confirm',
        method: 'get'
    },
    kycUpdate: {
        url: '/api/user/kyc',
        method: 'put'
    }
};
export default Resource.create(api);
