var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container record" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v("\n        お客様の投資申込を受付けいたしました\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "b1" }, [
      _vm._v(
        "\n        別途購入金振込のご連絡いたしますので、お待ちください。\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "btn", on: { click: _vm.onNext } }, [
      _vm._v("\n        申込履歴>>>\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }