/**
 * @Author: xiaodongyu
 * @Date: 2024-02-20 16:51:22
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-02-20 16:56:50
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import JA from './languages/ja';
import ZH from './languages/zh';
Vue.use(VueI18n);
export const createI18n = (context) => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'ja',
    messages: {
        zh: ZH,
        ja: JA
    },
    silentTranslationWarn: true
});
