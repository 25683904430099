"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.optionsMapping = exports.headMapping = exports.deleteMapping = exports.patchMapping = exports.putMapping = exports.postMapping = exports.getMapping = exports.requestMapping = void 0;
var util_1 = require("./util");
function requestMapping(requestMappingParam) {
    return function (target, propertyKey, descriptor) {
        if (descriptor === null || descriptor === void 0 ? void 0 : descriptor.value) {
            descriptor.value = (0, util_1.getCustomRequest)(requestMappingParam);
        }
    };
}
exports.requestMapping = requestMapping;
function getMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'get'));
}
exports.getMapping = getMapping;
function postMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'post'));
}
exports.postMapping = postMapping;
function putMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'put'));
}
exports.putMapping = putMapping;
function patchMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'patch'));
}
exports.patchMapping = patchMapping;
function deleteMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'delete'));
}
exports.deleteMapping = deleteMapping;
function headMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'head'));
}
exports.headMapping = headMapping;
function optionsMapping(value) {
    return requestMapping((0, util_1.getRequestMappingParam)(value, 'options'));
}
exports.optionsMapping = optionsMapping;
