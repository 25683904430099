/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-04-30 11:47:14
 */

/* global RUN_PORT */

export const rootTitle = 'Instacash';

export const getHost = () =>
    (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;
