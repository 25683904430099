var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-container" }, [
    _c("div", { staticClass: "left" }, [
      _c("h2", { staticClass: "mb24" }, [
        _vm._v("\n            メールアドレス変更\n        "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n            登録された新しいメールアドレスに変更処理完了用のメールを送信します。メール内に記載されているリンクをクリックして表示される画面から変更処理の完了手続きを行うとメールアドレスが変更されます。\n        "
        ),
      ]),
      _vm._v(" "),
      _vm.form
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "a-form",
                {
                  staticClass: "form",
                  attrs: { layout: "vertical", form: _vm.form },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "新しいメールアドレス" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "email",
                              {
                                rules: [
                                  { required: true, message: " " },
                                  {
                                    type: "email",
                                    message:
                                      "メールのフォーマットが正しくありません",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "['email', {\n                            rules: [\n                                {required: true, message: ' '},\n                                {type: 'email', message: 'メールのフォーマットが正しくありません'}]\n                        }]",
                          },
                        ],
                        attrs: {
                          placeholder: "メールアドレスを入力してください",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-form-item",
                    { attrs: { label: "新しいメールアドレス（確認）" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "emailMust",
                              {
                                rules: [
                                  { required: true, message: " " },
                                  {
                                    type: "email",
                                    message:
                                      "メールのフォーマットが正しくありません",
                                  },
                                  { validator: _vm.isEqualEmailValidator },
                                ],
                                validateFirst: true,
                              },
                            ],
                            expression:
                              "['emailMust', {\n                            rules: [\n                                {required: true, message: ' '},\n                                {type: 'email', message: 'メールのフォーマットが正しくありません'},\n                                {validator: isEqualEmailValidator}\n                            ],\n                            validateFirst: true\n                        }]",
                          },
                        ],
                        attrs: {
                          placeholder: "メールアドレスを入力してください",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn b2",
                      class: { active: _vm.canSubmit },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("\n                    変更申請\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("img", {
      staticClass: "right",
      attrs: { src: require("../login/img/group.jpg"), alt: "group" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }