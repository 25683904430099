<!--
 * @Author: zhaoyang
 * @Date: 2024-02-24 12:42:20
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-02-29 10:53:40
-->

<template>
    <register-layout>
        <div class="register-left">
            <h2 class="bold title">
                アカウント登録はまだ完了していません、下記のメッセージをお読みください。
            </h2>

            <div class="desc">
                １）ご登録いただいたメールアドレス宛にアカウント情報登録のURLを送付いたしましたので、登録を完了してください。
            </div>
            <div class="desc">
                ２）情報登録用URLは２４時間以内有効です。期限が過ぎると新たに仮登録を行ってください。
            </div>
            <div
                v-if="emailTokenExpire"
                class="mt24"
            >
                <div class="desc">
                    メールで送付したURLで登録してください。
                </div>
                <div class="desc">
                    メールが届かない場合<a
                        class="b2 yellow-02"
                        @click="reSend"
                    >「メールの再送信」</a>ボタンをクリックしてください。
                </div>
            </div>
        </div>
        <template #tip>
            <div class="tip">
                <span>以下に該当するお客様は、</span>
                <span class="red">ご利用になれません。</span>
                <span>予めご了承ください</span>
            </div>
            <div
                v-for="item in tipList"
                :key="item"
                class="tip-list c-09"
            >
                {{ item }}
            </div>
            <div
                class="b3 c-09"
                style="margin-top: 0.15rem;"
            >
                こ不明点等がありましたらお間い合わせよりこ連絡下さい。
            </div>
        </template>
    </register-layout>
</template>

<script>
import {mapGetters} from 'vuex';

import User from 'src/common/resource/user';

import RegisterLayout from '../component/register-layout';

export default {
    name: 'SendEmail',

    components: {
        RegisterLayout
    },

    data() {
        return {
            tipList: [
                'マイナンバーをお持ちでない個人の方',
                '非居住者の方',
                '20歳未満の方·75歲以上の方',
                '他の金融商品取引業者又は登録金融機関との間で紛争中の方'
            ]
        };
    },

    computed: {
        ...mapGetters('finance', ['emailTokenExpire'])
    },

    methods: {
        async reSend() {
            await User.send();
            this.$store.dispatch('finance/fetchInfo');
        }
    }
};
</script>

<style lang="scss" scoped>
.register-left {
    max-width: 6.3rem;

    .title {
        margin-top: 0.2rem;
        color: #32302f;
    }
}

.desc {
    margin-top: 0.24rem;
    font-family: Noto Sans JP;
    font-size: 0.16rem;
    font-weight: 400;
    line-height: 0.26rem;
    color: #4c4948;
}

.email {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0.15rem 0.2rem;
    margin-top: 0.24rem;
    background-color: #f1f0f0;
    height: 0.59rem;
    border-radius: 0.12rem;
    font-family: Noto Sans JP;
    font-size: 0.2rem;
    font-weight: 400;
    line-height: 0.26rem;
    color: #32302f;
    width: 5.31rem;
    cursor: pointer;

    .email-icon {
        width: 0.4rem;
        height: 0.29rem;
        margin-right: 0.28rem;
    }

    .arrow {
        margin-left: 0.2rem;
        width: 0.2rem;
    }
}

.btn {
    border-radius: 0.12rem;
    margin-top: 0.6rem;
    width: 3rem;
    height: 0.52rem;
    color: $c-09;
    background-color: $yellow-02;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Noto Sans JP;
    font-size: 0.16rem;
    font-weight: 700;
    line-height: 1;
}

.tip {
    margin-bottom: 0.18rem;
    margin-top: 0.13rem;
    color: #fff;
    font-family: Noto Sans JP;
    font-size: 0.2rem;
    font-weight: 700;
    line-height: 0.26rem;

    .red {
        color: #f66c70;
    }
}

.tip-list {
    position: relative;
    font-family: Noto Sans JP;
    font-size: 0.18rem;
    font-weight: 400;
    line-height: 0.29rem;
    box-sizing: border-box;
    padding-left: 0.2rem;

    &::before {
        content: "・";
        color: #fff;
        font-size: 0.18rem;
        position: absolute;
        left: 0.04rem;
        top: 0;
    }
}

.c-09 {
    color: $c-09;
}

.yellow-02 {
    color: $yellow-02;
}

.mt24 {
    margin-top: 0.48rem;
}
</style>
