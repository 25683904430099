var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c("div", { staticClass: "container product" }, [
        _c("div", { staticClass: "block-img" }, [
          _c("div", { staticClass: "small-title" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("formatProductType")(_vm.detail.type)) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n            " + _vm._s(_vm.detail.name) + "\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-wrap" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                "\n                目標利回り：" +
                  _vm._s(_vm._f("toFullWidth")(_vm.detail.interestRate)) +
                  "％／年\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                "\n                運用期間：" +
                  _vm._s(_vm._f("toFullWidth")(_vm.detail.closedDays)) +
                  "日\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                "\n                ファンド名：" +
                  _vm._s(_vm.detail.name) +
                  "（" +
                  _vm._s(_vm._f("formatProductType")(_vm.detail.type)) +
                  "）\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("span", { staticClass: "desc-text" }, [
              _c("span", { staticClass: "text1" }, [_vm._v("目標利回り")]),
              _vm._v(" "),
              _c("span", { staticClass: "text2" }, [
                _vm._v(
                  _vm._s(_vm._f("toFullWidth")(_vm.detail.interestRate)) + "％"
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text1" }, [_vm._v("/年")]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "desc-text second" }, [
              _c("span", { staticClass: "text1" }, [_vm._v("運用期間")]),
              _vm._v(" "),
              _c("span", { staticClass: "text2" }, [
                _vm._v(_vm._s(_vm._f("toFullWidth")(_vm.detail.closedDays))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text1" }, [_vm._v("日")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wrap" },
          _vm._l(_vm.productFields, function (item) {
            return _c("item-info", {
              key: item.label,
              staticClass: "wrap-item",
              attrs: {
                "label-class": "left",
                "value-class": "right",
                item: item,
                "val-obj": _vm.detail,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "operatorUpdated.value",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                インスタキャッシュ株式会社\n            "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "name.value",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.detail.name) +
                            "（" +
                            _vm._s(
                              _vm._f("formatProductType")(_vm.detail.type)
                            ) +
                            "）\n            "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "applyStartTime.value",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("dateMonth")(_vm.detail.applyStartTime)
                            ) +
                            "～" +
                            _vm._s(
                              _vm._f("dateMonth")(_vm.detail.applyEndTime)
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "interestRate.value",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                年" +
                            _vm._s(
                              _vm._f("toFullWidth")(_vm.detail.interestRate)
                            ) +
                            "％（運用期間利回り" +
                            _vm._s(
                              _vm._f("toFullWidth")(_vm.detail.returnRate)
                            ) +
                            "％）\n            "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "agreement-wrap" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "agree-desc" }, [
              _c("img", {
                staticClass: "check-icon",
                attrs: {
                  src: _vm.agreeDescIsCheck ? _vm.Select : _vm.NoSelect,
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.agreeDescIsCheck = !_vm.agreeDescIsCheck
                  },
                },
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "契約締結前交付書面,匿名組合契約書を全て読み、出資に伴うリスクや出資契約の内容を全て理解し自らの意思および判断に基づき下記金額の出資を申し込みます。"
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.agreementList, function (item, idx) {
              return _c("div", { key: idx, staticClass: "check" }, [
                _c("img", {
                  staticClass: "check-icon",
                  attrs: {
                    src: item.isCheck ? _vm.Select : _vm.NoSelect,
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onCheck(idx, item)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "check-text" }, [
                  _vm._v(_vm._s(_vm.pdfDisplayNameMap[item.text])),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "check-link",
                    on: {
                      click: function ($event) {
                        return _vm.handleRead(idx)
                      },
                    },
                  },
                  [_vm._v("読む")]
                ),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "money-wrap" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n            出資申込金額\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n            （残り募集金額を超える額は申し込めません）\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n            （" +
                _vm._s(_vm.detail.userMinAmount) +
                "万円以上１万円単位）\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.money,
                  expression: "money",
                },
              ],
              staticClass: "money-input",
              class: { error: _vm.noLess },
              attrs: {
                type: "number",
                max: _vm.detail.residueAmount,
                min: _vm.detail.userMinAmount,
                placeholder: "〇〇〇〇",
              },
              domProps: { value: _vm.money },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.money = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "big-tip" }, [_vm._v("万円")]),
          ]),
          _vm._v(" "),
          _vm.showTip
            ? _c("div", { staticClass: "input-tip" }, [
                _vm._v(
                  "\n            ファンド募集上限額とお客樣の出資上限を超えた金額は申し込めません\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "money-tip" },
            _vm._l(_vm.applyFields, function (item) {
              return _c("item-info", {
                key: item.label,
                staticClass: "money-item",
                attrs: {
                  "label-class": "left",
                  "value-class": "right",
                  item: item,
                  "val-obj": _vm.detail,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "raiseAmount.value",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toFullWidth")(
                                  _vm._f("currencyFormat")(
                                    _vm.detail.raiseAmount
                                  )
                                )
                              ) +
                              "万円（" +
                              _vm._s(
                                _vm._f("toFullWidth")(_vm.detail.raiseRatio)
                              ) +
                              "％）\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.detail.userMaxAmount
            ? _c("div", { staticClass: "tip-desc" }, [
                _vm._v(
                  "\n            「" +
                    _vm._s(_vm.userInfo.name) +
                    "様の出資申込可能額は" +
                    _vm._s(
                      _vm._f("toFullWidth")(
                        _vm._f("currencyFormat")(_vm.detail.userMaxAmount)
                      )
                    ) +
                    "万円以下です。」\n        "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-wrap" }, [
          _c(
            "div",
            {
              staticClass: "btn",
              class: { active: _vm.canSubmit },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("\n            申込\n        ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agree-title" }, [
      _c("span", { staticClass: "title-left" }, [_vm._v("以下の同意事項を")]),
      _c("span", { staticClass: "title-right" }, [
        _vm._v("お読みいただき、同意してください。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }