/*
 * @Author: huafengli
 * @Date: 2024-05-08 11:30:43
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-16 10:26:28
 */
import Resource from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    order: {
        url: '/api/order',
        method: 'post',
        formData: true
    },
    history: {
        url: '/api/order/history/list',
        method: 'get'
    },
    holding: {
        url: '/api/order/holding/list',
        method: 'get'
    }
};
export default Resource.create(api);
