var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index" }, [
      _c("div", { staticClass: "banner bold" }, [
        _c("p", [
          _vm._v(
            "\n            当社はお客様に新たな投資機会を提供し、\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n            お客様の資産形成に貢献いたします。\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h1", { staticClass: "mt100" }, [
          _vm._v("\n            インスタキャッシュについて\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt53" }, [
          _c("div", { staticClass: "w-card row-item mr20 c-04" }, [
            _c("div", { staticClass: "w-card-icon" }, [
              _c("img", {
                staticClass: "w-card-icon-inner",
                attrs: { src: require("./img/icon1.png") },
              }),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mt60" }, [
              _vm._v("\n                    会社概要\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-04" }, [
              _c("p", { staticClass: "b2 mt16" }, [
                _vm._v(
                  "\n                        Instacash株式会社\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        第二種金融商品取引業者：関東財務局長（金商）第○○号\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        ヒューリック渋谷一丁目ビル 624室  info@instcash.jp\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        〒150-0002 東京都渋谷区渋谷1-3-9\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        代表取締役 湯浅健一 (予定)\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        資本金 9,000万円\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        加入協会 一般社団法人第二種金融商品取引業協会\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-card row-item mr20" }, [
            _c("div", { staticClass: "w-card-icon" }, [
              _c("img", {
                staticClass: "w-card-icon-inner",
                attrs: { src: require("./img/icon2.png") },
              }),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mt60" }, [
              _vm._v(
                "\n                    当社グループについて\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2 mt16 c-04" }, [
              _vm._v(
                "                   当社は世界各国で活動するFintopiaグループの一員です。当社の前代表取締役耿博と前監査役の劉永延は中国の同じ大学同期生です。耿はマイクロソフト、劉はGoogleとFacebookにそれぞれ勤務しました。シリコンバレーで再会した二人は意気投合し資金の貸手借手をマッチングさせるプラットフォーム事業を中国で起業、そして大きな成果を収めました。成功の要因はAIを駆使した精緻なクレジットスコアリングです。他の国でも、と選ばれたのがインドネシアです。ここでも成果を挙げ、更に日本からの投資資金を呼込むため金融商品取引業の登録を目指すことになりました。\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-card mt20 mr20" }, [
          _c("div", { staticClass: "w-card-icon" }, [
            _c("img", {
              staticClass: "w-card-icon-inner",
              attrs: { src: require("./img/icon3.png") },
            }),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt60" }, [
            _vm._v("\n                代表者挨拶\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-04" }, [
            _c("p", { staticClass: "b2 mt16" }, [
              _vm._v(
                "\n                  代表取締役の湯浅と申します。当社が提供する新たな投資機会とは日本以外の国の一般消費者に小口資金を貸付ける事業への出資です。金融商品名は「貸付型ファンド」です。事業先のインドネシアではクレジットカードの普及率が低く短期借入資金需要が恒常的に高いです。それ即ちビジネスチャンスであり当社のグループ会社自己資金と現地投資家資金とで事業を先行運営してきました。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    AI駆動の精緻なアプリで貸付審査を行い事業成績は良好に推移しております。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    　少し私の紹介をさせていただきます。新卒で生命保険会社に入社し株式運用部門から外資系証券会社に転じ株式、債券、各デリバティブのセールス＆トレーディングに従事しました。一度業界を離れた後、不動産証券化の学習を通じファンドビジネスと金融商品取引法に関心を覚え業界に復帰、ブティック型金商業者でファンドの組成・販売、貸金業務等を経験しました。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    私が在籍した生保では有価証券の他に不動産や融資も運用対象でそれぞれ独立したアセットクラスでした。今では不動産や融資も証券化により小口化され個人向け有価証券として運用出来るようになり隔世の感があります。\n                "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "b2" }, [
              _vm._v(
                "\n                    株式や債券以外のオルタナティブ投資商品を提供することで運用リスクの分散をお手伝い出来ればと存じます。\nどうぞ宜しくお願いいたします。\n                "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "mission" }, [
        _c("h1", { staticClass: "c-09" }, [_vm._v("沿革")]),
        _vm._v(" "),
        _c("div", { staticClass: "mt37" }, [
          _c("span", { staticClass: "b2 bold" }, [
            _vm._v("\n                2017\n            "),
          ]),
          _vm._v("\n            --------\n            "),
          _c("span", { staticClass: "b2" }, [
            _vm._v("\n                2024\n            "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-card" }, [
          _c("div", { staticClass: "row-between" }, [
            _c("div", { staticClass: "year" }, [
              _vm._v("\n                    2024\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "text-align": "right" } }, [
              _c("h3", [
                _vm._v(
                  "\n                        2024年〇月〇日 第二種金融商品取引業登録完了\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "\n                        2024年○月○日 一般社団法人第二種金融商品取引業協会加入\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "section3" }, [
        _c("h1", [_vm._v("\n            ニュース\n        ")]),
        _vm._v(" "),
        _c("h3", { staticClass: "mt60" }, [
          _vm._v("\n            2024年〇月〇日 金融商品取引業者登録\n        "),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "\n            2024年〇月〇日 インドネシアMFファンド第一号運用開始\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }