/**
 * @Author: xiaodongyu
 * @Date: 2024-02-28 16:42:22
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-02-28 17:41:00
 */
export const pdfDisplayNameMap = {
    // footer pdfs
    'anti-social': '反社会的勢力に対する基本方針',
    'electromagnetic': '電磁的方法による書面の交付に関する承諾書',
    'anti-money': 'マネロン・テロ資金対策方針',
    'privacy-policy': 'プライバシーポリシー(個人情報保護宣言）',
    'information-security': '情報セキュリティ基本方針',
    'personal-data': '個人データの安全管理に係る基本方針',
    'website': 'ウェブサイト利用規約',
    'sales-act': '金融商品販売法に基づく勧誘方針',
    'transaction': '取引約款',
    // other pdfs
    'fund-risk': 'ファンドリスクについて',
    'anonymous': '匿名組合契約書',
    'anonymous-copy': '匿名組合契約書_副本',
    'contract-before': '契約締結前交付書面',
    'contract': '契約締結時交付書面'
};
