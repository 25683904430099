<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-24 16:34:50 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-27 13:58:50 -->

<template>
    <div class="register-step">
        <div class="step">
            <component
                :is="step === 1 ? 'h2' : 'h4'"
                :class="{yellow: step === 1}"
            >
                01.
            </component>
            <span
                :class="{'b2 yellow': step === 1, b4: step !== 1}"
            >
                仮登録
            </span>
        </div>
        <div class="step-divider" />
        <div class="step">
            <component
                :is="step === 2 ? 'h2' : 'h4'"
                :class="{yellow: step === 2}"
            >
                02.
            </component>
            <span
                :class="{'b2 yellow': step === 2, b4: step !== 2}"
            >
                本登録
            </span>
        </div>
        <div class="step-divider" />
        <div class="step">
            <component
                :is="step === 3 ? 'h2' : 'h4'"
                :class="{yellow: step === 3}"
            >
                03.
            </component>
            <span
                :class="{'b2 yellow': step === 3, b4: step !== 3}"
            >
                本人確認書類提出
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterStep',

    props: {
        step: {
            type: Number,
            default: 1
        }
    }
};
</script>

<style lang="scss" scoped>
.register-step {
    padding: 0.2rem 0 0.4rem;
    width: 100%;
    height: 1.02rem;
    background: linear-gradient(180deg, $c-08 84.5%, rgba(252, 252, 252, 0) 100%);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: $c-03;

    .step {
        display: flex;
        align-items: baseline;

        .yellow {
            color: $yellow-02;
        }

        h2,
        h4 {
            // override antdv head color
            color: $c-03;
        }
    }

    .step-divider {
        margin: 0 0.1rem;
        width: 0.4rem;
        border: 2px dotted $c-03;
    }
}
</style>
