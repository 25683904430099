var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "des-group-row b3" }, [
    _c(
      "div",
      { staticClass: "des-group-row-label bold" },
      [
        _vm._t(_vm.item.field + ".label", function () {
          return [
            _vm._v("\n            " + _vm._s(_vm.item.label) + "\n        "),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "des-group-row-value", class: _vm.item.valueClass },
      [
        _vm._t(_vm.item.field + ".value", function () {
          return [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toFullWidth")(_vm.value)) +
                "\n        "
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }