/*
 * @Author: huafengli
 * @Date: 2024-04-30 12:28:56
 * @Last Modified by: ruiwang  huafengli
 * @Last Modified time: 2024-05-09 14:25:54
 */
const getKeys = (keyString = '') => keyString.split(/\]?[[.]/);
export const pickValue = (obj, keyString) => {
    const keys = getKeys(keyString);
    let value = obj;
    try {
        keys.forEach(key => (value = value[key]));
    }
    catch (err) {
        value = undefined;
    }
    return value;
};
export const updateToken = (token) => {
    localStorage.setItem('token', token);
};
