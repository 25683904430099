/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-02-27 11:58:23
 */

import Vuex, {Store} from 'vuex';

import Vue from 'ssr-common/vue';

import finance from './module/finance';

Vue.use(Vuex);

export const createStore = () => new Store({
    modules: {
        finance
    },
    state: {}
});
