/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: xinzhong
 * @Last Modified time: 2023-06-01 15:36:56
 */

import Vue, {VueRouter, createVueRouter} from 'ssr-common/vue';

import routes from './routes';

Vue.use(VueRouter);

export const createRouter = () => createVueRouter({routes});
