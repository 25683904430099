<!-- @Author: ruiwang -->
<!-- @Date: 2024-02-25 14:46:43 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-28 19:39:02 -->

<template>
    <div
        v-if="list && list.length"
        class="container trade-record-page"
    >
        <section>
            <h1 class="bold">
                申込履歴
            </h1>
        </section>
        <section class="mt28">
            <a-list :data-source="list">
                <div
                    v-if="showLoadingMore"
                    slot="loadMore"
                    class="loading-more-container"
                >
                    <a-spin v-if="loadingMore" />
                    <div
                        v-else
                        class="more-btn b3"
                        @click="loadMore"
                    >
                        もっと読み込む
                    </div>
                </div>
                <template #renderItem="item">
                    <div class="info-card des-group mb20">
                        <div class="des-group-row b3">
                            <div class="des-group-row-label bold">
                                申込日
                            </div>
                            <div class="des-group-row-value pdf-row">
                                {{ item.timeCreated | date }}
                            </div>
                        </div>
                        <div
                            v-if="item.payTime"
                            class="des-group-row b3"
                        >
                            <div class="des-group-row-label bold">
                                入金日
                            </div>
                            <div class="des-group-row-value pdf-row">
                                {{ item.payTime | date }}
                            </div>
                        </div>
                        <div class="des-group-row b3">
                            <div class="des-group-row-label bold">
                                ファンド名
                            </div>
                            <div class="des-group-row-value pdf-row">
                                {{ item.name }}（{{ item.type | formatProductType }}）
                            </div>
                        </div>
                        <div class="des-group-row b3">
                            <div class="des-group-row-label bold">
                                購入金額
                            </div>
                            <div class="des-group-row-value pdf-row">
                                {{ item.payAmount | currencyFormat }}万円
                            </div>
                        </div>
                        <div class="des-group-row b3">
                            <div class="des-group-row-label bold">
                                各種書類
                            </div>
                            <div class="des-group-row-value pdf-row">
                                <div
                                    v-for="item in staticPdfList"
                                    :key="item"
                                    class="pdf-item"
                                    @click="handleRead(item)"
                                >
                                    <div class="pdf-item-wrapper">
                                        <pdf-svg />
                                        {{ pdfDisplayNameMap[item] }}.pdf
                                    </div>
                                </div>
                                <template v-if="item.files && item.files.length">
                                    <div
                                        v-for="(name, urlIdx) in item.files"
                                        :key="urlIdx"
                                        class="pdf-item"
                                        @click="onReadFile(name)"
                                    >
                                        <div class="pdf-item-wrapper">
                                            <pdf-svg />
                                            {{ name | sliceReportName }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="des-group-row b3">
                            <div class="des-group-row-label bold">
                                運用報告書
                            </div>
                            <div class="des-group-row-value pdf-row">
                                <template v-if="item.reports && item.reports.length">
                                    <div
                                        v-for="(name, urlIdx) in item.reports"
                                        :key="urlIdx"
                                        class="pdf-item"
                                        @click="onReadFile(name)"
                                    >
                                        <div class="pdf-item-wrapper">
                                            <pdf-svg />
                                            {{ name | sliceReportName }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </a-list>
        </section>
    </div>
    <i-empty v-else />
</template>

<script>
import moment from 'moment';

import PdfSvg from 'instacash/common/component/finance/footer/pdf.vc.svg';
import {pdfDisplayNameMap} from 'instacash/common/constant';
import Common from 'instacash/common/resource/common';
import Order from 'instacash/common/resource/order';

export default {
    name: 'ProfilePage',

    components: {
        PdfSvg
    },

    filters: {
        date(time) {
            return moment(time).format('YYYY/MM/DD');
        },
    },

    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            total: 0,
            list: null,
            loadingMore: false,
            staticPdfList: [
                'anonymous',
                'contract-before'
            ],
            pdfDisplayNameMap

        };
    },

    computed: {
        totalPage() {
            const {pageSize, total} = this;

            return Math.ceil(total / pageSize);
        },

        showLoadingMore() {
            return this.pageNo < this.totalPage;
        }
    },

    mounted() {
        this.fetchHistoryList();
    },

    methods: {
        async loadMore() {
            this.loadingMore = true;

            if (this.pageNo < this.totalPage) {
                try {
                    await this.fetchHistoryList(this.pageNo + 1);
                } catch (e) {
                    //
                }

                this.loadingMore = false;
            }
        },

        async fetchHistoryList(pageNo = this.pageNo) {
            const params = {pageNo, pageSize: this.pageSize};
            const {data: {body}} = await Order.history({params});
            this.pageNo = pageNo;

            this.list = (this.list || []).concat(body?.records || []);
            this.total = body?.total || 0;
        },

        async onReadFile(fileKey) {
            const {data: {body: url}} = await Common.file({params: {fileKey}});
            window.open(url, '_blank');
        },

        handleRead(link) {
            window.open(`/static/pdf/${link}.pdf`);
        }
    },
};
</script>

<style lang="scss" scoped>
.mt28 {
    margin-top: 0.28rem;
}

.mb20 {
    margin-bottom: 0.2rem;
}

.mr40 {
    margin-right: 0.4rem;
}

.trade-record-page {
    h1 {
        color: $c-01;
    }

    .info-card {
        padding: 0.28rem 0.48rem 0.14rem;
        border-radius: 0.16rem;
        border: 1px solid $c-06;
        width: 9rem;

        .des-group-row-label {
            width: 2rem;
        }
    }

    .pdf-row {
        display: flex;
        align-items: center;
    }

    .pdf-item {
        color: inherit;
        transition: color 0.3s;
        margin-left: 0.4rem;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            color: $yellow-02;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                margin-right: 0.03rem;
            }
        }
    }
}

.loading-more-container {
    display: flex;
    justify-content: center;

    .more-btn {
        cursor: pointer;
        padding: 0.2rem;
    }
}
</style>

