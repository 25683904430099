var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-step" }, [
    _c(
      "div",
      { staticClass: "step" },
      [
        _c(
          _vm.step === 1 ? "h2" : "h4",
          { tag: "component", class: { yellow: _vm.step === 1 } },
          [_vm._v("\n            01.\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { class: { "b2 yellow": _vm.step === 1, b4: _vm.step !== 1 } },
          [_vm._v("\n            仮登録\n        ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "step-divider" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "step" },
      [
        _c(
          _vm.step === 2 ? "h2" : "h4",
          { tag: "component", class: { yellow: _vm.step === 2 } },
          [_vm._v("\n            02.\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { class: { "b2 yellow": _vm.step === 2, b4: _vm.step !== 2 } },
          [_vm._v("\n            本登録\n        ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "step-divider" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "step" },
      [
        _c(
          _vm.step === 3 ? "h2" : "h4",
          { tag: "component", class: { yellow: _vm.step === 3 } },
          [_vm._v("\n            03.\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { class: { "b2 yellow": _vm.step === 3, b4: _vm.step !== 3 } },
          [_vm._v("\n            本人確認書類提出\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }