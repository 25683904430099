/*
 * @Author: xiaodongyu
 * @Date 2019-05-24 18:01:07
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2019-09-11 17:41:10
 */

import {reportBuilder} from '@yqg/reporter';

import ApiRouter from 'ssr-common/constant/api-router';

reportBuilder.setOptions({
    defaultAjaxUrl: ApiRouter.apiWeb.report
});
