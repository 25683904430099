<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:11:43 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:25 -->

<template>
    <div>
        <h2 class="b2 mb28">
            本人確認書類の提出
        </h2>
        <div
            class="tab-bar mb20"
            :class="{'not-allowed': isDisabled}"
        >
            <div
                v-for="(label, key) in $options.nationalityEnum"
                :key="key"
                class="tab-bar-item b1"
                :class="getActiveTabClass(key)"
                @click="onTabChange(key)"
            >
                {{ label }}
            </div>
        </div>
        <section>
            <a-form-item
                :key="identityTypeDef.field"
                class="custom-btn-radio-group-wrapper"
            >
                <a-radio-group
                    v-decorator="['identityType', {rules: [{ required: true }]}]"
                    class="custom-card-radio-group"
                    :disabled="isDisabled"
                    @change="onRadioChange"
                >
                    <a-radio-button
                        v-for="opt in identityTypeDef.options"
                        :key="opt.value"
                        :style="{width: opt.isJpIdCard || opt.isStarCard ? '100%' : undefined}"
                        :value="opt.value"
                    >
                        <div
                            v-if="opt.isJpIdCard"
                            class="flex-between"
                        >
                            <div>
                                <div class="b2">
                                    <icon-star class="star-icon icon-small" />
                                    {{ opt.label }}
                                </div>
                                <div class="b4 tab-desc">
                                    1書類の提出のみで可
                                </div>
                            </div>
                            <div class="tab-item-right flex">
                                <img
                                    class="id-card"
                                    src="../../register/step2/img/id-card-front.png"
                                >
                                <img
                                    class="id-card ml12"
                                    src="../../register/step2/img/id-card-back.png"
                                >
                            </div>
                        </div>
                        <div
                            v-else-if="opt.isStarCard"
                            class="flex-between"
                        >
                            <div>
                                <div
                                    v-for="label in opt.labels"
                                    :key="label"
                                    class="dot b2"
                                >
                                    {{ label }}
                                </div>
                            </div>
                            <div class="tab-item-right flex pr10">
                                <icon-star class="star-icon icon-big" />
                            </div>
                        </div>
                        <div v-else>
                            <div
                                v-for="label in opt.labels"
                                :key="label"
                                class="dot b2"
                            >
                                {{ label }}
                            </div>
                        </div>
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>
        </section>
        <section
            v-for="(group, idx) in fileDefGroup"
            :key="group.key"
            class="controler-box mt20"
        >
            <a-form-item
                :key="`${tab}_${group.row[0].value}`"
                :label="`${idx + 1}種類目の本人確認書類`"
            >
                <a-upload-dragger
                    v-decorator="genFileDecorator(group.row[0].value)"
                    :accept="$options.accept"
                    :custom-request="customRequest"
                    :before-upload="getBeforeUpload(group.row[0].value)"
                    :file-list="fileListMap[group.row[0].value]"
                    class="dragger-upload front"
                    :disabled="isDisabled"
                    @change="onFileChange($event, group.row[0].value)"
                >
                    <h5>
                        {{ group.row[0].cardLabel }}
                    </h5>
                    <div class="b3">
                        ファイルをアップロード
                    </div>
                </a-upload-dragger>
            </a-form-item>
            <a-form-item
                v-if="group.row[1]"
                :key="`${tab}_${group.row[1].value}`"
                style="padding-top: 28px;"
            >
                <a-upload-dragger
                    v-decorator="genFileDecorator(group.row[1].value)"
                    :accept="$options.accept"
                    :custom-request="customRequest"
                    :before-upload="getBeforeUpload(group.row[1].value)"
                    :file-list="fileListMap[group.row[1].value]"
                    class="dragger-upload back"
                    :disabled="isDisabled"
                    @change="onFileChange($event, group.row[1].value)"
                >
                    <h5>
                        {{ group.row[1].cardLabel }}
                    </h5>
                    <div class="b3">
                        ファイルをアップロード
                    </div>
                </a-upload-dragger>
            </a-form-item>
        </section>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';

import {nationalityEnum} from 'src/common/constant/enum';
import Common from 'src/common/resource/common';

import {cardType, identityTypeMap} from '../../register/step2/fields';
import IconStar from '../../register/step2/icon/star';
import FormMixin from '../mixins/form';
const accept = '.jpg,.jpeg,.png';

export default {
    name: 'KycFileInfo',

    nationalityEnum,

    accept,

    components: {
        IconStar
    },

    mixins: [FormMixin],

    props: {
        form: {
            type: Object,
            default: () => ({})
        },

        formData: {
            type: Object,
            default: () => ({})
        },
    },

    data() {
        return {
            tab: '',
            fileListMap: {},
            uploadErrMsgMap: {},
        };
    },

    computed: {
        ...mapGetters('finance', ['userInfo']),

        identityTypeDef() {
            const {tab} = this;
            const map = identityTypeMap[tab];
            if (!tab) {
                return;
            }

            const opts = [];
            Object.keys(map).forEach(key => {
                const def = {
                    labels: [],
                    value: key
                };

                const files = [];
                const cards = map[key];
                cards.forEach(item => {
                    const {type, double} = item;
                    const row = [];
                    const label = cardType[type];
                    const opt = {
                        label,
                        cardLabel: `${label}証明書（表）`,
                        value: `${type}.front`
                    };

                    row.push(opt);
                    if (double) {
                        row.push({
                            label: ' ',
                            cardLabel: `${label}証明書（裏）`,
                            value: `${type}.back`
                        });
                    }

                    files.push({
                        key: type,
                        row
                    });
                });

                const isJpIdCard = tab === 'JP_DOMESTIC' && key === 'ID_CARD';
                const isStarCard = tab === 'FOREIGNER' && ['ID_CARD_RESIDENCE', 'ID_CARD_PERMANENT_RESIDENCE'].includes(key);
                const len = Object.keys(cards).length;
                const labels = cards.map((card, idx) => {
                    let lab = cardType[card.type];
                    if (card.double) {
                        lab = `${lab}（両面）`;
                    }

                    if (idx === len - 1 && isStarCard) {
                        lab = `${lab}【推奨】`;
                    }

                    return lab;
                });
                Object.assign(def, {
                    files,
                    labels,
                    label: labels.join('\n'),
                    isJpIdCard,
                    isStarCard
                });
                opts.push(def);
            });

            return {
                field: 'identityType',
                label: nationalityEnum[tab],
                options: opts
            };
        },

        fileDefGroup() {
            const {identityType} = this.formData;

            if (!identityType) return [];

            const files = this.identityTypeDef.options.find(item => item.value === identityType)?.files;

            return files;
        },
    },

    async beforeMount() {
        this.tab = this.userInfo.nationality;
        this.fileListMap = this.fileDefGroup.reduce((map, group) => {
            const {key} = group;
            const imageMap = this.formData[key];
            Object.keys(imageMap).forEach(field => {
                const file = imageMap[field];
                map[`${key}.${field}`] = [file];
            });

            return map;
        }, {});
    },

    methods: {

        onTabChange(tab) {
            this.tab = tab;
            this.fileListMap = {};
            this.$emit('setFieldsValue', {
                identityType: tab === 'JP_DOMESTIC' ? 'ID_CARD' : 'ID_CARD_RESIDENCE',
            });
        },

        getActiveTabClass(type) {
            const isActive = this.tab === type;

            return {
                'bold': isActive,
                'tab-bar-item-active': isActive,
                'click-disabled': this.isDisabled
            };
        },

        onRadioChange() {
            this.fileListMap = {};
        },

        genFileDecorator(field) {
            return [field, {
                rules: [{required: true, message: ' '}],
                valuePropName: 'file',
                getValueFromEvent: e => {
                    if (Array.isArray(e)) {
                        return e[0];
                    }

                    return e?.fileList?.[0];
                }
            }];
        },

        onFileChange({file}, key) {
            const {status, response} = file;
            if (['removed', 'error'].includes(status) || (status === 'done' && !response)) {
                this.$set(this.fileListMap, key, []);

                return;
            }

            this.$set(this.fileListMap, key, [file]);
        },

        async customRequest(options) {
            const {file, onError, onProgress, onSuccess} = options;
            try {
                const {data: {body}} = await Common.upload({file}, {onUploadProgress: onProgress});
                onSuccess(body);
            } catch (err) {
                onError(err);
            }
        },

        getBeforeUpload(field) {
            // eslint-disable-next-line
            return file => {
                return new Promise((resolve, reject) => {
                    const {isValid, message} = this.validateFile(file);

                    const [cardType, type] = field.split('.');
                    const cardMsg = this.uploadErrMsgMap[cardType] || {front: '', back: ''};
                    if (!isValid) {
                        cardMsg[type] = message;
                        this.$set(this.uploadErrMsgMap, cardType, cardMsg);
                        this.$error(message);
                        reject();

                        return;
                    }

                    cardMsg[type] = '';
                    this.$set(this.uploadErrMsgMap, cardType, cardMsg);
                    // this.message = null;
                    resolve();
                });
            };
        },

        validateFile(file) {
            const {name, size} = file;

            const minSize = 0;
            const maxSize = 4 * 1024 * 1024;
            const accepts = accept?.split(',') || [];

            if (accepts.length && !accepts.some(ext => new RegExp(`${ext}$`, 'i').test(name))) {
                return {isValid: false, message: '利用可能なファイル形式：JPG、JPEG、PNG（大文字・小文字どちらもか）'};
            }

            if (size < minSize || size > maxSize) {
                return {
                    isValid: false,
                    // eslint-disable-next-line max-len
                    message: 'ファイルサイズ：１ファイルあたり４Mb以内'
                };
            }

            return {isValid: true, message: null};
        },

    }
};
</script>
<style lang="scss" scoped>
.ml12 {
    margin-left: 0.12rem;
}

.mt20 {
    margin-top: 0.2rem;
}

.mb20 {
    margin-bottom: 0.2rem;
}

.mt24 {
    margin-top: 0.24rem;
}

.mb28 {
    margin-bottom: 0.28rem;
}

.pr10 {
    padding-right: 0.1rem;
}

.controler-box {
    display: flex;
    flex-wrap: wrap;

    .ant-form-item {
        width: calc(50% - 0.15rem);
        margin-bottom: 0.24rem;

        &:nth-child(odd) {
            margin-right: 0.3rem;
        }
    }
}

.step-tit {
    display: flex;
}

.tab-bar {
    display: flex;
    padding: 0.06rem;
    border-radius: 0.12rem;
    background-color: $grey-01;

    &-item {
        padding: 0.06rem;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.08rem;
        cursor: pointer;

        &:first-child {
            margin-right: 0.06rem;
        }
    }

    &-item-active {
        background-color: $c-09;
    }
}

.dragger-upload {
    ::v-deep {
        .ant-upload.ant-upload-drag {
            border: none;
            height: 1.68rem;
            border-radius: 0.16rem;
            box-sizing: border-box;
            padding: 0.15rem;
        }
    }

    &.front {
        ::v-deep {
            .ant-upload.ant-upload-drag {
                background-color: $gold-01;
            }
        }
    }

    &.back {
        ::v-deep {
            .ant-upload.ant-upload-drag {
                background-color: $grey-01;
            }
        }
    }
}

.dot {
    display: flex;
    align-items: center;

    &::before {
        content: "";
        margin-right: 0.08rem;
        background-color: $green-03;
        display: block;
        width: 0.08rem;
        height: 0.08rem;
        border-radius: 50%;
    }
}

.icon-small {
    width: 0.12rem;
    height: 0.12rem;
}

.icon-big {
    width: 0.2rem;
    height: 0.2rem;
}

.star-icon {
    fill: $green-03;
}

.tab-desc {
    color: $propel-03;
}

.ant-radio-button-wrapper-checked {
    .star-icon {
        fill: $c-09;
    }

    .tab-desc {
        color: $c-09;
    }

    .dot {
        &::before {
            background-color: $c-09;
        }
    }
}

.c-09 {
    color: $c-09;
}

.flex {
    display: flex;
    align-items: center;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.id-card {
    width: 1rem;
}

.click-disabled {
    pointer-events: none;
}

.not-allowed {
    cursor: not-allowed;
}

</style>
