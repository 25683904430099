/**
 * @Author: xiaodongyu
 * @Date: 2023-07-28 12:08:36
 * @Last Modified by: huirubai
 * @Last Modified time: 2023-12-22 15:59:21
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Vue from 'vue';
import { initSentry as initBaseSentry } from '@shared/client-next/util/sentry';
export * from '@sentry/vue';
export function initSentry(_a) {
    var { router, throwTestError, beforeSend } = _a, rest = __rest(_a, ["router", "throwTestError", "beforeSend"]);
    initBaseSentry(Object.assign({ router, throwTestError, beforeSend, Vue }, rest));
}
