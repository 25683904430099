var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app", attrs: { id: "app" } },
    [
      _c("nav-bar"),
      _vm._v(" "),
      _c(
        "div",
        { class: { "app-content": true, "app-content-sticky": _vm.hideNav } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("back-btn"),
      _vm._v(" "),
      _c("footer-bar"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRequesting,
              expression: "isRequesting",
            },
          ],
          staticClass: "indicator-mask",
        },
        [_vm._m(0)]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "instacash-indicator" }, [
      _c("div", { staticClass: "indeterminate" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }