/**
 * @Author: xiaodongyu
 * @Date: 2023-07-28 12:08:36
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-06-20 11:30:17
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { BrowserTracing, init, vueRouterInstrumentation } from '@sentry/vue';
import { STAGE } from '@shared/common/constant/stage';
export * from '@sentry/vue';
const isProd = /prod/i.test(STAGE);
const tracesSampleRate = isProd ? 0.01 : 1.0;
export function initSentry(_a) {
    var { router, throwTestError, beforeSend } = _a, rest = __rest(_a, ["router", "throwTestError", "beforeSend"]);
    try {
        // only init sentry in test/feat/prod env
        if (!/test|feat|prod/i.test(STAGE)) {
            return;
        }
        const filters = [
            filterNoneErrorRejection,
            beforeSend
        ].filter(Boolean);
        init(Object.assign({ environment: STAGE, integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router)
                })
            ], 
            // enable sentry debug mode in none prod env
            debug: !isProd, 
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate, release: window.__SENTRY_PROJECT_RELEASE_NAME__ || undefined, beforeSend: (event, hint) => __awaiter(this, void 0, void 0, function* () {
                let result = event;
                for (const filter of filters) {
                    result = (yield (filter === null || filter === void 0 ? void 0 : filter(result, hint)));
                    if (!result)
                        break;
                }
                return result;
            }) }, rest));
    }
    catch (err) {
        // ignore
    }
    if (throwTestError) {
        setTimeout(() => {
            throw new Error(typeof throwTestError === 'string' ? throwTestError : 'sentry test');
        }, 3e3);
    }
}
/**
 * filter Modal close rejection, axios cancel rejection, etc.
 */
const filterNoneErrorRejection = (event, hint) => {
    if (hint.originalException instanceof Error) {
        return event;
    }
    return null;
};
