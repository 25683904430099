var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "i-empty" }, [
      _c("img", { attrs: { src: require("./empty.svg") } }),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v("\n        対象がありません。\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }