var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length
    ? _c(
        "div",
        { staticClass: "container hold" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n        保有フアンド\n    "),
          ]),
          _vm._v(" "),
          _c(
            "a-list",
            {
              attrs: { "data-source": _vm.list },
              scopedSlots: _vm._u(
                [
                  {
                    key: "renderItem",
                    fn: function (item) {
                      return [
                        _c(
                          "div",
                          { staticClass: "box" },
                          [
                            _c("div", { staticClass: "box-title" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatProductType")(item.type)
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "title1" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.name) +
                                  "（" +
                                  _vm._s(
                                    _vm._f("formatProductType")(item.type)
                                  ) +
                                  "）\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "title2" }, [
                              _vm._v(
                                "\n                    目標利回り：" +
                                  _vm._s(
                                    _vm._f("toFullWidth")(item.interestRate)
                                  ) +
                                  "％／年\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "title2" }, [
                              _vm._v(
                                "\n                    運用期間：" +
                                  _vm._s(
                                    _vm._f("toFullWidth")(item.closedDays)
                                  ) +
                                  "日\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "title2" }, [
                              _vm._v(
                                "\n                    ファンド名：" +
                                  _vm._s(item.name) +
                                  "（" +
                                  _vm._s(
                                    _vm._f("formatProductType")(item.type)
                                  ) +
                                  "）\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pdf-wrap" },
                              [
                                _vm._l(_vm.staticPdfList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item,
                                      staticClass: "pdf-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRead(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "pdf-icon",
                                        attrs: {
                                          src: require("./img/pdf.svg"),
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "pdf-title" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.pdfDisplayNameMap[item]
                                            ) +
                                            ".pdf\n                        "
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                item.files && item.files.length
                                  ? _vm._l(item.files, function (name, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: idx,
                                          staticClass: "pdf-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onReadFile(name)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "pdf-icon",
                                            attrs: {
                                              src: require("./img/pdf.svg"),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "pdf-title" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm._f("sliceReportName")(
                                                      name
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            item.publishStatus
                              ? [
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "info-title" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("currencyFormat")(
                                                item.evaluateAmount
                                              )
                                            ) +
                                            "万ルピア(※1)\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-desc" }, [
                                        _vm._v(
                                          "\n                                贷付残高(" +
                                            _vm._s(
                                              _vm._f("date")(item.evaluateTime)
                                            ) +
                                            ")\n                            "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "info-item" }, [
                                      _c("div", { staticClass: "info-title" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("currencyFormat")(
                                                item.indoTotalAmount
                                              )
                                            ) +
                                            "万ルピア(※2)\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-desc" }, [
                                        _vm._v(
                                          "\n                                事業開始時(" +
                                            _vm._s(
                                              _vm._f("date")(item.indoTime)
                                            ) +
                                            ")\n                            "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "sub-info" }, [
                                    _vm._v(
                                      "\n                        (※" +
                                        _vm._s(
                                          _vm._f("date")(item.evaluateTime)
                                        ) +
                                        "時点の金額であリ将来変動します)\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "sub-info" }, [
                                    _vm._v(
                                      "\n                        (※2出資金" +
                                        _vm._s(
                                          _vm._f("currencyFormat")(
                                            item.totalAmount
                                          )
                                        ) +
                                        "万円(@" +
                                        _vm._s(
                                          _vm._f("currencyFormat")(
                                            item.currentRate
                                          )
                                        ) +
                                        "))\n                    "
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3311275484
              ),
            },
            [
              _vm.showLoadingMore
                ? _c(
                    "div",
                    {
                      staticClass: "loading-more-container",
                      attrs: { slot: "loadMore" },
                      slot: "loadMore",
                    },
                    [
                      _vm.loadingMore
                        ? _c("a-spin")
                        : _c(
                            "div",
                            {
                              staticClass: "more-btn b3",
                              on: { click: _vm.loadMore },
                            },
                            [
                              _vm._v(
                                "\n                もっと読み込む\n            "
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c("i-empty")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }