var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("register-layout", {
    attrs: { step: 3 },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "register-step3" },
              [
                _c("h2", [_vm._v("会員登録申し込み完了")]),
                _vm._v(" "),
                _vm.status === "KYC_REFUSE"
                  ? [
                      _c("p", { staticClass: "b3" }, [
                        _vm._v(
                          "\n                    この度はお申込みをいただき誠に有難うございました。審査の結果について大変申し訳ございませんが、登録の承認をご遠慮させて頂く事となりました。\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "b3" }, [
                        _vm._v(
                          "\n                    審査内容の詳細につきまして開示しておらず、お問合せを頂きまして\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "b3" }, [
                        _vm._v(
                          "\n                    もお答えすることは出来ません。\n                "
                        ),
                      ]),
                    ]
                  : _vm.status === "KYC_WAIT_UPDATE"
                  ? [
                      _c("p", { staticClass: "b3" }, [
                        _vm._v(
                          "\n                    「お客様の本人情報が本人確認資料記載内容と一致しません。\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "b3" }, [
                        _vm._v("\n                    ご確認のうえ"),
                        _c(
                          "span",
                          {
                            staticClass: "bold c-03",
                            on: {
                              click: function ($event) {
                                return _vm.onOpenProfileModify()
                              },
                            },
                          },
                          [_vm._v("登録情報修正")]
                        ),
                        _vm._v(
                          "ボタンをクリックし再入力してください。」\n                "
                        ),
                      ]),
                    ]
                  : [
                      _c("h5", { staticClass: "bold" }, [
                        _vm._v("\n                    お申込み内容を確認後、"),
                        _c("span", { staticClass: "green-03" }, [
                          _vm._v("認証コ"),
                        ]),
                        _vm._v(
                          "ードをご登録住所宛て郵送いたしますので、しばらくお待ちください。\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "b2" }, [
                        _vm._v(
                          "\n                    🙏 引き続き、インスタキャッシュをよろしくお願いします。\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "b2" }, [
                        _c("span", { staticClass: "emoji-warn" }, [
                          _vm._v("⚠️ "),
                        ]),
                        _vm._v(
                          "審査の結果、ご登録をいたしかねる場合がございますので、ご了承ください。\n                "
                        ),
                      ]),
                    ],
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "b2 bold", on: { click: _vm.goHome } },
                  [_vm._v("\n                トップに戻る\n            ")]
                ),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }