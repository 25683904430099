var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "login-info-section" },
    [
      !_vm.isReRegister
        ? _c(
            "a-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "メールアドレス" },
            },
            [
              _c("div", { staticClass: "email-box" }, [
                _c("h5", [_vm._v(_vm._s(_vm.userInfo.email))]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "modif-entry",
                    on: { click: _vm.onOpenModifyEmail },
                  },
                  [
                    _c("img", {
                      staticClass: "modif-entry-icon",
                      attrs: { src: require("../image/edit.svg") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "b2_plus modif-entry-text" }, [
                      _vm._v("メールアドレス変更"),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a-form-item",
        { staticStyle: { width: "53%" }, attrs: { label: "パスワード" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["password", { rules: _vm.getRules("password") }],
                expression: "['password', {rules: getRules('password')}]",
              },
            ],
            staticStyle: { width: "92%" },
            attrs: { type: "password" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a-form-item",
        { staticStyle: { width: "47%" }, attrs: { label: "パスワード確認" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "passwordMust",
                  { rules: _vm.getRules("passwordMust") },
                ],
                expression:
                  "['passwordMust', {rules: getRules('passwordMust')}]",
              },
            ],
            attrs: { type: "password" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a-form-item",
        { staticStyle: { width: "53%" }, attrs: { label: "秘密の質問" } },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "pwdQuestion",
                    { rules: [{ required: true, message: " " }] },
                  ],
                  expression:
                    "['pwdQuestion', { rules: [{ required: true, message: ' ' }] }]",
                },
              ],
              staticClass: "custom-btn-radio-group unfixed-width-radio",
              staticStyle: { width: "92%" },
            },
            _vm._l(_vm.$options.pwdQuestionEnum, function (label, key) {
              return _c("a-radio-button", { key: key, attrs: { value: key } }, [
                _vm._v("\n                " + _vm._s(label) + "\n            "),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a-form-item",
        { staticStyle: { width: "47%" }, attrs: { label: "その答え" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["pwdAnswer", { rules: _vm.getRules("pwdAnswer") }],
                expression: "['pwdAnswer', {rules: getRules('pwdAnswer')}]",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }