var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "left" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v("\n            会員情報更改\n        "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      !_vm.isServer && _vm.form
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "a-form",
                {
                  staticClass: "form",
                  attrs: { layout: "vertical", form: _vm.form },
                },
                [
                  _c("login-item-info", { attrs: { form: _vm.form } }),
                  _vm._v(" "),
                  _c("base-item-info", { attrs: { options: _vm.baseOptions } }),
                  _vm._v(" "),
                  _c("bank-item-info", { attrs: { options: _vm.bankOptions } }),
                  _vm._v(" "),
                  _c("investment-item-info", {
                    attrs: { options: _vm.investmentOptions },
                  }),
                  _vm._v(" "),
                  _c("kyc-file-item-info", {
                    ref: "kycFileItemInfo",
                    attrs: { form: _vm.form, "form-data": _vm.formData },
                    on: { setFieldsValue: _vm.setFieldsValue },
                  }),
                  _vm._v(" "),
                  _c("a-form-item", [
                    _c(
                      "button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSubmit },
                      },
                      [
                        _vm._v(
                          "\n                        修正申込\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "image" }, [
        _c("img", { attrs: { src: require("./image/tip-bg.raw.png") } }),
        _vm._v(" "),
        _c("div", { staticClass: "user-name" }, [
          _c("h1", { staticClass: "white" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.userInfo.name) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("h5", { staticClass: "white" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.userInfo.email) +
                "\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "b3 mt24" }, [
        _vm._v(
          "\n                仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                ※ 自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }