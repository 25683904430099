<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-25 13:34:45 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 17:43:44 -->

<template>
    <div class="container about-us">
        <h1 class="bold">
            会社概要
        </h1>
        <div class="intro">
            <div class="des-group">
                <div
                    v-for="([label, value], idx) in infos"
                    :key="idx"
                    class="des-group-row b3"
                >
                    <div class="des-group-row-label bold">
                        {{ label }}
                    </div>
                    <div class="des-group-row-value">
                        {{ value }}
                    </div>
                </div>
            </div>
            <p class="b1 bold">
                治革
            </p>
            <p class="b2">
                平成２９年８月２２日日本店開設<br>
                令和〇年〇月〇日&nbsp;&nbsp;金融商品取引業登録関東財務局長(金商)第〇〇号
            </p>
            <p class="b2">
                一般社団法人第二種金融商品取引業協会加入
            </p>
            <p class="b2">
                特定非営利活動法人証券・金融商品あっせん相談センター<br>
                東京都中央区日本橋茅場町２－１－１第二証券会館 電話番号：0120-64-5005<br>
                （当社が加入する（社）第二種金融商品取引業協会を通じて契約する金融商品取引業務にかかる指定紛争解決機関）
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',

    data() {
        return {
            infos: [
                ['商号', 'インスタキャッシュ株式会社'],
                ['代表取締役', '湯浅健一（予定）'],
                ['取締役', '耿 博(非常動)'],
                ['所在地', '〒150-0002東京都渋谷区渋谷一丁目3番地9号 ヒュ-リック渋谷一丁目ビル 624号室'],
                ['電話番号', '03-6778-8580'],
                ['Eメ-ル', 'info＠○○.jp']
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.about-us {
    display: flex;
    padding: 1rem 0;

    h1 {
        color: $c-01;
    }

    .intro {
        margin-left: 1.84rem;
        width: 5.52rem;

        .b1 {
            margin-top: 0.8rem;
            color: $c-01;
        }

        .b2 {
            margin-top: 0.14rem;
            color: $c-03;
        }

        .first-p {
            margin: 0.48rem 0;
        }

        .des-group-row-label {
            white-space: nowrap;
        }
    }
}
</style>
