<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-24 17:54:53 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 13:28:54 -->

<template>
    <div class="register-layout">
        <div class="left">
            <register-step
                :step="step"
                class="layout-step"
            />
            <div class="step-ctn">
                <slot />
            </div>
        </div>
        <div class="right">
            <slot name="tip" />
        </div>
    </div>
</template>

<script>
import RegisterStep from './register-step';

export default {
    name: 'RegisterLayout',

    components: {
        RegisterStep
    },

    props: {
        step: {
            type: Number,
            default: 1
        }
    },
};
</script>

<style lang="scss" scoped>
.register-layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 0.43rem;
    width: 100%;
    box-sizing: border-box;

    .left {
        flex: 1;
        width: 6.3rem;
        margin-right: 0.43rem;

        .layout-step {
            position: sticky;
            top: 0.75rem;

            // left: 1rem;
            z-index: 3;
        }

        .step-ctn {
            padding-top: 1rem;
        }
    }

    .right {
        position: sticky;
        top: 0;
        flex: 1;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 0.6rem;
        padding-bottom: 0.71rem;
        background: url("../../login/img/group.jpg") no-repeat center / cover;
    }
}
</style>
