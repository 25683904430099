var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container notification-page" }, [
      _c("section", [
        _c("h1", { staticClass: "bold" }, [
          _vm._v("\n            通知一覧\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "mt28" }, [
        _c("div", { staticClass: "notice-card" }, [
          _c("div", [
            _c("div", { staticClass: "b3" }, [
              _vm._v("\n                    2014-02-24\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "status-row" }, [
              _c("div", { staticClass: "tag-item bold" }, [
                _vm._v("\n                        未读\n                    "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b2" }, [
                _vm._v(
                  "\n                        [重要] これはメッセージです。\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("img", { attrs: { src: require("./img/right.svg") } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }