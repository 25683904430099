/*
 * @Author: KylesLight
 * @Date: 2017-12-15 18:54:59
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-19 17:08:14
 */

import Vue from 'vue';

import {VUE_DEBUG, isClient, isServer} from 'ssr-common/constant/config';

Vue.config.devtools = VUE_DEBUG;
Vue.config.silent = !VUE_DEBUG;
Vue.config.errorHandler = (err, vm, info) => {
    if (isClient()) {
        if (typeof YqgReporter !== 'undefined') {
            // set logData.crashed when it's crashing error
            const logData = ['@render', 'render', 'renderError', 'data()'].includes(info) ? {
                crashed: info
            } : '';
            YqgReporter.fatalException(logData, err);
        }

        console.error(err); // eslint-disable-line
    }

    if (isServer()) {
        throw err;
    }
};
