<!--
 * @Author: zhaoyang
 * @Date: 2024-02-25 15:47:38
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-02-29 18:18:05
-->

<template>
    <div
        v-if="list && list.length"
        class="container hold"
    >
        <div class="title">
            保有フアンド
        </div>
        <a-list :data-source="list">
            <div
                v-if="showLoadingMore"
                slot="loadMore"
                class="loading-more-container"
            >
                <a-spin v-if="loadingMore" />
                <div
                    v-else
                    class="more-btn b3"
                    @click="loadMore"
                >
                    もっと読み込む
                </div>
            </div>
            <template #renderItem="item">
                <div class="box">
                    <div class="box-title">
                        {{ item.type | formatProductType }}
                    </div>
                    <div class="title1">
                        {{ item.name }}（{{ item.type | formatProductType }}）
                    </div>
                    <div class="title2">
                        目標利回り：{{ item.interestRate | toFullWidth }}％／年
                    </div>
                    <div class="title2">
                        運用期間：{{ item.closedDays | toFullWidth }}日
                    </div>
                    <div class="title2">
                        ファンド名：{{ item.name }}（{{ item.type | formatProductType }}）
                    </div>

                    <div class="pdf-wrap">
                        <div
                            v-for="item in staticPdfList"
                            :key="item"
                            class="pdf-item"
                            @click="handleRead(item)"
                        >
                            <img
                                class="pdf-icon"
                                src="./img/pdf.svg"
                                alt=""
                            >
                            <div class="pdf-title">
                                {{ pdfDisplayNameMap[item] }}.pdf
                            </div>
                        </div>
                        <template v-if="item.files && item.files.length">
                            <div
                                v-for="(name, idx) in item.files"
                                :key="idx"
                                class="pdf-item"
                                @click="onReadFile(name)"
                            >
                                <img
                                    class="pdf-icon"
                                    src="./img/pdf.svg"
                                    alt=""
                                >
                                <div class="pdf-title">
                                    {{ name | sliceReportName }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <template v-if="item.publishStatus">
                        <div class="info">
                            <div class="info-item">
                                <div class="info-title">
                                    {{ item.evaluateAmount | currencyFormat }}万ルピア(※1)
                                </div>
                                <div class="info-desc">
                                    贷付残高({{ item.evaluateTime | date }})
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="info-title">
                                    {{ item.indoTotalAmount | currencyFormat }}万ルピア(※2)
                                </div>
                                <div class="info-desc">
                                    事業開始時({{ item.indoTime | date }})
                                </div>
                            </div>
                        </div>

                        <div class="sub-info">
                            (※{{ item.evaluateTime | date }}時点の金額であリ将来変動します)
                        </div>

                        <div class="sub-info">
                            (※2出資金{{ item.totalAmount | currencyFormat }}万円(@{{ item.currentRate | currencyFormat }}))
                        </div>
                    </template>
                </div>
            </template>
        </a-list>
    </div>
    <i-empty v-else />
</template>

<script>
import {dateMonth} from 'ssr-common/util/date';

import {pdfDisplayNameMap} from 'instacash/common/constant';
import Common from 'instacash/common/resource/common';
import Order from 'instacash/common/resource/order';

export default {
    name: 'ProductHold',

    filters: {
        date: dateMonth,
    },

    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            total: 0,
            list: null,
            loadingMore: false,
            staticPdfList: [
                'anonymous',
                'contract-before'
            ],
            pdfDisplayNameMap
        };
    },

    computed: {
        totalPage() {
            const {pageSize, total} = this;

            return Math.ceil(total / pageSize);
        },

        showLoadingMore() {
            return this.pageNo < this.totalPage;
        }
    },

    mounted() {
        this.fetchHoldingList();
    },

    methods: {
        async loadMore() {
            this.loadingMore = true;

            if (this.pageNo < this.totalPage) {
                try {
                    await this.fetchHoldingList(this.pageNo + 1);
                } catch (e) {
                    //
                }

                this.loadingMore = false;
            }
        },

        async fetchHoldingList(pageNo = this.pageNo) {
            const params = {pageNo, pageSize: this.pageSize};
            const {data: {body}} = await Order.holding({params});
            this.pageNo = pageNo;

            this.list = (this.list || []).concat(body?.records || []);
            this.total = body?.total || 0;
        },

        async onReadFile(fileKey) {
            const {data: {body}} = await Common.file({params: {fileKey}});
            window.open(body, '_blank');
        },

        handleRead(link) {
            window.open(`/static/pdf/${link}.pdf`);
        }
    }
};
</script>

<style lang="scss" scoped>
.hold {
    .title {
        color: #32302f;
        padding-top: 0.4rem;
        font-family: Noto serif JP;
        font-size: 0.4rem;
        font-weight: 700;
        line-height: 0.52rem;
    }

    .box {
        margin-top: 0.4rem;
        box-sizing: border-box;
        border-radius: 0.16rem;
        padding: 0.4rem 0.6rem;
        position: relative;
        background-image: url("./img/bg-s.png");
        background-size: 100% 100%;

        .box-title {
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #f0f0f0;
        }

        .title1 {
            margin-top: 0.16rem;
            margin-bottom: 0.16rem;
            font-family: Noto serif JP;
            font-size: 0.32rem;
            font-weight: 700;
            line-height: 0.42rem;
            color: #fff;
        }

        .title2 {
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #fff;
        }

        .info {
            margin-bottom: 0.08rem;
            margin-top: 0.36rem;
            width: 5.8rem;
            padding: 0.28rem 0.4rem;
            border-radius: 0.16rem;
            background-color: #fff;
            border: 1px solid #cacaca;
            display: flex;
            justify-content: space-between;
            gap: 0.8rem;

            .info-item {
                display: flex;
                flex-direction: column;
                flex: 1;

                .info-title {
                    text-align: center;
                    font-family: Noto Sans JP;
                    font-size: 0.2rem;
                    font-weight: 700;
                    line-height: 0.26rem;
                    color: #32302f;
                }

                .info-desc {
                    margin-top: 0.12rem;
                    text-align: center;
                    color: #828180;
                    font-family: Noto Sans JP;
                    font-size: 0.12rem;
                    font-weight: 400;
                    line-height: 0.19rem;
                }
            }
        }

        .pdf-wrap {
            display: flex;
            align-items: center;
            height: 0.38rem;
            padding: 0.08rem 0;
            margin-top: 0.16rem;

            .pdf-item {
                display: flex;
                align-items: center;
                margin-left: 0.4rem;
                cursor: pointer;

                &:first-child {
                    margin-left: 0;
                }

                .pdf-icon {
                    width: 0.16rem;
                    height: 0.16rem;
                }

                .pdf-title {
                    margin-left: 0.04rem;
                    font-family: Noto Sans JP;
                    font-size: 0.14rem;
                    font-weight: 400;
                    line-height: 0.22rem;
                    color: #f0f0f0;
                }
            }
        }

        .sub-info {
            font-family: Noto Sans JP;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.19rem;
            color: #cacaca;

            &:last-child {
                margin-top: 0.08rem;
            }
        }

        .arrow {
            margin-top: 0.36rem;
            width: 0.48rem;
            height: 0.48rem;
        }
    }

    .loading-more-container {
        display: flex;
        justify-content: center;

        .more-btn {
            cursor: pointer;
            padding: 0.2rem;
        }
    }
}
</style>
