var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-page" },
    [
      _c("section", { staticClass: "table-row" }, [
        _c("div", { staticClass: "flex-between" }, [
          _c("h1", { staticClass: "bold" }, [
            _vm._v("\n                会員情報\n            "),
          ]),
          _vm._v(" "),
          !_vm.showSimpleInfo
            ? _c(
                "div",
                {
                  staticClass: "modif-entry",
                  on: {
                    click: function ($event) {
                      return _vm.onOpenModifyPage("ProfileModify")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "modif-entry-icon",
                    attrs: { src: require("./image/edit.svg") },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "b2_plus modif-entry-text" }, [
                    _vm._v("更改会員情報"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "b3 c-02 mt28 mb28" }, [
          _vm._v(
            "\n            住所の変更をご希望される場合、お問い合わせよりご連絡ください。\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.showSimpleInfo
        ? [
            _c("section", { staticClass: "table-row" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "info-card des-group base-info-left mr28" },
                    _vm._l(_vm.userBaseInfo, function (item, idx) {
                      return _c("item-info", {
                        key: idx,
                        attrs: { item: item, "val-obj": _vm.userInfo },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "email.value",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.userInfo.email)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "modif-entry",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOpenModifyPage(
                                            "EmailModify"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "modif-entry-icon",
                                        attrs: {
                                          src: require("./image/edit.svg"),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "b2_plus modif-entry-text",
                                        },
                                        [_vm._v("メールアドレス変更")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "pwdAnswer.label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        "秘密質問：" +
                                          (_vm.pwdQuestionEnum[
                                            _vm.userInfo.pwdQuestion
                                          ] || "")
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "address.value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.userInfo.area) +
                                      " " +
                                      _vm._s(_vm.userInfo.street) +
                                      " " +
                                      _vm._s(_vm.userInfo.address) +
                                      "\n                            "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "info-card des-group base-info-right" },
                    _vm._l(_vm.investmentInfo, function (item, idx) {
                      return _c("item-info", {
                        key: idx,
                        attrs: { item: item, "val-obj": _vm.userInfo },
                      })
                    }),
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "section2 mt100" }, [
              _c("h1", { staticClass: "bold" }, [
                _vm._v("\n                入金先口座情報\n            "),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "info-card mt28" }, [
                _c(
                  "div",
                  { staticClass: "des-group" },
                  _vm._l(_vm.depositAccountInfo, function (item, idx) {
                    return _c("item-info", {
                      key: idx,
                      attrs: { item: item, "val-obj": _vm.userInfo },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "divider-line mt14" }),
                _vm._v(" "),
                _c("h5", { staticClass: "c-02 mt28" }, [
                  _vm._v(
                    "\n                    ご入金時の注意事項\n                "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "mt100" }, [
              _c("h1", { staticClass: "bold" }, [
                _vm._v("\n                出金先口座情報\n            "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-card des-group mt28" },
                _vm._l(_vm.withDrawalAccountInfo, function (item, idx) {
                  return _c("item-info", {
                    key: idx,
                    attrs: { item: item, "val-obj": _vm.userInfo },
                  })
                }),
                1
              ),
            ]),
            _vm._v(" "),
            _vm.userInfo.reports && _vm.userInfo.reports.length
              ? _c("section", { staticClass: "section2 mt100" }, [
                  _c("h1", { staticClass: "bold" }, [
                    _vm._v("\n                顧客交付書面\n            "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info-card report-card mt28" },
                    _vm._l(_vm.userInfo.reports, function (name, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          class: {
                            "report-item": true,
                            b3: true,
                            "report-item-non-border":
                              idx === _vm.userInfo.reports.length - 1,
                          },
                        },
                        [
                          _c("div", { staticClass: "report-item-name" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._f("sliceReportName")(name)) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "report-item-btns" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn mr40",
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenReport(name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            開く\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDownloadReport(name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            ダウンロード\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]
        : _c(
            "div",
            { staticClass: "info-card des-group mt28" },
            _vm._l(_vm.userBaseInfo.slice(0, 1), function (item, idx) {
              return _c("item-info", {
                key: idx,
                attrs: { item: item, "val-obj": _vm.userInfo },
              })
            }),
            1
          ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "b3 mt28" }, [
        _vm._v(
          "\n                    投資申込後は、下記の口座へご本人名義でご入金をお願いいたします。\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                    ご入金後、当社での着金確認およびマイページへの反映にお時間をいただくことがあります。また、振込手数料はお客さま負担となりますので、ご注意ください。\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                    会員登録されているご本人の名義（下記の振込依頼人名）でのお振込みをお願いいたします。\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-03 mt28" }, [
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                        ①会員登録されているご本人の名義と異なる名義からお振込みされた場合、お客さまからのご入金として取り扱うことができず、組戻しの手続きが必要となります。組戻し手数料等はお客さまのご負担となりますのでご了承ください。\n                    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                        ②会員登録されているご本人の名義と同じ名義であれば、送金用口座としてご登録の銀行口座以外からでもご入金いただけます。\n                    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                        ③口座名義は正しく入力してください。受取人の口座名義【インスタキャッシュ（カ】が異なる場合、振込先金融機関にて正しい入金処理が行われないことがございます。\n                    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                        ④当社サービスは出資金のデポジット（事前入金）には対応しておりません。出資金は必ず、ファンドへの投資申込みが完了してから、申込額を過不足なくご入金ください。\n                    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "b3" }, [
        _vm._v(
          "\n                        ⑤お客さまの投資家用口座へのご入金は、通常、ご入金の翌営業日を目処に、投資家用口座の入金額および投資申込ファンドへ充当されマイページ上に反映されますが、16時以降や土日・祝日、年末年始等のお振込みはご入金および投資申込ファンドへの充当の反映にお時間をいただくことがございます。あらかじめご了承ください。\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }