/*
 * @Author: ruiwang
 * @Date: 2024-02-25 12:37:13
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-07-09 21:38:23
 */
import { bankTypeEnum, professionEnum, investmentRiskEnum, investmentPlanEnum, investmentYearsEnum, investmentAccountEnum, investmentExperienceEnum, investmentPurposeEnum } from 'src/common/constant/enum';
// 用户基本信息
export const userBaseInfo = [
    {
        label: 'メールアドレス（ログインID）',
        field: 'email',
        valueClass: 'email-item'
    },
    {
        label: '名前',
        field: 'name',
    },
    {
        label: 'フリガナ',
        field: 'alias',
    },
    {
        label: '生年月日',
        field: 'birthday',
        type: 'date'
    },
    {
        label: 'マイナンバー',
        field: 'idNo'
    },
    {
        label: '住所',
        field: 'address',
    },
    {
        field: 'pwdAnswer',
    },
    {
        label: '電話',
        field: 'mobileNumber',
    }
];
// 投资信息
export const investmentInfo = [
    {
        label: '投資経験',
        field: 'investmentExperience',
        enumType: investmentExperienceEnum,
    },
    {
        label: '投資経験（年数）',
        field: 'investmentYears',
        enumType: investmentYearsEnum,
    },
    {
        label: '金融資産（万円）',
        field: 'financialAssets',
    },
    {
        label: '職業等',
        field: 'profession',
        enumType: professionEnum,
    },
    {
        label: '年収（万円）',
        field: 'annualIncome',
    },
    {
        label: '投資目的（複数可）',
        field: 'investmentPurpose',
        enumType: investmentPurposeEnum,
    },
    {
        label: '投資方針※（複数可）',
        field: 'investmentPlan',
        enumType: investmentPlanEnum,
    },
    {
        label: '証券口座の有無',
        field: 'investmentAccount',
        enumType: investmentAccountEnum
    },
    {
        label: '元本毀損で生活に支障',
        field: 'investmentRisk',
        enumType: investmentRiskEnum
    },
    {
        label: '外国PEPs',
        field: 'peps',
        enumType: investmentRiskEnum
    }
];
// 存款账户信息
export const depositAccountInfo = [
    {
        label: '金融機闃名',
        field: 'payBankName',
    },
    {
        label: '支店名 ',
        field: 'payBranchBank',
    },
    {
        label: '口座種別',
        field: 'payBankType',
        enumType: bankTypeEnum
    },
    {
        label: '口座番号',
        field: 'payBankNumber',
    },
    {
        label: '口座名義人',
        field: 'payBankUser',
    }
];
// 提款账户信息
export const withDrawalAccountInfo = [
    {
        label: '金融機闃名',
        field: 'bankName',
    },
    {
        label: '支店名 ',
        field: 'branchBank',
    },
    {
        label: '口座種別',
        field: 'bankType',
        enumType: bankTypeEnum
    },
    {
        label: '口座番号',
        field: 'bankNumber',
    },
    {
        label: '口座名義人',
        field: 'bankUser',
    }
];
export const genOptions = (options) => {
    const opts = options.map(item => {
        const [label, value, filter] = item;
        return {
            label,
            value,
            filter
        };
    });
    return opts;
};
