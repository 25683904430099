var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "b2 mb28" }, [
      _vm._v("\n        下記項目全てにご回答お願いいたします\n    "),
    ]),
    _vm._v(" "),
    _c(
      "section",
      [
        _vm._l(_vm.options, function (option) {
          return [
            _c(
              "a-form-item",
              { key: option.field, attrs: { label: option.label } },
              [
                option.type === "radio"
                  ? _c(
                      "a-radio-group",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.genDecorator(option),
                            expression: "genDecorator(option)",
                          },
                        ],
                        staticClass: "custom-btn-radio-group",
                      },
                      _vm._l(option.options, function (item) {
                        return _c(
                          "a-radio-button",
                          { key: item.value, attrs: { value: item.value } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.label) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                option.type === "checkbox"
                  ? _c("a-checkbox-group", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.genDecorator(option),
                          expression: "genDecorator(option)",
                        },
                      ],
                      staticClass: "custom-btn-checkbox-group",
                      attrs: { options: option.options },
                    })
                  : _vm._e(),
                _vm._v(" "),
                option.type === "date"
                  ? _c("a-date-picker", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.genDecorator(option),
                          expression: "genDecorator(option)",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: option.placeholder },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !option.type || option.type === "input"
                  ? _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.genDecorator(option),
                          expression: "genDecorator(option)",
                        },
                      ],
                      attrs: {
                        placeholder: option.placeholder,
                        suffix: option.suffix,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                option.field === "financialAssets"
                  ? _c("div", { staticClass: "b4 c-04 mt4" }, [
                      _vm._v(
                        "\n                    当社ファンドの購入上限はお客様の金融資産の５０％までです。\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                option.field === "peps"
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticStyle: { "text-decoration-line": "underline" },
                          attrs: {
                            href: _vm.$options.pepsPdfUrl,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        外国PEPs>>\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }