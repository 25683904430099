var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-layout" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("register-step", {
          staticClass: "layout-step",
          attrs: { step: _vm.step },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "step-ctn" }, [_vm._t("default")], 2),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [_vm._t("tip")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }