<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-25 18:47:57 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 17:38:33 -->

<template>
    <div
        ref="header"
        :class="{'nav-bar': true, 'nav-bar-transparent': hideNav}"
    >
        <router-link :to="{name: 'Index'}">
            <h2 class="bold">
                Instacash
            </h2>
        </router-link>

        <div
            v-show="!hideNav"
            class="routes"
        >
            <router-link :to="{name: 'Index'}">
                Home
            </router-link>
            <div class="dropdown">
                <a class="roate-icon">
                    ファンド一覧
                    <arrow-down />
                </a>
                <div class="dropdown-content">
                    <router-link
                        class="b3"
                        :to="{name: 'ProductList'}"
                    >
                        ファンド一覧
                        <vector class="vector-svg" />
                    </router-link>
                    <template v-if="profileEntryText">
                        <router-link
                            class="b3"
                            :to="{name: 'ProductHold'}"
                        >
                            保有ファンド
                            <vector class="vector-svg" />
                        </router-link>
                        <router-link
                            class="b3"
                            :to="{name: 'TradeRecord'}"
                        >
                            申込履歴
                            <vector class="vector-svg" />
                        </router-link>
                    </template>
                </div>
            </div>
            <router-link :to="{name: 'AboutUs'}">
                会社概要
            </router-link>
        </div>

        <div class="user">
            <template v-if="profileEntryText">
                <router-link
                    class="b3 user-email"
                    :to="{name: 'Profile'}"
                >
                    {{ profileEntryText }}
                </router-link>
                <button
                    class="b3 bold"
                    @click="onLogout"
                >
                    サインアウト
                </button>
            </template>
            <template v-else>
                <button
                    v-show="!$route.name.startsWith('Register')"
                    class="b3 bold mr10"
                    @click="onRegister"
                >
                    新規登録
                </button>
                <button
                    v-show="$route.name !== 'Login'"
                    class="b3 bold"
                    @click="onLogin"
                >
                    ログイン
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import {Dropdown, Menu} from 'ant-design-vue';
import Vue from 'vue';
import {mapState, mapActions, mapGetters} from 'vuex';

import ArrowDown from './arrow-down.vc.svg';
import Vector from './vector.vc.svg';

[Dropdown, Menu].forEach(component => {
    Vue.use(component);
});

const SCROLL_NAV_CLASS_NAME = 'nav-bar-scroll';
const SCROLL_DISTANCE = 20;

export default {
    name: 'NavBar',

    components: {
        ArrowDown,
        Vector
    },

    computed: {
        ...mapState(['finance']),

        ...mapGetters('finance', ['kyc', 'userInfo']),

        profileEntryText() {
            const {name, email} = this.userInfo;

            return name || email;
        },

        hideNav() {
            return this.$route.meta.hideNav;
        }

    },

    watch: {
        hideNav: {
            handler (val) {
                if (val) {
                    this.addScrollListener();
                } else {
                    this.removeScrollListener();
                }
            },
        },
    },

    mounted() {
        if (this.hideNav) {
            this.addScrollListener();
        }
    },

    methods: {
        ...mapActions('finance', {
            logout: 'logout'
        }),

        async onRegister() {
            const {currentStepRouteName} = this.finance;

            this.$router.push({name: currentStepRouteName || 'RegisterStep1-1'});
        },

        async onLogout() {
            await this.logout();
            if (this.$route.name !== 'Index') {
                this.$router.push({name: 'Index'});
            }
        },

        async onLogin() {
            if (this.$route.name !== 'Login') {
                this.$router.push({name: 'Login'});
            }
        },

        addScrollListener() {
            if (window) {
                if (window.pageYOffset > SCROLL_DISTANCE) {
                    this.$refs.header.classList.add(SCROLL_NAV_CLASS_NAME);
                }

                window.addEventListener('scroll', this.scrollAcrossClass);
            }
        },

        scrollAcrossClass() {
            const el = this.$refs.header;
            if (window.pageYOffset > SCROLL_DISTANCE) {
                el.classList.add(SCROLL_NAV_CLASS_NAME);

                return;
            }

             el.classList.remove(SCROLL_NAV_CLASS_NAME);
        },

        removeScrollListener() {
            window.removeEventListener('scroll', this.scrollAcrossClass);
        }
    }
};
</script>

<style lang="scss" scoped>
.mr10 {
    margin-right: 0.1rem;
}

.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    padding: 0 0.2rem;
    height: 0.8rem;
    background-color: $c-08;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    &-transparent {
        background-color: transparent;

        // transition: all  0.2s;
    }

    &-scroll {
        background-color: $c-08;
    }

    h2 {
        color: $grey-03;
        cursor: pointer;
    }

    .routes {
        width: 3.2rem;
        display: flex;
        justify-content: space-between;
        height: 100%;
        display: flex;
        align-items: center;

        a {
            color: $c-01;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            gap: 0.08rem;
            justify-content: center;

            &:hover,
            &.router-link-exact-active {
                color: $yellow-02;
            }

            &.router-link-exact-active {
                font-weight: 700;
            }
        }

        a.roate-icon {
            svg {
                transition: all 0.3s;
            }

            &:hover,
            &.router-link-exact-active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .user {
        display: flex;
        align-items: center;

        &-email {
            margin-right: 0.1rem;
            padding: 0.08rem 0;
            color: $c-02;
            cursor: pointer;
            text-decoration: underline;
        }

        button {
            padding: 0.08rem 0.2rem;
            background-color: $c-01;
            border-radius: 0.04rem;
            width: auto;
            height: auto;
        }
    }

    .dropdown {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .dropdown-content {
        display: none;
    }

    .dropdown-content a {
        padding: 0.2rem 0;
    }

    .dropdown:hover .dropdown-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0.8rem;
        border: 1px solid #d7d7da;
        border-left: 0;
        border-right: 0;
        display: flex;
        justify-content: center;
        gap: 0.52rem;
        background-color: $c-08;
        z-index: 1;
    }
}
</style>
