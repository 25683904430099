<!--
 * @Author: zhaoyang
 * @Date: 2024-02-25 16:31:48
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-02-27 17:15:31
-->

<template>
    <div class="agreement">
        <div class="title-fix">
            <div class="title">
                {{ info.text }}
            </div>
            <div class="desc">
                ※ ご確認いただき「承諾する」にチェックしてください。最後までスクロールしないとチェックできません。
            </div>
        </div>
        <div
            class="agreement-wrap"
            @scroll="onScroll"
        >
            <yqg-md-preview
                :md-text="info.desc"
            />
        </div>
        <div
            class="agreement-item"
            @click="onCheck"
        >
            <img
                class="check-icon"
                :src="info.isCheck ? Select : NoSelect"
                alt=""
            >
            <span class="check-text">上記の書面を確認し、承諾します。</span>
            <span class="check-import">*</span>
        </div>
    </div>
</template>

<script>
import NoSelect from '../img/no-select.svg';
import Select from '../img/select.svg';

export default {
    name: 'Agreement',

    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            canSelect: false,
            NoSelect,
            Select
        };
    },

    methods: {
        onCheck() {
            if (this.canSelect) {
                this.$emit('check');
            }
        },

        onScroll(evt) {
            const element = evt.target;

            const isBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 3;

            if (isBottom) {
                this.canSelect = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.agreement {
    overflow: hidden;
    border-radius: 0.12rem;
    border: 1px solid #cacaca;

    .title-fix {
        box-sizing: border-box;
        padding: 0.16rem 0.2rem 0.12rem;

        .title {
            font-family: Noto Sans JP;
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 0.26rem;
            color: #4c4948;
        }

        .desc {
            margin-top: 0.04rem;
            font-family: Noto Sans JP;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.19rem;
            color: #f66c70;
            letter-spacing: -0.2px;
        }
    }

    .agreement-wrap {
        height: 1.33rem;
        box-sizing: border-box;
        padding: 0 0.2rem 0.16rem;
        overflow-y: auto;
    }

    .agreement-item {
        box-sizing: border-box;
        padding: 0 0.2rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #cacaca;
        height: 0.43rem;
        background-color: #f0f0f0;
        cursor: pointer;

        .check-icon {
            width: 0.18rem;
            height: 0.18rem;
        }

        .check-text {
            margin-left: 0.08rem;
            font-family: Noto Sans JP;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.19rem;
            color: #4c4948;
        }

        .check-import {
            margin-top: 0.1rem;
            margin-left: 0.05rem;
            font-size: 0.25rem;
            color: #f66c70;
        }
    }
}
</style>
