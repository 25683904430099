/**
 * @Author: panezhang
 * @Date: 2018/8/19-19:06
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-05-We 02:34:41
 */

/* eslint-disable consistent-return */

import {DEBUG} from 'ssr-common/constant/config';

import {getWebviewBridge} from './bridge';
import {minVersion} from './ua';
import ua from './ua-parsed';

// 各业务配置 AVAILABLE 时，判断自己的业务，不要影响其他项目的使用，示例：!ua.isYqg || (ua.isYqg && ***)
export const HANDLER_AVAILABLE = {
    setDocumentTitle: ua.isIOS
};

export {ua};

function resolveUrl(url) {
    return url.startsWith('/') ? `${location.origin}${url}` : url;
}

// UAHelper 用于统一管理内部 ua
const UAHelper = {
    isSupportJSBridge: ua.isIOS || ua.isAndroid
};

const {isIOS, isAndroid, isYqd, isZebra} = ua;

export const isSupportRequestPermission = (isYqd && minVersion('21108'))
    || (isZebra && isAndroid && minVersion(21100))
    || (isZebra && isIOS && minVersion(21101));

export class YqgWebviewInterface {

    initBridge() {
        const self = this;
        if (ua.isWebview) {
            if (UAHelper.isSupportJSBridge) {
                // 初始化 Javascript Bridge
                if ((self.bridge === window.WebViewJavascriptBridge)) {
                    self.bridge.init();
                } else {
                    document.addEventListener('WebViewJavascriptBridgeReady', event => {
                        self.bridge = event.bridge;
                        self.bridge.init();
                    }, false);
                }
            } else {
                self.debug('当前尚未支持该操作系统');
            }
        }
    }

    initReceiver() {
        const self = this;
        self.ReceivedEventMap = {};

        window.YqgWebMessager = {
            on(type, callback) {
                if (!self.ReceivedEventMap[type]) {
                    self.ReceivedEventMap[type] = [];
                }

                if (self.ReceivedEventMap[type].includes(callback)) return;
                self.ReceivedEventMap[type].push(callback);
            },

            off(type, callback) {
                if (!self.ReceivedEventMap[type]) return;
                if (!self.ReceivedEventMap[type].includes(callback)) return;
                const index = self.ReceivedEventMap[type].indexOf(callback);
                self.ReceivedEventMap[type].splice(index, 1);
            },

            send(type, payload) {
                if (!self.ReceivedEventMap[type]) return;
                self.ReceivedEventMap[type].forEach(callback => callback(payload));
            }
        };
    }

    callHandler(name, data, callback) {
        if (!ua.isWebview) {
            this.debug(`[${name}] is called, but not in webview.`);

            return;
        }

        const callbackWrapper = _data => (callback && callback(_data));
        if (HANDLER_AVAILABLE[name] === false) {
            callbackWrapper();

            return;
        }

        getWebviewBridge().then(bridge => bridge.callHandler(name, data, callbackWrapper)).catch(err => err);
        // .catch((err) => {
        // alert(err ? (err.message || JSON.stringify(err)) : 'APP 版本过低')
        // });
    }

    asyncCallHandler(name, data) {
        return new Promise((resolve, reject) => {
            if (!ua.isWebview) {
                this.debug(`[${name}] is called, but not in webview.`);

                return reject();
            }

            const callbackWrapper = _data => (resolve(_data));
            if (HANDLER_AVAILABLE[name] === false) {
                return reject();
            }

            getWebviewBridge()
                .then(bridge => bridge.callHandler(name, data, callbackWrapper))
                .catch(() => reject());
        });
    }

    handleAction({type, payload} = {}) {
        if (type && this[type]) {
            this[type](payload);
        } else {
            this.debug(`Action [${type}] is not found for payload [${payload}]`);
        }
    }

    debug(text) {
        if (DEBUG) alert(text);
    }

    copyToClipboard(str) {
        this.callHandler('copyToClipboard', str);
    }

    setDocumentTitle(title) {
        this.callHandler('setDocumentTitle', title);
    }

    callCustomerServiceTel() {
        this.callHandler('callCustomerServiceTel');
    }

    openOnlineCustomer() {
        this.callHandler('openOnlineCustomer');
    }

    openBrowser(url) {
        if (ua.isWebview) {
            this.callHandler('openBrowser', resolveUrl(url));
        } else {
            window.open(url);
        }
    }

    openWebview(url, callback) {
        if (ua.isWebview) {
            this.callHandler('openWebview', resolveUrl(url), callback);
        } else {
            window.location.href = url;
            if (callback) callback();
        }
    }

    requestPermission(data, callback) {
        this.callHandler('requestPermission', JSON.stringify(data), callback);
    }

    openWebViewNoLoading(url, callback) {
        if (ua.isWebview) {
            this.callHandler('openWebViewNoLoading', resolveUrl(url), callback);
        } else {
            window.open(url);
        }
    }

    confirmAgreement(data) {
        this.callHandler('onConfirmAgreement', data);
    }

    downloadPDF(key) {
        this.callHandler('downloadPDF', key);
    }

    closeWebPage() {
        this.callHandler('closeWebPage');
    }

    openHomePage() {
        this.callHandler('openHomePage');
    }

    goBack() {
        if (ua.isWebview) {
            this.callHandler('goBack');
        } else {
            window.history.back();
        }
    }

    getStatusBarHeight() {
        if (ua.isWebview) {
            return this.asyncCallHandler('getStatusBarHeight');
        }

        return Promise.resolve(0);
    }

    showSignPad(params) {
        this.callHandler('showSignPad', params);
    }

    getHeaderInfo() {
        if (ua.isWebview) {
            return this.asyncCallHandler('getHeaderInfo');
        }

        return Promise.resolve(0);
    }

    authSuccess() {
        this.callHandler('authSuccess');
    }

    showLoginFlow() {
        this.callHandler('showLoginFlow');
    }

    setBackCallbackEnable() {
        this.callHandler('setBackCallbackEnable', true);
        this.initReceiver();
    }

}

export default new YqgWebviewInterface();
