/*
 * @Author: huafengli
 * @Date: 2024-05-07 18:29:24
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-08 11:31:02
 */
import Resource from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    product: {
        url: '/api/product',
        method: 'get'
    },
    productDetail: {
        url: '/api/product/:id',
        method: 'get'
    },
    order: {
        url: '/api/order',
        method: 'post'
    },
};
export default Resource.create(api);
