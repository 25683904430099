/*
 * @Author: xiaodongyu
 * @Date 2019-05-24 18:07:56
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2019-05-24 18:08:28
 */

export default {
    apiWeb: {
        report: '/api-web/report'
    }
};
