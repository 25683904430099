import { mapGetters } from 'vuex';
export default {
    computed: Object.assign(Object.assign({}, mapGetters('finance', ['status'])), { isDisabled() {
            // 非内部审核拒绝状态下，部分字段禁止修改（姓名、地址、出生年月、银行信息、用户个人ID号码、个人上传过的文件）
            // 仅正式会员能过打开用户信息修改页面
            return this.status !== 'KYC_WAIT_UPDATE';
        },
        isReRegister() {
            return this.status === 'KYC_WAIT_UPDATE';
        } }),
    methods: {
        genDecorator(option) {
            const { field, rules: originRules = [], required } = option;
            const requiredMsg = ' ';
            const requiredRule = { required, message: requiredMsg };
            const rules = [].concat(requiredRule, originRules);
            return [field, { rules }];
        },
    }
};
