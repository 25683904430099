/*
 * @Author: huafengli
 * @Date: 2024-05-06 11:26:24
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-19 17:07:11
 */
import { investmentAccountEnum, bankTypeEnum, currencyTypeEnum } from 'src/common/constant/enum';
export const productFields = [
    {
        field: 'name',
        label: 'ファンド名'
    },
    {
        field: 'operatorUpdated',
        label: '営業者'
    },
    {
        field: 'maxAmount',
        label: '募集総額',
        suffix: '万円（上限）',
        filter: 'currencyFormat'
    },
    {
        field: 'minAmount',
        label: '募集最低額',
        suffix: '万円',
        filter: 'currencyFormat'
    },
    {
        field: 'userMinAmount',
        label: '最低申込額',
        suffix: '万円、追加単位1万円',
        filter: 'currencyFormat'
    },
    {
        field: 'applyStartTime',
        label: '募集期間'
    },
    {
        field: 'closedDays',
        label: '運用期間',
        suffix: '日'
    },
    {
        field: 'earlyTermination',
        label: '中途解約',
        enumType: investmentAccountEnum,
    },
    {
        field: 'currency',
        label: '運用通貨',
        enumType: currencyTypeEnum,
    },
    {
        field: 'payCurrencyUnit',
        label: '出資金払込',
        enumType: currencyTypeEnum
    },
    {
        field: 'incomeCurrencyUnit',
        label: '出資金返還及び分配金交付',
        enumType: currencyTypeEnum
    },
    {
        field: 'interestRate',
        label: '目標利回り'
    }
];
export const applyFields = [
    {
        field: 'raiseAmount',
        label: '申込状況'
    },
    {
        field: 'residueAmount',
        label: '残り募集金額',
        suffix: '万円',
        filter: 'currencyFormat'
    },
    {
        field: 'residueDays',
        label: '残り募集日数',
        suffix: '日'
    }
];
export const bankFields = [
    {
        field: 'payBankName',
        label: '金融機闃名'
    },
    {
        field: 'payBranchBank',
        label: '支店名'
    },
    {
        field: 'payBankType',
        label: '口座種別',
        enumType: bankTypeEnum
    },
    {
        field: 'payBankNumber',
        label: '口座番号'
    },
    {
        field: 'payBankUser',
        label: '振込先名義人'
    }
];
