import { render, staticRenderFns } from "./register-step.vue?vue&type=template&id=97c6e558&scoped=true&"
import script from "./register-step.vue?vue&type=script&lang=js&"
export * from "./register-step.vue?vue&type=script&lang=js&"
import style0 from "./register-step.vue?vue&type=style&index=0&id=97c6e558&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c6e558",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/apps/jenkins/workspace/新海外前端/fintopia-web-build-deploy@2/prod-sea-ssr/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97c6e558')) {
      api.createRecord('97c6e558', component.options)
    } else {
      api.reload('97c6e558', component.options)
    }
    module.hot.accept("./register-step.vue?vue&type=template&id=97c6e558&scoped=true&", function () {
      api.rerender('97c6e558', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/finance/register/component/register-step.vue"
export default component.exports