<!-- @Author: ruiwang -->
<!-- @Date: 2024-02-24 11:17:06 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-28 18:29:39 -->

<template>
    <register-layout :step="2">
        <div class="rs2">
            <h2 class="bold">
                会員登録
            </h2>
            <section>
                <p class="b3 mt24">
                    仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。
                </p>
                <p class="b3">
                    ※ 自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。
                </p>
            </section>
            <section
                v-if="!isServer && form"
                class="mt60 fm-1"
            >
                <a-form
                    layout="vertical"
                    :form="form"
                    @submit.prevent="handleSubmit"
                >
                    <section class="controler-box">
                        <template
                            v-for="option in options1"
                        >
                            <a-form-item
                                :key="option.field"
                                :label="option.label"
                            >
                                <a-radio-group
                                    v-if="option.type === 'radio'"
                                    v-decorator="genDecorator(option)"
                                    class="custom-btn-radio-group"
                                >
                                    <a-radio-button
                                        v-for="item in option.options"
                                        :key="item.value"
                                        :value="item.value"
                                    >
                                        {{ item.label }}
                                    </a-radio-button>
                                </a-radio-group>

                                <a-date-picker
                                    v-if="option.type === 'date'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                    style="width: 100%;"
                                />
                                <a-input
                                    v-if="!option.type || option.type === 'input'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                />
                                <div
                                    v-if="option.field === 'mobileNumber'"
                                    class="b4 c-04 mt4"
                                >
                                    {{ option.extra }}
                                </div>
                            </a-form-item>
                        </template>
                    </section>
                    <div class="b2 mt60 mb28">
                        本人名義の銀行口座情報を入力してください。
                    </div>
                    <section class="controler-box">
                        <template
                            v-for="option in options2"
                        >
                            <a-form-item
                                :key="option.field"
                                :label="option.label"
                            >
                                <a-radio-group
                                    v-if="option.type === 'radio'"
                                    v-decorator="genDecorator(option)"
                                    class="custom-btn-radio-group"
                                >
                                    <a-radio-button
                                        v-for="item in option.options"
                                        :key="item.value"
                                        :value="item.value"
                                    >
                                        {{ item.label }}
                                    </a-radio-button>
                                </a-radio-group>

                                <a-date-picker
                                    v-if="option.type === 'date'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                    style="width: 100%;"
                                />
                                <a-input
                                    v-if="!option.type || option.type === 'input'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                />
                                <div v-if="option.field === 'accountHolder'">
                                    <div class="b4">
                                        小文字は入力できません。
                                    </div>
                                    <div class="b4">
                                        長音記号は「-」（半角マイナスハイフン）にて入力ください。
                                    </div>
                                </div>
                            </a-form-item>
                        </template>
                    </section>

                    <div class="b2 mt60 mb28">
                        下記項目全てにご回答お願いいたします
                    </div>
                    <section>
                        <template
                            v-for="option in options3"
                        >
                            <a-form-item
                                :key="option.field"
                                :label="option.label"
                            >
                                <a-radio-group
                                    v-if="option.type === 'radio'"
                                    v-decorator="genDecorator(option)"
                                    class="custom-btn-radio-group"
                                >
                                    <a-radio-button
                                        v-for="item in option.options"
                                        :key="item.value"
                                        :value="item.value"
                                    >
                                        {{ item.label }}
                                    </a-radio-button>
                                </a-radio-group>
                                <a-checkbox-group
                                    v-if="option.type === 'checkbox'"
                                    v-decorator="genDecorator(option)"
                                    :options="option.options"
                                    class="custom-btn-checkbox-group"
                                />
                                <a-date-picker
                                    v-if="option.type === 'date'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                    style="width: 100%;"
                                />
                                <a-input
                                    v-if="!option.type || option.type === 'input'"
                                    v-decorator="genDecorator(option)"
                                    :placeholder="option.placeholder"
                                    :suffix="option.suffix"
                                />
                                <div
                                    v-if="option.field === 'financialAssets'"
                                    class="b4 c-04 mt4"
                                >
                                    当社ファンドの購入上限はお客様の金融資産の５０％までです。
                                </div>
                                <div
                                    v-if="option.field === 'peps'"
                                >
                                    <a
                                        :href="$options.pepsPdfUrl"
                                        target="_blank"
                                        style="text-decoration-line: underline;"
                                    >
                                        外国PEPs>>
                                    </a>
                                </div>
                            </a-form-item>
                        </template>
                    </section>
                    <a-form-item>
                        <button
                            type="primary"
                        >
                            次へ
                        </button>
                    </a-form-item>
                </a-form>
            </section>
        </div>
        <template #tip>
            <div class="my-tip">
                <h1 class="bold c-09">
                    ウェルカム
                </h1>
                <h5 class="c-09">
                    {{ email }}
                </h5>
            </div>
        </template>
    </register-layout>
</template>

<script>
import {mapGetters} from 'vuex';

import {isServer} from 'ssr-common/constant/config';

import RegisterLayout from '../component/register-layout';
import {fieldsMap, options1 as opts1, options2 as opts2, options3 as opts3, genOptions} from './fields';

const pepsPdfUrl = '/static/pdf/PEPs.pdf';
export default {
    name: 'RegisterStep2',

    pepsPdfUrl,

    components: {
        RegisterLayout
    },

    data() {
        return {
            fieldsMap,
            options1: [],
            options2: [],
            options3: [],
            form: null,
            isServer: isServer(),
            formData: null,
        };
    },

    computed: {
        ...mapGetters('finance', ['email']),

        optionMap() {
            const {options1, options2, options3} = this;

            return [...options1, ...options2, ...options3].reduce((acc, cur) => {
                const {field, ...option} = cur;

                return {
                    ...acc,
                    [field]: option
                };
            }, {});
        }
    },

    mounted() {
        this.initOptions();
        const {onValuesChange} = this;
        this.form = this.$form.createForm(this, {
            name: 'step2',
            onValuesChange
        });
    },

    methods: {
        genOptions,

        async initOptions() {
            const [options1, options2, options3] = await Promise.all([
                genOptions(opts1, fieldsMap),
                genOptions(opts2, fieldsMap),
                genOptions(opts3, fieldsMap)
            ]);
            Object.assign(this, {options1, options2, options3});
        },

        genDecorator(option) {
            const {field, rules: originRules = [], required} = option;
            const requiredMsg = ' ';
            const requiredRule = {required, message: requiredMsg};

            const rules = [].concat(requiredRule, originRules);

            return [field, {rules}];
        },

        onValuesChange(form, value, values) {
            this.formData = values;
        },

        handleSubmit() {
            this.form.validateFieldsAndScroll({scroll: {offsetTop: 200}}, (err, values) => {
                if (err) return;

                const {birthday, ...restData} = values || {};
                const cond = {
                    birthday: new Date(birthday).getTime(),
                    ...restData
                };
                this.$store.commit('finance/SAVE', {step2FormData: cond});
                this.$router.push({name: 'RegisterStep2-2'});
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~instacash/common/style/finance/index.scss";

.c-09 {
    color: $c-09;
}

.c-04 {
    color: $c-04;
}

.red-02 {
    color: $red-02;
}

.rs2 {
    .mt4 {
        margin-top: 0.04rem;
    }

    .mt24 {
        margin-top: 0.24rem;
    }

    .mt28 {
        margin-top: 0.28rem;
    }

    .mb28 {
        margin-bottom: 0.28rem;
    }

    .mt60 {
        margin-top: 0.6rem;
    }

    .pt24 {
        padding-top: 0.24rem;
    }

    .pt28 {
        padding-top: 0.28rem;
    }

    .email {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0.1rem 0.2rem;
        background-color: $grey-01;
        border-radius: 0.12rem;

        &-logo {
            margin-right: 0.18rem;
            width: 0.24rem;
        }
    }

    .fm {
        border: 1px solid blue;
    }

    &-bg {
        position: sticky;
        top: 1.2rem;
        border: 1px solid red;
        width: 5.56rem;
        height: 7rem;
    }

    .controler-box {
        display: flex;
        flex-wrap: wrap;

        .ant-form-item {
            width: calc(50% - 0.15rem);
            margin-bottom: 0.24rem;

            &:nth-child(odd) {
                margin-right: 0.3rem;
            }
        }
    }

    .ant-input,
    .ant-input-affix-wrapper {
        max-width: 3rem;
    }
}

</style>
