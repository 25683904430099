var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "register-layout",
    {
      attrs: { step: 2 },
      scopedSlots: _vm._u([
        {
          key: "tip",
          fn: function () {
            return [
              _c("div", { staticClass: "my-tip" }, [
                _c("h1", { staticClass: "bold c-09" }, [
                  _vm._v("\n                ウェルカム\n            "),
                ]),
                _vm._v(" "),
                _c("h5", { staticClass: "c-09" }, [
                  _vm._v(
                    "\n                " + _vm._s(_vm.email) + "\n            "
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "rs2" }, [
        _c("h2", { staticClass: "bold" }, [
          _vm._v("\n            会員登録\n        "),
        ]),
        _vm._v(" "),
        _c("section", [
          _c("p", { staticClass: "b3 mt24" }, [
            _vm._v(
              "\n                仮登録完了後ご入力頂いたメールアドレスへ本会員登録のご案内を配信します。\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "b3" }, [
            _vm._v(
              "\n                ※ 自動配信メールが届かない場合は、「インスタキャッシュ」のドメイン（instachsh.jp）を許可する設定になっているかご確認ください。\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.isServer && _vm.form
          ? _c(
              "section",
              { staticClass: "mt60 fm-1" },
              [
                _c(
                  "a-form",
                  {
                    attrs: { layout: "vertical", form: _vm.form },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "section",
                      { staticClass: "controler-box" },
                      [
                        _vm._l(_vm.options1, function (option) {
                          return [
                            _c(
                              "a-form-item",
                              {
                                key: option.field,
                                attrs: { label: option.label },
                              },
                              [
                                option.type === "radio"
                                  ? _c(
                                      "a-radio-group",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.genDecorator(option),
                                            expression: "genDecorator(option)",
                                          },
                                        ],
                                        staticClass: "custom-btn-radio-group",
                                      },
                                      _vm._l(option.options, function (item) {
                                        return _c(
                                          "a-radio-button",
                                          {
                                            key: item.value,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.label) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                option.type === "date"
                                  ? _c("a-date-picker", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: option.placeholder,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !option.type || option.type === "input"
                                  ? _c("a-input", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: option.placeholder,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                option.field === "mobileNumber"
                                  ? _c("div", { staticClass: "b4 c-04 mt4" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(option.extra) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "b2 mt60 mb28" }, [
                      _vm._v(
                        "\n                    本人名義の銀行口座情報を入力してください。\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "controler-box" },
                      [
                        _vm._l(_vm.options2, function (option) {
                          return [
                            _c(
                              "a-form-item",
                              {
                                key: option.field,
                                attrs: { label: option.label },
                              },
                              [
                                option.type === "radio"
                                  ? _c(
                                      "a-radio-group",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.genDecorator(option),
                                            expression: "genDecorator(option)",
                                          },
                                        ],
                                        staticClass: "custom-btn-radio-group",
                                      },
                                      _vm._l(option.options, function (item) {
                                        return _c(
                                          "a-radio-button",
                                          {
                                            key: item.value,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.label) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                option.type === "date"
                                  ? _c("a-date-picker", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: option.placeholder,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !option.type || option.type === "input"
                                  ? _c("a-input", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: option.placeholder,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                option.field === "accountHolder"
                                  ? _c("div", [
                                      _c("div", { staticClass: "b4" }, [
                                        _vm._v(
                                          "\n                                    小文字は入力できません。\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "b4" }, [
                                        _vm._v(
                                          "\n                                    長音記号は「-」（半角マイナスハイフン）にて入力ください。\n                                "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "b2 mt60 mb28" }, [
                      _vm._v(
                        "\n                    下記項目全てにご回答お願いいたします\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "section",
                      [
                        _vm._l(_vm.options3, function (option) {
                          return [
                            _c(
                              "a-form-item",
                              {
                                key: option.field,
                                attrs: { label: option.label },
                              },
                              [
                                option.type === "radio"
                                  ? _c(
                                      "a-radio-group",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.genDecorator(option),
                                            expression: "genDecorator(option)",
                                          },
                                        ],
                                        staticClass: "custom-btn-radio-group",
                                      },
                                      _vm._l(option.options, function (item) {
                                        return _c(
                                          "a-radio-button",
                                          {
                                            key: item.value,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.label) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                option.type === "checkbox"
                                  ? _c("a-checkbox-group", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      staticClass: "custom-btn-checkbox-group",
                                      attrs: { options: option.options },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                option.type === "date"
                                  ? _c("a-date-picker", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: option.placeholder,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !option.type || option.type === "input"
                                  ? _c("a-input", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genDecorator(option),
                                          expression: "genDecorator(option)",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: option.placeholder,
                                        suffix: option.suffix,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                option.field === "financialAssets"
                                  ? _c("div", { staticClass: "b4 c-04 mt4" }, [
                                      _vm._v(
                                        "\n                                当社ファンドの購入上限はお客様の金融資産の５０％までです。\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                option.field === "peps"
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            "text-decoration-line": "underline",
                                          },
                                          attrs: {
                                            href: _vm.$options.pepsPdfUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    外国PEPs>>\n                                "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("a-form-item", [
                      _c("button", { attrs: { type: "primary" } }, [
                        _vm._v(
                          "\n                        次へ\n                    "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }