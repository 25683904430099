<!-- @Author: huafengli -->
<!-- @Date: 2024-5-6 16:47:43 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-05-07 16:48:03 -->

<template>
    <div class="login-box">
        <div class="login-left">
            <div class="title">
                ログイン
            </div>
            <div
                v-if="form"
                class="form-wrap"
            >
                <a-form
                    class="form"
                    layout="vertical"
                    :form="form"
                >
                    <a-form-item
                        label="メールアドレス"
                    >
                        <a-input
                            v-decorator="['email', {initialValue: initialEmail, rules: getRules('email')}]"
                            placeholder="メールアドレスを入力してください"
                        />
                    </a-form-item>
                    <a-form-item
                        label="パスワード"
                    >
                        <a-input
                            v-decorator="['password', {rules: getRules('password')}]"
                            type="password"
                            placeholder="パスワードを入力してください"
                        />
                    </a-form-item>

                    <div
                        class="remember-container"
                        @click="onRemember"
                    >
                        <img
                            class="check-icon"
                            :src="isRememberEmail ? Select : NoSelect"
                            alt=""
                        >
                        <span class="check-text">次回からメールアドレスを省略します。</span>
                    </div>
                    <div
                        class="btn"
                        :class="{active: canSubmit}"
                        @click="onSubmit"
                    >
                        ログイン
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';

import NoSelect from 'instacash/common/image/no-select.svg';
import Select from 'instacash/common/image/select.svg';

import statusFlow from 'src/common/mixin/statusFlow';
const UESR_EMAIL = 'email';

export default {
    name: 'Login',

    mixins: [statusFlow],

    data() {
        return {
            form: null,
            formData: null,
            canSubmit: false,
            isRememberEmail: false,
            initialEmail: '',
            NoSelect,
            Select
        };
    },

    mounted() {
        const {onValuesChange} = this;

        this.form = this.$form.createForm(this, {
            name: 'login',
            onValuesChange
        });

        const email = localStorage.getItem(UESR_EMAIL);
        if (email) {
            this.isRememberEmail = true;
            this.initialEmail = email;
        }
    },

    methods: {
        ...mapActions('finance', {
            login: 'login'
        }),

        onValuesChange(form, value, values) {
            this.formData = values || {};
            if (this.isRememberEmail) {
                this.updateLocalStorage(values.email);
            }

            this.$nextTick(() => {
                this.form.validateFields(err => {
                    this.canSubmit = !err;
                });
            });
        },

        onRemember() {
            this.isRememberEmail = !this.isRememberEmail;
            if (this.isRememberEmail) {
                this.updateLocalStorage(this.formData?.email);
            } else {
                this.removeLocalStorage();
            }
        },

        async onSubmit() {
            const {canSubmit} = this;
            if (!canSubmit) return;

            await this.login(this.formData);
            const {$route: {query: {emailToken, from}}} = this;
            if (emailToken && from) {
                const href = this.$router.resolve({name: from, query: {emailToken}}).href;
                window.open(href, '_self');

                return;
            }

            this.goPage();
        },

        removeLocalStorage() {
            localStorage.removeItem(UESR_EMAIL);
        },

        updateLocalStorage(val) {
            localStorage.setItem(UESR_EMAIL, val);
        },

        getRules(field) {
            let rules = [];
            switch (field) {
                case 'email':
                    rules = [
                        {required: true, message: ' '}
                        // {type: 'email', message: 'メールのフォーマットが正しくありません'}
                    ];

                    break;
                case 'password':
                    rules = [
                        {required: true, message: ' '}
                    ];
                    break;
                default:
                    break;
            }

            return rules;
        }
    }
};
</script>
<style lang="scss" scoped>
    .login-box {
        flex: 1;
        display: flex;

        &::after {
            content: "";
            display: block;
            flex: 1;
            background: url("./img/group.jpg") no-repeat center / cover;
            padding-top: 58.2%;
        }
    }

    .login-left {
        flex: 0 1 50%;
        font-family: Noto Sans JP;
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.22rem;
        color: $c-02;
        padding: 1.6rem 1.6rem 2.4rem 1.2rem;

        .title {
            font-family: Noto serif JP;
            font-size: 0.32rem;
            font-weight: 500;
            line-height: 0.42rem;
            color: $c-01;
        }

        .form-wrap {
            margin-top: 0.6rem;

            .form {
                display: flex;
                flex-direction: column;

                .remember-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .check-icon {
                        width: 0.18rem;
                        height: 0.18rem;
                    }

                    .check-text {
                        margin-left: 0.08rem;
                        font-family: Noto Sans JP;
                        font-size: 0.12rem;
                        font-weight: 400;
                        line-height: 0.19rem;
                        color: #4c4948;
                    }
                }

                .btn {
                    border-radius: 0.12rem;
                    margin-top: 0.4rem;
                    width: 3rem;
                    height: 0.52rem;
                    background-color: $c-06;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Noto Sans JP;
                    font-size: 0.16rem;
                    font-weight: 700;
                    line-height: 1;
                    cursor: pointer;
                }

                .active {
                    color: $c-09;
                    background-color: $c-01;
                }
            }
        }
    }
</style>
