<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-28 19:06:18 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-02-29 17:47:04 -->

<template>
    <div class="i-empty">
        <img src="./empty.svg">
        <p class="b3">
            対象がありません。
        </p>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'IEmpty'
};
</script>

<style lang="scss" scoped>
.i-empty {
    margin: 1.8rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        margin-bottom: 0.2rem;
        width: 0.6rem;
    }

    p {
        color: $c-04;
    }
}
</style>
