/*
 * @Author: huafengli
 * @Date: 2024-05-09 17:16:37
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-19 16:29:18
 */
import { productTypeEnum } from 'src/common/constant/enum';
export default {
    install(Vue) {
        // 数字转全角
        Vue.filter('toFullWidth', value => {
            const map = {
                0: '０',
                1: '１',
                2: '２',
                3: '３',
                4: '４',
                5: '５',
                6: '６',
                7: '７',
                8: '８',
                9: '９',
            };
            return `${value}`.split('').map(i => i in map ? map[i] : i).join('');
        });
        // 截断报告书文件名称
        Vue.filter('sliceReportName', value => {
            return (value || '').slice(42); // 截断uuid的部分
        });
        // 映射产品类型
        Vue.filter('formatProductType', val => {
            return productTypeEnum[val] || val;
        });
        Vue.filter('currencyFormat', (val = '') => {
            return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        });
    }
};
