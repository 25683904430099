/*
 * @Author: huafengli
 * @Date: 2024-05-08 11:23:48
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-16 10:28:25
 */
import Resource from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    deposit: {
        url: '/api/bank/pay/info',
        method: 'get'
    }
};
export default Resource.create(api);
