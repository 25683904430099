/*
 * @Author: huafengli
 * @Date: 2024-04-30 10:20:01
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-05-17 22:45:21
 */
import Resource from '@yqg/resource';
import { getHost } from 'src/common/constant/config';
const api = {
    getHost,
    enums: {
        url: '/api/common/enums/:name',
        method: 'get'
    },
    file: {
        url: '/api/common/file/download',
        method: 'get',
    },
    upload: {
        url: '/api/common/file/upload',
        method: 'post',
        formData: true
    }
};
export default Resource.create(api);
