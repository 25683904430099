<!--
 * @Author: zhaoyang
 * @Date: 2024-02-25 15:23:33
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-03-06 16:07:25
-->

<template>
    <div
        v-if="list && list.length"
        class="list"
    >
        <div class="title">
            全てのファンド
        </div>
        <a-list
            :data-source="list"
            :class="{'flex-card-list': isFlexCard}"
        >
            <div
                v-if="showLoadingMore"
                slot="loadMore"
                class="loading-more-container"
            >
                <a-spin v-if="loadingMore" />
                <div
                    v-else
                    class="more-btn b3"
                    @click="loadMore"
                >
                    もっと読み込む
                </div>
            </div>
            <template #renderItem="item">
                <div
                    class="box"
                    :class="{'flex-card': isFlexCard, 'mb20': !isFlexCard, ...{[getProductStatusClass(item.showStatus)[1]]: !isFlexCard}}"
                    @click="onGo(item)"
                >
                    <div
                        class="part-1"
                        :class="{[getProductStatusClass(item.showStatus)[1]]: isFlexCard}"
                    >
                        <div class="box-title">
                            {{ item.type | formatProductType }}
                        </div>
                        <div class="title1">
                            {{ item.name }}
                        </div>
                        <div
                            class="product-status"
                            :class="getProductStatusClass(item.showStatus)[0]"
                        >
                            {{ $options.ProductStatusEnum[item.showStatus] }}
                        </div>
                    </div>
                    <div class="part-2">
                        <div>
                            <div class="title2">
                                目標利回り：{{ item.interestRate | toFullWidth }}％／年
                            </div>
                            <div class="title2">
                                運用期間：{{ item.closedDays | toFullWidth }}日
                            </div>
                            <div class="title2">
                                ファンド名：{{ item.name }}（{{ item.type | formatProductType }}）
                            </div>
                        </div>

                        <img
                            v-if="!$options.All_DOWN_STATUS.includes(item.showStatus)"
                            src="./img/arrow.svg"
                            alt=""
                            class="arrow"
                        >
                    </div>
                </div>
            </template>
        </a-list>
    </div>
    <i-empty v-else />
</template>

<script>

import {ProductStatusEnum} from 'instacash/common/constant/enum';
import Product from 'instacash/common/resource/product';

import {All_DOWN_STATUS} from './constant';

export default {
    name: 'ProductList',

    ProductStatusEnum,

    All_DOWN_STATUS,

    data() {
        return {
            pageNo: 1,
            pageSize: 6,
            total: 0,
            list: null,
            loadingMore: false,
        };
    },

    computed: {
        totalPage() {
            const {pageSize, total} = this;

            return Math.ceil(total / pageSize);
        },

        showLoadingMore() {
            return this.pageNo < this.totalPage;
        },

        isFlexCard() {
            return this.total > 1;
        }
    },

    mounted() {
        this.fetchProductList();
    },

    methods: {
        onGo(item) {
            this.$router.push(`/product/${item.id}`);
        },

        async loadMore() {
            this.loadingMore = true;

            if (this.pageNo < this.totalPage) {
                try {
                    await this.fetchProductList(this.pageNo + 1);
                } catch (e) {
                    //
                }

                this.loadingMore = false;
            }
        },

        async fetchProductList(pageNo = this.pageNo) {
            const params = {pageNo, pageSize: this.pageSize};
            const {data: {body}} = await Product.product({params});
            this.pageNo = pageNo;
            this.list = (this.list || []).concat(body?.records || []);
            this.total = body?.total || 0;
        },

        getProductStatusClass(status) {
            return {
                NOT_OPEN: ['status-bg-purple'],
                ...All_DOWN_STATUS.reduce((acc, cur) => {
                    acc[cur] = ['status-bg-gray', 'card-disabled'];

                    return acc;
                }, {}),
            }[status] || [];
        }
    }
};
</script>

<style lang="scss" scoped>
.mb {
    margin-bottom: 0.2rem;
}

.list {
    padding: 0.2rem 1rem;

    .title {
        color: #32302f;
        padding-top: 0.4rem;
        font-family: Noto serif JP;
        font-size: 0.4rem;
        font-weight: 700;
        line-height: 0.52rem;
    }

    .box {
        position: relative;
        margin-top: 0.4rem;
        box-sizing: border-box;
        border-radius: 0.16rem;
        padding: 0.4rem 0.6rem;
        background-image: url("./img/bg-s.png");
        background-size: 100% 100%;
        background-color: $c-09;

        &.flex-card {
            width: 4rem;
            background-image: url("./img/flex-product-card-bg.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0;
            display: flex;
            flex-direction: column;

            .part-1 {
                height: 2.24rem;
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0.4rem;

                .box-title {
                    text-align: center;
                }

                .title1 {
                    text-align: center;
                    font-size: 0.2rem;
                    line-height: 0.26rem;
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
            }

            .part-2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0.2rem;
                flex: 1;

                .title2 {
                    color: $c-01;
                }

                .arrow {
                    margin-top: 0.2rem;
                }
            }
        }

        .part-1 {
            position: relative;
        }

        .box-title {
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #cacaca;
        }

        .title1 {
            margin-top: 0.16rem;
            margin-bottom: 0.16rem;
            font-family: Noto serif JP;
            font-size: 0.32rem;
            font-weight: 700;
            line-height: 0.42rem;
            color: #fff;
        }

        .title2 {
            font-family: Noto Sans JP;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.22rem;
            color: #fff;
        }

        .arrow {
            margin-top: 0.36rem;
            width: 0.48rem;
            height: 0.48rem;
            cursor: pointer;
        }

        .product-status {
            padding: 0.02rem 0.08rem;
            border-radius: 0.13rem;
            color: $c-09;
            position: absolute;
            top: 0.08rem;
            right: 0.08rem;
        }

        .status-bg-gray {
            background-color: $c-03;
            z-index: 1;
        }

        .status-bg-purple {
            background-color: #724589;
        }
    }

    .card-disabled::before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .loading-more-container {
        display: flex;
        justify-content: center;

        .more-btn {
            cursor: pointer;
            padding: 0.2rem;
        }
    }

    ::v-deep .flex-card-list {
        .ant-list-items {
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
        }
    }
}
</style>
