/*
 * @Author: huafengli
 * @Date: 2024-05-15 19:20:56
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-16 16:45:02
 */
import { mapGetters } from 'vuex';
export default {
    computed: Object.assign({}, mapGetters('finance', ['status'])),
    methods: {
        goPage() {
            const { status } = this;
            const { $route: { name } } = this;
            let toRoute;
            switch (status) {
                case 'TEMP_CREATE': // 临时会员
                    toRoute = 'RegisterStep1-2';
                    break;
                case 'KYC_WAIT_SUBMIT': // kyc 待提交
                    toRoute = 'RegisterStep2-1';
                    break;
                case 'KYC_IN_REVIEW': // 审核中
                case 'KYC_REFUSE': // 审核拒绝
                case 'KYC_WAIT_UPDATE': // kyc待修改
                    toRoute = 'RegisterStep3';
                    break;
                case 'KYC_PASS': //kyc通过
                case 'KYC_SEND': // kyc 已寄送
                    toRoute = 'RegisterKycCode';
                    break;
                default:
                    {
                        if (status) {
                            if (/Login|Register/.test(name)) {
                                toRoute = 'Index';
                            }
                        }
                        else {
                            toRoute = 'Login';
                        }
                    }
                    break;
            }
            if (toRoute && toRoute !== name) {
                this.$router.replace({ name: toRoute });
            }
        }
    }
};
