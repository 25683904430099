<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:11:29 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:25 -->

<template>
    <section class="controler-box">
        <template
            v-for="option in options"
        >
            <a-form-item
                :key="option.field"
                :label="option.label"
            >
                <a-radio-group
                    v-if="option.type === 'radio'"
                    v-decorator="genDecorator(option)"
                    class="custom-btn-radio-group"
                    :disabled="option.useDisabled && isDisabled"
                >
                    <a-radio-button
                        v-for="item in option.options"
                        :key="item.value"
                        :value="item.value"
                    >
                        {{ item.label }}
                    </a-radio-button>
                </a-radio-group>

                <a-date-picker
                    v-if="option.type === 'date'"
                    v-decorator="genDecorator(option)"
                    :placeholder="option.placeholder"
                    style="width: 100%;"
                    :disabled="option.useDisabled && isDisabled"
                />
                <a-input
                    v-if="!option.type || option.type === 'input'"
                    v-decorator="genDecorator(option)"
                    :placeholder="option.placeholder"
                    :disabled="option.useDisabled && isDisabled"
                />
                <div
                    v-if="option.field === 'mobileNumber'"
                    class="b4 c-04 mt4"
                >
                    {{ option.extra }}
                </div>
            </a-form-item>
        </template>
    </section>
</template>
<script>
import formMixin from '../mixins/form';

export default {
    name: 'BaseInfo',

    mixins: [formMixin],

    props: {
        options: {
            type: Array,
            default: () => []
        }
    }
};
</script>
<style lang="scss" scoped>
@import "~instacash/common/style/finance/index.scss";

.c-04 {
    color: $c-04;
}

.controler-box {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.28rem;

    .ant-form-item {
        width: calc(50% - 0.15rem);
        margin-bottom: 0.24rem;

        &:nth-child(odd) {
            margin-right: 0.3rem;
        }
    }
}
</style>
