<!--
 * @Author: zhaoyang
 * @Date: 2024-02-25 15:47:38
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-03-01 11:16:12
-->

<template>
    <div class="container record">
        <div class="title">
            お客様の投資申込を受付けいたしました
        </div>

        <div class="b1">
            別途購入金振込のご連絡いたしますので、お待ちください。
        </div>

        <!-- <div
            v-if="bankInfo"
            class="wrap"
        >
            <item-info
                v-for="item in bankFields"
                :key="item.label"
                class="wrap-item"
                label-class="left"
                value-class="right"
                :item="item"
                :val-obj="bankInfo"
            />
        </div> -->

        <div
            class="btn"
            @click="onNext"
        >
            申込履歴>>>
        </div>
    </div>
</template>

<script>
import Bank from 'instacash/common/resource/bank';

import {bankFields} from './field';

export default {
    name: 'ProductRecord',

    data() {
        return {
            bankFields,
            bankInfo: null
        };
    },

    // mounted() {
    //     this.fetchBankInfo();
    // },

    methods: {
        async fetchBankInfo() {
            const {data: {body}} = await Bank.deposit();
            this.bankInfo = body;
        },

        onNext() {
            this.$router.push({
                name: 'TradeRecord'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.record {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        box-sizing: border-box;
        padding-top: 0.4rem;
        font-family: Noto serif JP;
        font-size: 0.4rem;
        font-weight: 700;
        line-height: 0.52rem;
        color: #32302f;
        margin-bottom: 0.24rem;
    }

    .wrap {
        margin-top: 0.2rem;
        width: 4.05rem;
        box-sizing: border-box;
        padding: 0.28rem 0.48rem;
        border: 1px solid #cacaca;
        border-radius: 0.16rem;

        ::v-deep .wrap-item {
            display: flex;
            margin-top: 0.12rem;

            .left {
                width: 0.84rem;
                font-family: Noto Sans JP;
                font-size: 0.14rem;
                font-weight: 700;
                line-height: 0.22rem;
                color: #32302f;
            }

            .right {
                flex: 1;
                margin-left: 0.4rem;
                font-family: Noto Sans JP;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.22rem;
                color: #5f5b5a;
            }
        }
    }

    .btn {
        border-radius: 0.12rem;
        margin-top: 0.6rem;
        width: 3rem;
        height: 0.52rem;
        color: $c-09;
        background-color: $yellow-02;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Noto Sans JP;
        font-size: 0.16rem;
        font-weight: 700;
        line-height: 1;
    }
}
</style>
