<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:11:35 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:25 -->

<template>
    <div>
        <div class="b2 mb28">
            下記項目全てにご回答お願いいたします
        </div>
        <section>
            <template
                v-for="option in options"
            >
                <a-form-item
                    :key="option.field"
                    :label="option.label"
                >
                    <a-radio-group
                        v-if="option.type === 'radio'"
                        v-decorator="genDecorator(option)"
                        class="custom-btn-radio-group"
                    >
                        <a-radio-button
                            v-for="item in option.options"
                            :key="item.value"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-radio-button>
                    </a-radio-group>
                    <a-checkbox-group
                        v-if="option.type === 'checkbox'"
                        v-decorator="genDecorator(option)"
                        :options="option.options"
                        class="custom-btn-checkbox-group"
                    />
                    <a-date-picker
                        v-if="option.type === 'date'"
                        v-decorator="genDecorator(option)"
                        :placeholder="option.placeholder"
                        style="width: 100%;"
                    />
                    <a-input
                        v-if="!option.type || option.type === 'input'"
                        v-decorator="genDecorator(option)"
                        :placeholder="option.placeholder"
                        :suffix="option.suffix"
                    />
                    <div
                        v-if="option.field === 'financialAssets'"
                        class="b4 c-04 mt4"
                    >
                        当社ファンドの購入上限はお客様の金融資産の５０％までです。
                    </div>
                    <div
                        v-if="option.field === 'peps'"
                    >
                        <a
                            :href="$options.pepsPdfUrl"
                            target="_blank"
                            style="text-decoration-line: underline;"
                        >
                            外国PEPs>>
                        </a>
                    </div>
                </a-form-item>
            </template>
        </section>
    </div>
</template>
<script>
import formMixin from '../mixins/form';
export default {
    name: 'InvestmentInfo',

    mixins: [formMixin],

    props: {
        options: {
            type: Array,
            default: () => []
        }
    }
};
</script>
<style lang="scss" scoped>
@import "~instacash/common/style/finance/index.scss";

.c-04 {
    color: $c-04;
}

.mt4 {
    margin-top: 0.04rem;
}

.mb28 {
    margin-bottom: 0.28rem;
}

.ant-input,
.ant-input-affix-wrapper {
    max-width: 3rem;
}
</style>
