var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "register-layout",
    {
      scopedSlots: _vm._u([
        {
          key: "tip",
          fn: function () {
            return [
              _c("div", { staticClass: "tip" }, [
                _c("span", [_vm._v("以下に該当するお客様は、")]),
                _vm._v(" "),
                _c("span", { staticClass: "red" }, [
                  _vm._v("ご利用になれません。"),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("予めご了承ください")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.tipList, function (item) {
                return _c("div", { key: item, staticClass: "tip-list c-09" }, [
                  _vm._v("\n            " + _vm._s(item) + "\n        "),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "b3 c-09",
                  staticStyle: { "margin-top": "0.15rem" },
                },
                [
                  _vm._v(
                    "\n            こ不明点等がありましたらお間い合わせよりこ連絡下さい。\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "register-left" }, [
        _c("h2", { staticClass: "bold title" }, [
          _vm._v(
            "\n            アカウント登録はまだ完了していません、下記のメッセージをお読みください。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            "\n            １）ご登録いただいたメールアドレス宛にアカウント情報登録のURLを送付いたしましたので、登録を完了してください。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            "\n            ２）情報登録用URLは２４時間以内有効です。期限が過ぎると新たに仮登録を行ってください。\n        "
          ),
        ]),
        _vm._v(" "),
        _vm.emailTokenExpire
          ? _c("div", { staticClass: "mt24" }, [
              _c("div", { staticClass: "desc" }, [
                _vm._v(
                  "\n                メールで送付したURLで登録してください。\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _vm._v("\n                メールが届かない場合"),
                _c(
                  "a",
                  { staticClass: "b2 yellow-02", on: { click: _vm.reSend } },
                  [_vm._v("「メールの再送信」")]
                ),
                _vm._v("ボタンをクリックしてください。\n            "),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }