var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length
    ? _c("div", { staticClass: "container trade-record-page" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "mt28" },
          [
            _c(
              "a-list",
              {
                attrs: { "data-source": _vm.list },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "renderItem",
                      fn: function (item) {
                        return [
                          _c(
                            "div",
                            { staticClass: "info-card des-group mb20" },
                            [
                              _c("div", { staticClass: "des-group-row b3" }, [
                                _c(
                                  "div",
                                  { staticClass: "des-group-row-label bold" },
                                  [
                                    _vm._v(
                                      "\n                            申込日\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "des-group-row-value pdf-row",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("date")(item.timeCreated)
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              item.payTime
                                ? _c(
                                    "div",
                                    { staticClass: "des-group-row b3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "des-group-row-label bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            入金日\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "des-group-row-value pdf-row",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("date")(item.payTime)
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "des-group-row b3" }, [
                                _c(
                                  "div",
                                  { staticClass: "des-group-row-label bold" },
                                  [
                                    _vm._v(
                                      "\n                            ファンド名\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "des-group-row-value pdf-row",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.name) +
                                        "（" +
                                        _vm._s(
                                          _vm._f("formatProductType")(item.type)
                                        ) +
                                        "）\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "des-group-row b3" }, [
                                _c(
                                  "div",
                                  { staticClass: "des-group-row-label bold" },
                                  [
                                    _vm._v(
                                      "\n                            購入金額\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "des-group-row-value pdf-row",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("currencyFormat")(
                                            item.payAmount
                                          )
                                        ) +
                                        "万円\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "des-group-row b3" }, [
                                _c(
                                  "div",
                                  { staticClass: "des-group-row-label bold" },
                                  [
                                    _vm._v(
                                      "\n                            各種書類\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "des-group-row-value pdf-row",
                                  },
                                  [
                                    _vm._l(_vm.staticPdfList, function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item,
                                          staticClass: "pdf-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRead(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pdf-item-wrapper" },
                                            [
                                              _c("pdf-svg"),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.pdfDisplayNameMap[item]
                                                  ) +
                                                  ".pdf\n                                "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    item.files && item.files.length
                                      ? _vm._l(
                                          item.files,
                                          function (name, urlIdx) {
                                            return _c(
                                              "div",
                                              {
                                                key: urlIdx,
                                                staticClass: "pdf-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onReadFile(name)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pdf-item-wrapper",
                                                  },
                                                  [
                                                    _c("pdf-svg"),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "sliceReportName"
                                                          )(name)
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "des-group-row b3" }, [
                                _c(
                                  "div",
                                  { staticClass: "des-group-row-label bold" },
                                  [
                                    _vm._v(
                                      "\n                            運用報告書\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "des-group-row-value pdf-row",
                                  },
                                  [
                                    item.reports && item.reports.length
                                      ? _vm._l(
                                          item.reports,
                                          function (name, urlIdx) {
                                            return _c(
                                              "div",
                                              {
                                                key: urlIdx,
                                                staticClass: "pdf-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onReadFile(name)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pdf-item-wrapper",
                                                  },
                                                  [
                                                    _c("pdf-svg"),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "sliceReportName"
                                                          )(name)
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2624339507
                ),
              },
              [
                _vm.showLoadingMore
                  ? _c(
                      "div",
                      {
                        staticClass: "loading-more-container",
                        attrs: { slot: "loadMore" },
                        slot: "loadMore",
                      },
                      [
                        _vm.loadingMore
                          ? _c("a-spin")
                          : _c(
                              "div",
                              {
                                staticClass: "more-btn b3",
                                on: { click: _vm.loadMore },
                              },
                              [
                                _vm._v(
                                  "\n                    もっと読み込む\n                "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ])
    : _c("i-empty")
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h1", { staticClass: "bold" }, [
        _vm._v("\n            申込履歴\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }