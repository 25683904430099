var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container register-kyc-code" }, [
    _c("h2", [_vm._v("郵便でお知らせした認証コ-ドをご入力下さい。")]),
    _vm._v(" "),
    _c("div", { staticClass: "input-wrapper" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.kycCode,
            expression: "kycCode",
          },
        ],
        class: { "caret-hide": _vm.kycCode.length === 6 },
        attrs: { type: "text", autofocus: "", maxlength: "6" },
        domProps: { value: _vm.kycCode },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.kycCode = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "input-bg" }),
    ]),
    _vm._v(" "),
    _vm.kycInvalid
      ? _c("div", { staticClass: "input-tip b3" }, [
          _vm._v("\n        認証コードが正しくありません。\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c(
        "button",
        {
          staticClass: "b2 bold",
          attrs: { disabled: _vm.kycCode.length !== 6 },
          on: { click: _vm.send },
        },
        [_vm._v("\n            認証\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }