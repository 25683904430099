<!-- @Author: huafengli -->
<!-- @Date: 2024-7-9 14:11:52 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-07-09 19:49:26 -->

<template>
    <section class="login-info-section">
        <a-form-item
            v-if="!isReRegister"
            label="メールアドレス"
            style="width: 100%;"
        >
            <div class="email-box">
                <h5>{{ userInfo.email }}</h5>
                <div
                    class="modif-entry"
                    @click="onOpenModifyEmail"
                >
                    <img
                        class="modif-entry-icon"
                        src="../image/edit.svg"
                    >
                    <span class="b2_plus modif-entry-text">メールアドレス変更</span>
                </div>
            </div>
        </a-form-item>

        <a-form-item
            label="パスワード"
            style="width: 53%;"
        >
            <a-input
                v-decorator="['password', {rules: getRules('password')}]"
                type="password"
                style="width: 92%;"
            />
        </a-form-item>
        <a-form-item
            label="パスワード確認"
            style="width: 47%;"
        >
            <a-input
                v-decorator="['passwordMust', {rules: getRules('passwordMust')}]"
                type="password"
            />
        </a-form-item>
        <a-form-item
            label="秘密の質問"
            style="width: 53%;"
        >
            <a-radio-group
                v-decorator="['pwdQuestion', { rules: [{ required: true, message: ' ' }] }]"
                class="custom-btn-radio-group unfixed-width-radio"
                style="width: 92%;"
            >
                <a-radio-button
                    v-for="(label, key) in $options.pwdQuestionEnum"
                    :key="key"
                    :value="key"
                >
                    {{ label }}
                </a-radio-button>
            </a-radio-group>
        </a-form-item>
        <a-form-item
            label="その答え"
            style="width: 47%;"
        >
            <a-input v-decorator="['pwdAnswer', {rules: getRules('pwdAnswer')}]" />
        </a-form-item>
    </section>
</template>
<script>
import {mapGetters} from 'vuex';

import {pwdQuestionEnum} from 'src/common/constant/enum';

import FormMixin from '../mixins/form';

export default {
    name: 'LoginInfo',

    pwdQuestionEnum,

    mixins: [FormMixin],

    props: {
        form: {
            type: Object,
            default: () => ({})
        },
    },

    computed: {
        ...mapGetters('finance', ['userInfo']),
    },

    methods: {
        getRules(field) {
            const pwdRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,16}$/;
            let rules = [];
            const isEqualValidatorGenerator = fieldKey => {
                return (rule, value, callback) => {
                    const must = this.form.getFieldValue(fieldKey);
                    if (must && value !== must) {
                        callback(' ');

                        return;
                    }

                    callback();
                };
            };

            switch (field) {
                case 'password':
                    rules = [
                        // {required: true, message: ' '},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'}
                    ];
                    break;
                case 'passwordMust':
                    rules = [
                        // {required: true, message: ' '},
                        {pattern: pwdRe, message: '大文字・小文字・数字をそれぞれ1字以上入れてください。'},
                        {validator: isEqualValidatorGenerator('password'), message: 'パスワードが違います。'}
                    ];
                    break;
                case 'pwdAnswer':
                    rules = [
                        {required: true, message: ' '}
                        // {pattern: inputRe, message: '全角（漢字、ひらがな、カタカナ）でご入力ください。'}
                    ];
                    break;
                default:
            }

            return rules;
        },

        onOpenModifyEmail() {
            this.$router.push({name: 'EmailModify'});
        }
    }

};
</script>
<style lang="scss" scoped>
.login-info-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.6rem;
}

.email-box {
    width: 100%;
    padding: 0.2rem;
    box-sizing: border-box;
    border-radius: 0.16rem;
    border: 1px solid $c-06;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    .modif-entry {
        cursor: pointer;
        display: flex;
        align-items: center;

        &-icon {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.08rem;
        }

        &-text {
            color: $yellow-02;
        }
    }
}
</style>
