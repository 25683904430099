var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement" }, [
    _c("div", { staticClass: "title-fix" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            " + _vm._s(_vm.info.text) + "\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v(
          "\n            ※ ご確認いただき「承諾する」にチェックしてください。最後までスクロールしないとチェックできません。\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "agreement-wrap", on: { scroll: _vm.onScroll } },
      [_c("yqg-md-preview", { attrs: { "md-text": _vm.info.desc } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "agreement-item", on: { click: _vm.onCheck } }, [
      _c("img", {
        staticClass: "check-icon",
        attrs: { src: _vm.info.isCheck ? _vm.Select : _vm.NoSelect, alt: "" },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "check-text" }, [
        _vm._v("上記の書面を確認し、承諾します。"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "check-import" }, [_vm._v("*")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }