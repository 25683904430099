var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.finance.userEmail && !_vm.finance.kyc
    ? _c(
        "button",
        {
          staticClass: "back-btn b2 bold",
          on: { click: _vm.goToCurrentStepRoute },
        },
        [_c("back-svg"), _vm._v("\n    戻る\n")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }