<!-- @Author: xiaodongyu -->
<!-- @Date: 2024-02-28 19:55:54 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-03-06 10:46:42 -->

<template>
    <button
        v-if="finance.userEmail && !finance.kyc"
        class="back-btn b2 bold"
        @click="goToCurrentStepRoute"
    >
        <back-svg />
        戻る
    </button>
</template>

<script type="text/babel">
import {mapState} from 'vuex';

import BackSvg from './back.vc.svg';

export default {
    name: 'BackBtn',

    components: {
        BackSvg
    },

    computed: mapState(['finance']),

    methods: {
        goToCurrentStepRoute() {
            const {currentStepRouteName} = this.finance;
            if (currentStepRouteName) {
                this.$router.push({name: currentStepRouteName});

                return;
            }

            this.$router.push({name: 'Index'});
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.back-btn {
    position: fixed;
    top: 50%;
    right: 0;
    padding: 0;
    padding-left: 0.06rem;
    width: 0.86rem;
    height: 0.48rem;
    color: $c-09;
    border-radius: 1rem 0 0 1rem;
    background-color: $green-03;

    svg {
        transform: rotate(180deg);
    }
}
</style>
