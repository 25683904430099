/**
 * @Author: qingliu
 * @Date: 2018-07-19 17:46:41.048
 * @Last Modified by: huafengli
 * @Last Modified time: 2024-05-09 17:20:10
 */

import Filters from '../filter';
import BackBtn from './finance/back-btn';
import IEmpty from './finance/empty';
import FooterBar from './finance/footer';
import NavBar from './finance/nav-bar';

export default {

    install(Vue) {
        Vue.use(Filters);
        [
            BackBtn,
            IEmpty,
            FooterBar,
            NavBar
        ].forEach(Comp => Vue.component(Comp.name, Comp));
    }

};
