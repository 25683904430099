var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "register-layout",
    {
      attrs: { step: 3 },
      scopedSlots: _vm._u([
        {
          key: "tip",
          fn: function () {
            return [
              _c("div", { staticClass: "b1 c-09" }, [
                _c("p", [
                  _vm._v(
                    "※確認書類は発行後3ヶ月以内・有効期限内のものを用意してください。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mid-tip" }, [
                  _vm._v(
                    "\n                ※確認書類は複数枚に渡る場合、すべてのページが必要となります。\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "※官公署が発行する書類は公印のあるページが必要となります。"
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "rs2" }, [
        _c("h2", { staticClass: "bold" }, [
          _vm._v("\n            本人確認書類の提出\n        "),
        ]),
        _vm._v(" "),
        _c("section", [
          _c("p", { staticClass: "b3 mt24" }, [
            _vm._v(
              "\n                会員本登録には、本人確認書類として下記をご提出ください。\n                各書類はアップロードにて提出いただけます。\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "b3" }, [
            _vm._v(
              "\n                （ご不明な点がございましたら、お問合せよりご連絡ください。）\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "b3 mt24" }, [
            _vm._v(
              "\n                ファイルサイズ：１ファイルあたり４Mb以内\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.isServer && _vm.form
          ? _c(
              "section",
              { staticClass: "mt60 fm-1" },
              [
                _c(
                  "a-form",
                  {
                    attrs: { layout: "vertical", form: _vm.form },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "step-tit" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(
                          "\n                        Step 01.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "b2" }, [
                        _vm._v(
                          "\n                        アップロードするファイルの種類を選択します。\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-bar mb20 mt24" },
                      _vm._l(
                        _vm.$options.nationalityEnum,
                        function (label, key) {
                          return _c(
                            "div",
                            {
                              key: key,
                              staticClass: "tab-bar-item b1",
                              class: _vm.getActiveTabClass(key),
                              on: {
                                click: function ($event) {
                                  return _vm.onTabChange(key)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(label) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "section",
                      [
                        _c(
                          "a-form-item",
                          {
                            key: _vm.identityTypeDef.field,
                            staticClass: "custom-btn-radio-group-wrapper",
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "identityType",
                                      {
                                        rules: [{ required: true }],
                                        initialValue: _vm.formData.identityType,
                                      },
                                    ],
                                    expression:
                                      "['identityType', { rules: [{ required: true }], initialValue: formData.identityType }]",
                                  },
                                ],
                                staticClass: "custom-card-radio-group",
                                on: { change: _vm.onRadioChange },
                              },
                              _vm._l(
                                _vm.identityTypeDef.options,
                                function (opt) {
                                  return _c(
                                    "a-radio-button",
                                    {
                                      key: opt.value,
                                      style: {
                                        width:
                                          opt.isJpIdCard || opt.isStarCard
                                            ? "100%"
                                            : undefined,
                                      },
                                      attrs: { value: opt.value },
                                    },
                                    [
                                      opt.isJpIdCard
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-between" },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "b2" },
                                                  [
                                                    _c("icon-star", {
                                                      staticClass:
                                                        "star-icon icon-small",
                                                    }),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(opt.label) +
                                                        "\n                                        "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "b4 tab-desc",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            1書類の提出のみで可\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tab-item-right flex",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "id-card",
                                                    attrs: {
                                                      src: require("./img/id-card-front.png"),
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("img", {
                                                    staticClass: "id-card ml12",
                                                    attrs: {
                                                      src: require("./img/id-card-back.png"),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : opt.isStarCard
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-between" },
                                            [
                                              _c(
                                                "div",
                                                _vm._l(
                                                  opt.labels,
                                                  function (label) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: label,
                                                        staticClass: "dot b2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(label) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tab-item-right flex pr10",
                                                },
                                                [
                                                  _c("icon-star", {
                                                    staticClass:
                                                      "star-icon icon-big",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            _vm._l(
                                              opt.labels,
                                              function (label) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: label,
                                                    staticClass: "dot b2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(label) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "step-tit" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(
                          "\n                        Step 02.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "b2" }, [
                        _vm._v(
                          "\n                        「ファイル選択」をクリックし、提出書類の画像を選択\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.fileDefGroup, function (group, idx) {
                      return _c(
                        "section",
                        { key: group.key, staticClass: "controler-box mt20" },
                        [
                          _c(
                            "a-form-item",
                            {
                              key: _vm.tab + "_" + group.row[0].value,
                              attrs: {
                                label: idx + 1 + "種類目の本人確認書類",
                              },
                            },
                            [
                              _c(
                                "a-upload-dragger",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.genFileDecorator(
                                        group.row[0].value
                                      ),
                                      expression:
                                        "genFileDecorator(group.row[0].value)",
                                    },
                                  ],
                                  staticClass: "dragger-upload front",
                                  attrs: {
                                    accept: _vm.$options.accept,
                                    "custom-request": _vm.customRequest,
                                    "before-upload": _vm.getBeforeUpload(
                                      group.row[0].value
                                    ),
                                    "file-list":
                                      _vm.fileListMap[group.row[0].value],
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onFileChange(
                                        $event,
                                        group.row[0].value
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(group.row[0].cardLabel) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "b3" }, [
                                    _vm._v(
                                      "\n                                ファイルをアップロード\n                            "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          group.row[1]
                            ? _c(
                                "a-form-item",
                                {
                                  key: _vm.tab + "_" + group.row[1].value,
                                  staticStyle: { "padding-top": "28px" },
                                },
                                [
                                  _c(
                                    "a-upload-dragger",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.genFileDecorator(
                                            group.row[1].value
                                          ),
                                          expression:
                                            "genFileDecorator(group.row[1].value)",
                                        },
                                      ],
                                      staticClass: "dragger-upload back",
                                      attrs: {
                                        accept: _vm.$options.accept,
                                        "custom-request": _vm.customRequest,
                                        "before-upload": _vm.getBeforeUpload(
                                          group.row[1].value
                                        ),
                                        "file-list":
                                          _vm.fileListMap[group.row[1].value],
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onFileChange(
                                            $event,
                                            group.row[1].value
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(group.row[1].cardLabel) +
                                            "\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "b3" }, [
                                        _vm._v(
                                          "\n                                ファイルをアップロード\n                            "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("a-form-item", [
                      _c(
                        "button",
                        {
                          attrs: { disabled: !_vm.canSubmit, type: "primary" },
                        },
                        [
                          _vm._v(
                            "\n                        送信する\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }