var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "b2 mb28" }, [
        _vm._v("\n        本人確認書類の提出\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-bar mb20",
          class: { "not-allowed": _vm.isDisabled },
        },
        _vm._l(_vm.$options.nationalityEnum, function (label, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "tab-bar-item b1",
              class: _vm.getActiveTabClass(key),
              on: {
                click: function ($event) {
                  return _vm.onTabChange(key)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(label) + "\n        ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c(
            "a-form-item",
            {
              key: _vm.identityTypeDef.field,
              staticClass: "custom-btn-radio-group-wrapper",
            },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["identityType", { rules: [{ required: true }] }],
                      expression:
                        "['identityType', {rules: [{ required: true }]}]",
                    },
                  ],
                  staticClass: "custom-card-radio-group",
                  attrs: { disabled: _vm.isDisabled },
                  on: { change: _vm.onRadioChange },
                },
                _vm._l(_vm.identityTypeDef.options, function (opt) {
                  return _c(
                    "a-radio-button",
                    {
                      key: opt.value,
                      style: {
                        width:
                          opt.isJpIdCard || opt.isStarCard ? "100%" : undefined,
                      },
                      attrs: { value: opt.value },
                    },
                    [
                      opt.isJpIdCard
                        ? _c("div", { staticClass: "flex-between" }, [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "b2" },
                                [
                                  _c("icon-star", {
                                    staticClass: "star-icon icon-small",
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(opt.label) +
                                      "\n                            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "b4 tab-desc" }, [
                                _vm._v(
                                  "\n                                1書類の提出のみで可\n                            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "tab-item-right flex" }, [
                              _c("img", {
                                staticClass: "id-card",
                                attrs: {
                                  src: require("../../register/step2/img/id-card-front.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "id-card ml12",
                                attrs: {
                                  src: require("../../register/step2/img/id-card-back.png"),
                                },
                              }),
                            ]),
                          ])
                        : opt.isStarCard
                        ? _c("div", { staticClass: "flex-between" }, [
                            _c(
                              "div",
                              _vm._l(opt.labels, function (label) {
                                return _c(
                                  "div",
                                  { key: label, staticClass: "dot b2" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(label) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "tab-item-right flex pr10" },
                              [
                                _c("icon-star", {
                                  staticClass: "star-icon icon-big",
                                }),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "div",
                            _vm._l(opt.labels, function (label) {
                              return _c(
                                "div",
                                { key: label, staticClass: "dot b2" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(label) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.fileDefGroup, function (group, idx) {
        return _c(
          "section",
          { key: group.key, staticClass: "controler-box mt20" },
          [
            _c(
              "a-form-item",
              {
                key: _vm.tab + "_" + group.row[0].value,
                attrs: { label: idx + 1 + "種類目の本人確認書類" },
              },
              [
                _c(
                  "a-upload-dragger",
                  {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.genFileDecorator(group.row[0].value),
                        expression: "genFileDecorator(group.row[0].value)",
                      },
                    ],
                    staticClass: "dragger-upload front",
                    attrs: {
                      accept: _vm.$options.accept,
                      "custom-request": _vm.customRequest,
                      "before-upload": _vm.getBeforeUpload(group.row[0].value),
                      "file-list": _vm.fileListMap[group.row[0].value],
                      disabled: _vm.isDisabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, group.row[0].value)
                      },
                    },
                  },
                  [
                    _c("h5", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(group.row[0].cardLabel) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "b3" }, [
                      _vm._v(
                        "\n                    ファイルをアップロード\n                "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            group.row[1]
              ? _c(
                  "a-form-item",
                  {
                    key: _vm.tab + "_" + group.row[1].value,
                    staticStyle: { "padding-top": "28px" },
                  },
                  [
                    _c(
                      "a-upload-dragger",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.genFileDecorator(group.row[1].value),
                            expression: "genFileDecorator(group.row[1].value)",
                          },
                        ],
                        staticClass: "dragger-upload back",
                        attrs: {
                          accept: _vm.$options.accept,
                          "custom-request": _vm.customRequest,
                          "before-upload": _vm.getBeforeUpload(
                            group.row[1].value
                          ),
                          "file-list": _vm.fileListMap[group.row[1].value],
                          disabled: _vm.isDisabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event, group.row[1].value)
                          },
                        },
                      },
                      [
                        _c("h5", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(group.row[1].cardLabel) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "b3" }, [
                          _vm._v(
                            "\n                    ファイルをアップロード\n                "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }