<!-- @Author: huafengli -->
<!-- @Date: 2024-5-7 19:24:41 -->
<!-- @Last Modified by: huafengli -->
<!-- @Last Modified time: 2024-05-08 17:41:17 -->

<template>
    <div>
        <div :class="labelClass">
            <slot :name="`${item.field}.label`">
                {{ item.label }}
            </slot>
        </div>
        <div :class="valueClass">
            <slot :name="`${item.field}.value`">
                {{ value | toFullWidth }}
            </slot>
        </div>
    </div>
</template>
<script>
import Enum from '@yqg/enum';

import {dateMonth} from 'ssr-common/util/date';

import {pickValue} from 'src/common/util';
export default {
    name: 'ItemInfo',

    props: {
        labelClass: {
            type: [String, Array, Object],
            default: ''
        },

        valueClass: {
            type: [String, Array, Object],
            default: ''
        },

        item: {
            type: Object,
            default: () => ({})
        },

        valObj: {
            type: Object,
            default: () => ({})
        }

    },

    data() {
        return {
            enumMap: null,
        };
    },

    computed: {
        value() {
            const {field, enumType, type, suffix, filter} = this.item;

            const realVal = pickValue(this.valObj, field);

            let viewVal = realVal;

            if (type === 'date') {
                viewVal = dateMonth(viewVal);
            }

            if (enumType && this.enumMap) {
                if (Array.isArray(realVal)) {
                    viewVal = realVal.map(i => this.enumMap[i]).join(',');
                } else {
                    viewVal = this.enumMap[realVal];
                }
            }

            if (filter) {
                viewVal = this.$options.filters[filter](viewVal);
            }

            if (suffix) {
                viewVal = `${viewVal}${suffix}`;
            }

            return viewVal || '';
        }
    },

    async mounted() {
        const {enumType} = this.item;
        this.enumMap = await this.getEnumMap(enumType);
    },

    methods: {
        async getEnumMap(enumType) {
            let enumMap = null;
            if (enumType) {
                if (enumType.constructor === Enum) {
                    await enumType.query();
                    enumMap = enumType.MAP;
                } else {
                    enumMap = enumType;
                }
            }

            return enumMap;
        }
    }

};
</script>
